import ProductActions from "redux/actions/productActions";
import {setItem} from "utils/localStorage"
const { ActionTypes } = ProductActions;
const initialState = {
  data: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADD_PRODUCT: {
      const { product, collectionId, variantMetaData, price, sellingPriceAttr } = action.payload;
      const newProducts = [
        ...state.data,
        { ...product, variant_meta: variantMetaData, product_price : price, selling_priceAttr:sellingPriceAttr},
      ];

      setItem(
        `shortlisted_products`,
        JSON.stringify(newProducts)
      );

      return {
        ...state,
        data: newProducts,
      };
    }
    case ActionTypes.REMOVE_PRODUCT: {
      const { product, collectionId } = action.payload;
      const newProducts = state.data.filter((p) => p.id !== product.id);
      setItem(
        `shortlisted_products`,
        JSON.stringify(newProducts)
      );

      return {
        ...state,
        data: newProducts,
      };
    }
    case ActionTypes.SET_PRODUCTS: {
      const { products, collectionId } = action.payload;
      setItem(
        `shortlisted_products`,
        JSON.stringify(products)
      );
      return {
        ...state,
        data: products,
      };
    }
    default:
      return state;
  }
}
