// @ts-nocheck
import React, { useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Image } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Colors } from "../../../theme";

const { Title, Text } = Typography;

export default function ShareCatalogue({ handleModal }) {
  const [form] = Form.useForm();
  const [shareCatalogueStatus, setshareCatalogueStatus] = useState(false);

  const handleCatalogueStatus = () => {
    setshareCatalogueStatus((state) => !state);
  };
  return (
    <div>
      {shareCatalogueStatus ? (
        <div>
          <Row>
            <Col span={24}>
              <Row align="center" justify="center">
                <Image src="https://via.placeholder.com/179x179.png" />
              </Row>
              <Row
                align="center"
                justify="center"
                style={{ marginTop: "0.5rem" }}
              >
                <Title
                  level={4}
                  style={{
                    fontFamily: "Avenir",
                    margin: 0,
                  }}
                >
                  Content shared succesfully!
                </Title>
              </Row>
              <Row
                align="center"
                justify="center"
                style={{ marginTop: "0.5rem" }}
              >
                <Text style={{ color: Colors.gray, marginTop: 0 }}>
                  Check your email?
                </Text>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <Row>
          <Col span={24}>
            <Row style={{ marginBottom: "1rem" }}>
              <MailOutlined style={{ fontSize: "2rem", color: Colors.gray }} />
            </Row>
            <Row>
              <Title
                level={4}
                style={{
                  fontFamily: "Avenir",
                }}
              >
                Share a catalogue via email
              </Title>
            </Row>
            <Row>
              <Col span={24}>
                <div>
                  <Form
                    form={form}
                    layout="vertical"
                    style={{
                      fontFamily: "Avenir",
                      marginTop: "1 rem",
                    }}
                    size="large"
                  >
                    <Form.Item
                      label={<Title level={5}>Your name</Title>}
                      style={{ marginBottom: "10px", fontSize: "1rem" }}
                    >
                      <Input placeholder="Enter your full name" />
                    </Form.Item>
                    <Form.Item
                      label={<Title level={5}>Your mail id</Title>}
                      style={{ marginBottom: "10px" }}
                      rules={[{ type: "email" }]}
                    >
                      <Input placeholder="You will be cc'd" />
                    </Form.Item>

                    <Form.Item
                      label={<Title level={5}>Enter recipient email id</Title>}
                      rules={[{ type: "email" }]}
                      style={{ marginBottom: "10px" }}
                    >
                      <Input placeholder="Recipient Email" />
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: "1.5rem" }}>
              <Col span={8}>
                <Button
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  onClick={handleModal}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={handleCatalogueStatus}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}
