import API_KEY from "../../api";

export const products = {
  getProducts: async (store, payload) => {
    const { params } = payload;
    store.setState({ loading: true });
    const data = await API_KEY.URL.get(API_KEY.path.products, { params });
    console.log(data.data, "products");
    store.setState({ products: data?.data, loading: false });
  },
  getProductsFilters: async (store, filters, fields, search_query) => {
    store.setState({ loading: true });
    const data = await API_KEY.URL.get(API_KEY.path.products, {
      params: {
        filter: filters
          ? filters
          : {
              user_id: {
                _eq: `$CURRENT_USER`,
              },
            },
        fields: fields,
        meta: "total_count,filter_count",
        search: search_query,
      },
    });
    store.setState({ products: data?.data, loading: false });
  },
  selectedProduct: async (store, payload) => {
    store.setState({ selectedProdutcs: payload, loading: false });
  },
  setProductIndex: async (store, payload) => {
    store.setState({ productIndex: payload });
  },
  setWishlistComments: async (store, payload) => {
    store.setState({ wishlistComments: payload });
  },
};
