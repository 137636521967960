import React, { useState, useEffect } from "react";
import OnImagesLoaded from "react-on-images-loaded";
import { Image } from "antd";

export default function ImageRouter(props) {
  const [imageURL, setImageURL] = useState(props.src);

  useEffect(() => {
    setImageURL(props.src);
  }, [props.src]);

  return (
    <OnImagesLoaded
      onLoaded={() => {
        if (props.imageLoaded) {
          props.imageLoaded();
        }
        return null;
      }}
    >
      <Image
        {...props}
        fallback={
          props.secondFallbackUrl ? props.secondFallbackUrl : props.fallback
        }
        src={imageURL}
      />
    </OnImagesLoaded>
  );
}
