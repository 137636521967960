import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Typography,
  Divider,
  Space,
  Button,
  Spin,
} from "antd";
import {
  createFromIconfontCN,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { Carousel } from "react-responsive-carousel";
import { Colors } from "../../../theme";
import { Shimmer } from "../../../components/Shimmer";

import { AiFillFileText } from "react-icons/ai";
import { FiClock } from "react-icons/fi";
import { BsBookmark } from "react-icons/bs";
import { BsBookmarkFill } from "react-icons/bs";
import VariantsTable from "components/VariantsTable";
import React360 from "components/360View/index";
import Icon360 from "./3DView.svg";
import ImageWithMagnification from "react-common-components/ImageWithMagnification";
import ImageRouter from "react-common-components/ImageRouter";
import { collections } from "store/actions/collections";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
});

const { Title, Text } = Typography;

export default function ProductDetailsModal({
  modalData,
  showModal,
  handleModal,
  handleShortlist,
  isShortlisted,
  allProductHits,
  handleNext,
  handlePrev,
  currentProductIndex,
  imgLoaded,
  setImgLoaded,
  collectionDetails,
}) {
  const onLoadImg = () => {
    setImgLoaded(true);
  };

  const handleClose = () => {
    handleModal();
    setImgLoaded(false);
    setShow360(false);
  };

  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const magnifierHeight = 400;
  const magnifieWidth = 400;
  const zoomLevel = 3;
  const [show360, setShow360] = useState(false);

  function detectMob() {
    return window.innerWidth <= 800 && window.innerHeight <= 600;
  }

  const renderBody = () => {
    const customRenderItem = (item, props) => (
      <item.type {...item.props} {...props} />
    );

    const customRenderThumb = (children) => {
      let image = children.map((item) => {
        let imageId;
        let fallbackImageId;
        imageId = item.props.src;
        fallbackImageId = item.props.fallbackUrl;
        return (
          <ImageRouter
            src={imageId}
            fallback={fallbackImageId}
            preview={false}
          />
        );
      });
      return image;
    };

    const sortedData = modalData?.attributes?.sort((a, b) => {
      return (
        b.attribute_values_id.attribute_id.priority -
        a.attribute_values_id.attribute_id.priority
      );
    });
    if (!modalData) {
      return null;
    } else {
      return (
        <div style={{ width: "100%" }}>
          <Row>
            <Col span={24}>
              <Row style={{ marginTop: "1em" }}>
                <Col
                  className="product-image-section"
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 14, offset: 0 }}
                  xl={{ span: 14, offset: 0 }}
                >
                  <Row justify="center">
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {modalData?.has_360_view > 0 && (
                        <Button
                          onClick={() => setShow360((state) => !state)}
                          style={{
                            color: "#363E3D",
                            border: "1px solid #363E3D",
                          }}
                        >
                          <Row justify="space-around" align="middle">
                            <Col>
                              {!show360 && <img src={Icon360} alt="360" />}
                            </Col>
                            <Col style={{ marginLeft: 5 }}>
                              {show360
                                ? "Close 3D view"
                                : "Click to view in 3D"}
                            </Col>
                          </Row>
                        </Button>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "1em" }}>
                    <Col span={24}>
                      <div
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {modalData?.product_images?.length > 0 ? (
                          show360 ? (
                            <React360
                              images={modalData?.three_sixty_images}
                              numImages={modalData?.three_sixty_images?.length}
                            />
                          ) : (
                            <Carousel
                              className="product-images" 
                              showThumbs={true}
                              renderItem={customRenderItem}
                              renderThumbs={customRenderThumb}
                              autoFocus={true}
                              selectedItem={0}
                            >
                              {modalData?.product_images?.map((item, index) => {
                                const MagnifiedSrc = `${process.env.REACT_APP_DIRECTUS}assets/${item?.directus_files_id}?transforms=[["resize",{"width":1800,"height":1200,"fit":"contain","background":{"r":255,"g":255,"b":255}}]]`;
                                const secondFallbackUrl = `${process.env.REACT_APP_DIRECTUS}assets/${item?.directus_files_id}?transforms=[["resize",{"width":1800,"height":1200,"fit":"contain","background":{"r":255,"g":255,"b":255}}]]`;
                                const src = `${process.env.REACT_APP_DIRECTUS}assets/${item?.directus_files_id}?transforms=[["resize",{"width":900,"height":600,"fit":"contain","background":"white"}]]`;
                                if (collectionDetails?.manufacturer !== 324) {
                                  return (
                                    <ImageWithMagnification
                                      magnifiedSrc={MagnifiedSrc}
                                      src={src}
                                      item={item}
                                      imgLoaded={imgLoaded}
                                      setImgLoaded={setImgLoaded}
                                      key={index}
                                      style={{
                                        margin: "auto",
                                      }}
                                      secondFallbackUrl={secondFallbackUrl}
                                    />
                                  );
                                }
                                return (
                                  <ImageRouter
                                    src={src}
                                    item={item}
                                    imgLoaded={imgLoaded}
                                    setImgLoaded={setImgLoaded}
                                    key={index}
                                    secondFallbackUrl={secondFallbackUrl}
                                  />
                                );
                              })}
                            </Carousel>
                          )
                        ) : (
                          <div>
                            <Carousel
                              className="product-images"
                              showThumbs={false}
                              centerMode={true}
                            >
                              <div>
                                <img
                                  src="http://placehold.jp/24/0a8f8d/ffffff/500x300.png?text=No Images for this product"
                                  alt="No Images for this product"
                                ></img>
                              </div>
                            </Carousel>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col
                  className="catalogue-product-details overflow-y-scroll"
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 10, offset: 0 }}
                  xl={{ span: 10, offset: 0 }}
                  // style = {{ height : '75vh', overflowY : 'scroll', overflowX:'hidden'}}
                >
                  {/*ID Starts ----------------------------------- */}
                  <Row>
                    <Col>
                      <Text
                        className="product-id"
                        // type="secondary"
                        style={{
                          fontFamily: "Avenir-Roman",
                          marginBottom: "0.5rem",
                          fontSize: 18,
                        }}
                      >
                        {modalData?.products_id
                          ? "ID: " + modalData?.products_id
                          : ""}
                      </Text>
                    </Col>
                  </Row>
                  {/*ID Ends ----------------------------------- */}

                  {/*Name Starts --------------
        { modalData?.transformedAttributes && Object.values(modalData.transformedAttributes)[9]
          ?
          <Row>
            <Space>
                <Title
                  style={{ fontSize: "14px", textTransform : 'capitalize' }}
                >
                {Object.values(modalData.transformedAttributes)[9]}
                </Title>
            </Space>
          </Row>
        : <></>
      }
        Name Ends -------------- */}

                  {/*Price Starts -------------- */}
                  {modalData?.attributes &&
                    Object.keys(sortedData)?.map((key, index) =>
                      modalData?.attributes?.[key]?.attribute_values_id
                        ?.attribute_id?.dtype === "price" &&
                      modalData?.attributes?.[key]?.attribute_values_id
                        ?.values ? (
                        <Row>
                          <Col span={12}>
                            <Row>
                              <Text
                                style={{ fontSize: "16px" }}
                                type="secondary"
                              >
                                {modalData?.attributes?.[key]
                                  ?.attribute_values_id?.attribute_id?.name ||
                                  ""}
                              </Text>
                            </Row>
                            <Row gutter={[8]}>
                              <Col>
                                <Title
                                  level={4}
                                  style={{
                                    fontFamily: "Gilroy",
                                    margin: "0.5em 0",
                                  }}
                                  className="product-id"
                                >
                                  {`${modalData?.attributes?.[key]?.attribute_values_id?.composite?.currency} ` +
                                    `${modalData?.attributes?.[key]?.attribute_values_id?.values} `}
                                </Title>
                              </Col>
                              <Col>
                                <Title
                                  style={{
                                    margin: "0.8em  0 0.3em",
                                    fontWeight: "normal",
                                  }}
                                  level={5}
                                >
                                  {
                                    modalData?.attributes?.[key]
                                      ?.attribute_values_id?.composite?.unit
                                  }
                                </Title>
                              </Col>
                            </Row>
                          </Col>
                          {/* <Divider
                          style={{
                            margin: "0.3em 0",
                            border: "1px solid #E6E7E7",
                          }}
                        /> */}
                        </Row>
                      ) : (
                        <> </>
                      )
                    )}

                  {/*Price Ends -------------- */}

                  {/*Button Starts ---------------------- */}
                  <Row>
                    <Button
                      size="large"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        color: "white",
                        background: isShortlisted
                          ? Colors.primaryColor
                          : Colors.black,
                      }}
                      onClick={() => handleShortlist(modalData, !isShortlisted)}
                    >
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          display: "inline-block",
                        }}
                      >
                        {isShortlisted ? (
                          <BsBookmarkFill
                            style={{
                              margin: "0 5px",
                              transform: "translateY(2px)",
                              marginTop: 3,
                            }}
                          />
                        ) : (
                          <BsBookmark
                            size={"18px"}
                            style={{
                              margin: "0 5px",
                              transform: "translateY(2px)",
                              marginTop: 3,
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          width: "100px",
                          display: "inline-block",
                        }}
                      >
                        {isShortlisted ? "Shortlisted" : "Shortlist"}
                      </div>
                    </Button>
                  </Row>
                  {/*Button Ends ---------------------- */}

                  {!!modalData?.variants?.length &&
                    modalData?.variants?.[0]?.attributes?.length > 0 && (
                      <VariantsTable data={modalData?.variants} />
                    )}

                  <br />

                  {modalData?.attributes &&
                    Object.keys(modalData?.attributes).map((key, index) => {
                      return modalData?.attributes?.[key]?.attribute_values_id
                        ?.attribute_id?.dtype !== "price" &&
                        modalData?.attributes?.[key]?.attribute_values_id
                          ?.values ? (
                        <Row key={index}>
                          <Col span={6}>
                            <Title
                              style={{ fontSize: "16px", marginRight: "0.7em" }}
                              type="secondary"
                            >
                              {`${modalData?.attributes?.[key]?.attribute_values_id?.attribute_id?.name}`}
                            </Title>
                          </Col>

                          <Col span={14}>
                            <Text
                              style={{
                                fontSize: "16px",
                                textTransform: "capitalize",
                                paddingTop: "6px",
                              }}
                            >
                              {`${modalData?.attributes?.[key]?.attribute_values_id?.values}`}
                            </Text>
                          </Col>
                        </Row>
                      ) : (
                        <> </>
                      );
                    })}
                </Col>
              </Row>
              {/* <Row>

            </Row> */}
            </Col>
          </Row>
          <Row className="product-preview-button-wrapper" gutter={[16, 16]}>
            <Col>
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
                gutter={[8, 8]}
              >
                <Col>
                  {currentProductIndex === 0 ? (
                    <Title
                      style={{ cursor: "not-allowed", marginBottom: "0" }}
                      level={5}
                      disabled={currentProductIndex === 0}
                    >
                      Back
                    </Title>
                  ) : (
                    <Title
                      style={{ cursor: "pointer", marginBottom: "0" }}
                      level={5}
                      onClick={() => {
                        handlePrev(currentProductIndex);
                        sendAnalyticsEvent("product-modal-back", {
                          current_Product_Index: currentProductIndex,
                        });
                      }}
                    >
                      Back
                    </Title>
                  )}
                </Col>
                <Col>
                  <Button
                    size="large"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      background:
                        currentProductIndex === 0
                          ? "#e5e5e5"
                          : "rgba(201, 228, 223, 1)",
                      color: "rgba(12, 41, 36, 1)",
                      width: "2.5rem",
                      height: "2.5rem",
                      border: "none",
                      borderRadius: "50%",
                    }}
                    disabled={currentProductIndex === 0}
                    onClick={() => {
                      handlePrev(currentProductIndex);
                      sendAnalyticsEvent("product-modal-back", {
                        current_Product_Index: currentProductIndex,
                      });
                    }}
                  >
                    <LeftOutlined className="focus:p-0 items-center p-0 flex items-center justify-center transition-all duration-300 ease-in-out mx-auto my-auto h-max" />
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
                gutter={[4, 4]}
              >
                <Col>
                  <Col>
                    <Button
                      size="large"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        background:
                          currentProductIndex === allProductHits.length - 1
                            ? "#e5e5e5"
                            : "rgba(201, 228, 223, 1)",
                        color: "rgba(12, 41, 36, 1)",
                        width: "2.5rem",
                        height: "2.5rem",
                        border: "none",
                        borderRadius: "50%",
                      }}
                      disabled={
                        currentProductIndex === allProductHits.length - 1
                      }
                      onClick={() => {
                        handleNext(currentProductIndex);
                        sendAnalyticsEvent("product-modal-next", {
                          current_Product_Index: currentProductIndex,
                        });
                      }}
                    >
                      <RightOutlined className="flex items-center p-0 h-max w-max flex items-center justify-center mx-auto my-auto" />
                    </Button>
                  </Col>
                </Col>
                <Col>
                  {currentProductIndex === allProductHits.length - 1 ? (
                    <Title
                      disabled={
                        currentProductIndex === allProductHits.length - 1
                      }
                      style={{ cursor: "not allowed", marginBottom: "0" }}
                      level={5}
                    >
                      Next
                    </Title>
                  ) : (
                    <Title
                      style={{ cursor: "pointer", marginBottom: "0" }}
                      level={5}
                      onClick={() => {
                        handleNext(currentProductIndex);
                        sendAnalyticsEvent("product-modal-next", {
                          current_Product_Index: currentProductIndex,
                        });
                      }}
                    >
                      Next
                    </Title>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
  };

  const renderLoader = () => {
    if (!modalData) {
      return (
        <Row style={{ height: "30vh" }} align="middle">
          <Col span={2} offset={12}>
            <Spin size="large" />
          </Col>
        </Row>
      );
    }
  };

  // const moreDetails = modalData?.attributes.filter(attr => attr.attribute_values_id?.attribute_id?.tabname !== 'production_detail' && attr.attribute_values_id?.attribute_id?.dtype !== 'price' && attr.attribute_values_id?.values);
  return (
    <Modal
      visible={showModal}
      destroyOnClose={true}
      onCancel={handleClose}
      wrapClassName="product-preview-wrapper"
      className="product-preview-modal"
      centered
      bodyStyle={{ height: "80vh", overflow: "hidden" }}
      width={"80vw"}
      footer={null}
    >
      {/* {renderLoader()} */}
      {renderBody()}
    </Modal>
  );
}
