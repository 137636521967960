import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Typography,
  Input,
  Button,
  notification,
  Skeleton,
} from "antd";
import ShortListedCard from "./ShortListedCard";
import { IoMdMail } from "react-icons/io";
import axios from "axios";
import { useGlobal } from "../../../store/index";
import { useHistory } from "react-router-dom";
import EmptyState from "./DraftEmptyState";
import API_KEY from "../../../api/index";
import { getShortedListedProductsFrom } from "utils";
import {getItem,removeItem} from "utils/localStorage"

const { Title, Text } = Typography;

export default function DraftQuery() {
  const [shortlisted, setShortlisted] = useState([]);
  const [collectionDetails, setCollectionDetails] = useState(null);
  const [draftQuery, setDraftQuery] = useState(null);
  const [form] = Form.useForm();
  const [globalState, setGlobalState] = useGlobal();
  const { queriesData } = globalState;
  // const [status, setStatus] = useState("sent");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const collection_link = getItem("collection_link");

  // get data from localStorage
  useEffect(() => {
    const products = getShortedListedProductsFrom();
    setShortlisted(products);
    const collectionData = getItem("collection_details");
    setCollectionDetails(collectionData ? JSON.parse(collectionData) : null);
  }, []);

  useEffect(() => {
    if (collectionDetails) {
      //setGlobalState.buyerActions.getQueries(collectionDetails?.id);
      setLoading(true);
      API_KEY.URL.get(`items/enquiries`, {
        params: {
          filter: {
            collection: {
              id: {
                _eq: collectionDetails?.id,
              },
            },
          },
          fields:
            "*,products.comment,products.collections_products_id.id, products.collections_products_id.products_id.product_images.*,products.collections_products_id.products_id.product_id,products.collections_products_id.attributes.attribute_values_id.*,products.collections_products_id.attributes.attribute_values_id.attribute_id.*",
        },
      }).then((res) => {
        const queries = res?.data?.data?.filter(
          (query) => query.status === "draft"
        );
        setLoading(false);

        const data = queries[queries?.length - 1];
        const sortedProducts = data?.products.sort(
          (a, b) =>
            a?.collections_products_id?.id - b?.collections_products_id?.id
        );
        setDraftQuery({
          ...data,
          products: sortedProducts,
        });
      });
    }
  }, [setGlobalState, collectionDetails]);

  // useEffect(() => {
  //   if (queriesData) {
  //     const length = queriesData?.data?.length;
  //     if (length) {
  //       const queries = queriesData?.data?.filter(
  //         (query) => query.status === "draft"
  //       );

  //       setDraftQuery(queries[queries?.length - 1]);
  //     }
  //   }
  // }, [queriesData, ]);

  useEffect(() => {
    if (draftQuery) {
      form.setFieldsValue({
        comment: draftQuery.comment,
      });

      draftQuery?.products?.forEach((item) => {
       
        const fieldName = `${item?.collections_products_id?.id}_comment`;
        // [`${collections_products_id?.id}`, "comment"]
        form.setFieldsValue({
          [fieldName]: item.comment,
        });
      });
    }
  }, [draftQuery]);

  // handle form submit
  const onFinish = (status) => {
    const formValues = form.getFieldsValue();
    const comment = formValues.comment;
    delete formValues["comment"];
    const keys = Object.keys(formValues);
    const values = Object.values(formValues);

    let products = [];
    for (let index = 0; index < keys.length; index++) {
      const element = {
        comment: values[index],
        collections_products_id: parseInt(keys[index]),
      };
      if (keys[index]) {
        products.push(element);
      }
    }

    let requestPayload = {
      products: products,
      collection: collectionDetails?.id,
      comment: comment,
      status: status,
    };
    axios
      .patch(
        `${process.env.REACT_APP_DIRECTUS}items/enquiries/${draftQuery?.id}`,
        requestPayload
      )
      .then((response) => {
        if (response.status === 200) {
          if (status === "sent") {
            removeItem("shortlisted_products");
            setGlobalState.settings.setShortlistedProducts([]);
            history.push(`/sent-query/${collection_link}/${draftQuery?.id}`);
          } else {
            notification.info({
              message: `Saved as draft`,
              placement: "bottomLeft",
            });
          }
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    return () => {
      setDraftQuery(null);
    };
  }, []);
  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : draftQuery?.products?.length > 0 ? (
        <Form form={form} name="nest-messages">
          <Row>
            <Col
              xs={{ offset: 0, span: 24 }}
              sm={{ offset: 0, span: 24 }}
              md={{ offset: 2, span: 20 }}
              lg={{ offset: 2, span: 20 }}
              xl={{ offset: 2, span: 20 }}
            >
              <Title level={2}>Draft Query</Title>
            </Col>
          </Row>
          <Row
            style={
              {
                // borderRadius: 8,
                // paddingTop: "1rem",
                // marginLeft: "2rem",
                // marginRight: "2rem",
              }
            }
          >
            <Col
              xs={{ offset: 0, span: 24 }}
              sm={{ offset: 0, span: 24 }}
              md={{ offset: 2, span: 20 }}
              lg={{ offset: 2, span: 20 }}
              xl={{ offset: 2, span: 20 }}
            >
              <Row>
                <Col
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <IoMdMail color={"#9B9F9E"} size={"2.5rem"} />
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Title level={2} style={{ fontFamily: "Gilroy", margin: 0 }}>
                    {`Send query to ${collectionDetails?.exporter_name}`}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Text type="secondary" style={{ fontSize: 16 }}>
                    An email will be sent to {collectionDetails?.exporter_name}{" "}
                    with your message and any comments that you share
                  </Text>
                </Col>
              </Row>

              <Row style={{ marginTop: "2.5rem" }}>
                <Col
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Title level={5} style={{ fontFamily: "Gilroy", margin: 0 }}>
                    Your Message
                  </Title>
                </Col>
              </Row>

              <Row style={{ marginTop: "0.7rem" }}>
                <Col
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Form.Item name="comment">
                    <Input.TextArea
                      placeholder="Add your query for the collection."
                      rows={3}
                    ></Input.TextArea>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: "2.5rem" }}>
                <Col
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Title level={5} style={{ fontFamily: "Gilroy", margin: 0 }}>
                    Shortlisted products
                  </Title>
                </Col>
              </Row>

              <Row style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                <Col
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  {draftQuery?.products?.length &&
                    draftQuery?.products?.map((product, index) => (
                      <ShortListedCard product={product} key={index} />
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="space-around" className="send-query-actions">
            <Col>
              <Button
                className="emailbtn"
                onClick={() => onFinish("sent")}
                type="primary"
                size="large"
              >
                Submit Query
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Row style={{ marginTop: "2rem" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <EmptyState />
          </Col>
        </Row>
      )}
    </div>
  );
}
