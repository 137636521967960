import "./publicqr.less";
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Typography,
  Input,
  Button,
  message,
  Modal,
  Image,
  Alert,
} from "antd";
import successPng from "../../../assets/images/logo64.png";

const { Text } = Typography;
function TopBar({ manufacturer }) {
  return (
    <div className="topBar">
      {manufacturer?.logo && (
        <img
          src={`${process.env.REACT_APP_DIRECTUS}assets/${manufacturer?.logo}`}
          className="topBar_img"
        />
      )}

      <Text className="topBar_txt">{manufacturer?.name}</Text>
    </div>
  );
}

export default TopBar;
