import { useEffect, useState } from "react";

import  {
    Drawer,
    Collapse,
    Row,
    Col,
    Checkbox,
    Button
} from "antd"; 

import { useGlobal } from "../../../store/index";

import MediaQuery from "react-responsive";

import { Colors } from "theme";

const { Panel } = Collapse;

export default function CatalogueDrawer({
    closeAllFilters,
    Facets,
    Filters,
    applyFilters,
    clearAll
}) {
    const [facets, setFacets] = useState({})
    const [filters, setFilters] = useState({...Filters});
    const [filterCount, setFilterCount] = useState();
    const [globalState, setGlobalState] = useGlobal();

    const deviceType = globalState.deviceType;

    const getFilterCount = () => {
        const _keys = Object.keys(Filters);
        let count = 0;
        _keys.forEach(_key => {
            if(_key !== "search_keyword") {
            count = count + Filters[_key].length;                
            }
        });

        setFilterCount(count);
    }

    const resolveFacetHeader = facet => {
        switch(facet) {
          case "sub_category": return "Sub Category";
          case "secondary_category": return "Category";
          default: return facet;
        }
    }

    useEffect(() => {
        const _keys = Object.keys(Filters);
        const _filters = {...Filters};
        const _facets = {...Facets};
        _keys.forEach(_key => {
            if(_key !== "search_keyword") {
               _filters[_key].forEach(bucket => {
                let index = _facets[_key].buckets.findIndex(_b => _b.key === bucket);
                if(index >= 0) {
                    _facets[_key].buckets[index].checked=true;
                }
               });
            }
        });

        setFacets({..._facets});
        getFilterCount();
    },[Facets, Filters]);

    const updateFilter = (facet, index, current, bucketKey) => {
        const _facets = {...facets};
        _facets[facet].buckets[index].checked = current ? false : true;
        setFilterCount(_count => current ? _count-1 : _count+1);
        setFacets(_facets);
        const _filters = {...filters};
        if(current) {
            
            if(_filters[facet].length === 1) {
                delete _filters[facet]
            } else {
                _filters[facet].splice(_filters[facet].indexOf(bucketKey),1);
            }
        } else {
            _filters[facet] = (_filters[facet]) ? [..._filters[facet], bucketKey] : [bucketKey];
        }

        setFilters({..._filters});
    }

    const getWidth = () => {
        let width = "400";

        if(deviceType === "mobile") {
            width = "100%";
        }
        return width;
    }

    return (
        <Drawer
            className="sw-drawer"
            size="default"
            title="Apply more filters"
            closable={false}
            visible={true}
            placement="right"
            onClose={(e) => {
                e.preventDefault(); closeAllFilters();
            }}
            width={`${deviceType !== "mobile" ? "400" : "100%"}`}
            // width="300"
            style={{ paddingBottom: 100, zIndex:999, display:"block" }}
        >
            {/* <MediaQuery maxWidth={600}> */}
            {deviceType === "mobile" && (
                <div className="clearAll" onClick={(e)=>{e.preventDefault(); clearAll()}}>CLEAR ALL</div>
            )}
            {/* </MediaQuery> */}
            <div>{filterCount} Selected</div>
            <Row style={{paddingTop:"10px"}}>
                <Col md={24} xs={24}>
                <Collapse
                    expandIconPosition="left"
                    bordered={false}
                    style={{ backgroundColor: Colors.white }}
                >
                    {Object.keys(facets).map((facet) => (
                        <>{facets[facet].buckets.length > 0 && (
                        <Panel key={facet} header={resolveFacetHeader(facet)}>
                            {Facets[facet].buckets.map((facetBucket, index) => (
                            <Row id={facetBucket.key}>
                                <Checkbox 
                                    className="checkFilters"
                                    key={`${facet}${index}`}
                                    checked={facetBucket.checked ? facetBucket.checked : false}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        updateFilter(facet, index,facetBucket.checked ? facetBucket.checked : false, facetBucket.key);
                                    }}
                                > 
                                    {facetBucket.key} ({facetBucket.doc_count})
                                </Checkbox></Row>
                            ))}
                        </Panel>
                        )}
                        </>
                    ))}
                </Collapse>
                </Col>
            </Row>
            <div className="drawerBottom"><Row>
                <Col md={12} xs={12}><Button className="drawerCta" onClick={(e) => {e.preventDefault();closeAllFilters()}}>Cancel</Button></Col>
                <Col md={12} xs={12}>
                    <Row style={{justifyContent:"flex-end"}}>
                        <Button className="drawerCta"
                            onClick={(e) => {e.preventDefault();applyFilters(filters)}}
                            style={{backgroundColor:"black",color:"white"}}
                        >
                            Apply
                        </Button>
                    </Row>
                </Col>
            </Row></div>
        </Drawer>
    )
}