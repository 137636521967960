import React, { useEffect, useState } from "react";
import { Modal, Typography, Row, Space } from "antd";
import LoadingGif from "assets/images/loadingGIF.gif";
import { CheckCircleFilled } from "@ant-design/icons";
const { Title, Text } = Typography;
function LoadingModal({ visible, fileType }) {
  const [isVisible, setIsVisible] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  useEffect(() => {
    if (visible) {
      setIsVisible(true);
    } else {
      if (visible === false && fileType) {
        setShowCompleted(true);
        setTimeout(() => {
          setIsVisible(false);
          setShowCompleted(false);
        }, 2000);
      }
    }
  }, [visible, fileType]);

  return (
    <>
      <Modal
        footer={null}
        centered
        visible={isVisible}
        // onOk={() => setVisible(false)}
        // onCancel={() => setVisible(false)}
        closable={false}
        width={600}
      >
        <Row justify="center" style={{ padding: "2rem", textAlign: "center" }}>
          {showCompleted ? (
            <Space direction="vertical" size="large">
              <CheckCircleFilled style={{ fontSize: 80, color: "#0B7764" }} />

              <Title level={3} style={{textTransform: "capitalize"}}>{fileType} Download Started</Title>
            </Space>
          ) : (
            <>
              <img src={LoadingGif} alt="loading" width="60%" />
              <Title style={{ fontSize: 20 }}>
                Getting your {fileType} ready for download
              </Title>
              <Text type="secondary">
                Do not close this window until the download is complete. Please
                be patient, this may take upto a few minutes
              </Text>
            </>
          )}
        </Row>
      </Modal>
    </>
  );
}

export default LoadingModal;
