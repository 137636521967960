import { getItem } from "utils/localStorage";

export const getShortedListedProductsFrom = () => {
  const products = JSON.parse(getItem("shortlisted_products")) || [];
  const result = products.sort((a, b) => a?.id - b?.id);
  return result;
};

export const isValidUrl = urlString => {
	var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
	  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
	  '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
	  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
	  '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
	  '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
	return !!urlPattern.test(urlString);
  }
