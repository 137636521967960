import React, { useState, useEffect } from "react";
import { Table } from "antd";

const transformTableData = (data = []) => {
  const rows = data.map((r) => {
    const attrs = r?.attributes?.reduce((previousAttr, currentAttr, index) => {
      if(currentAttr.attribute_values_id.composite && currentAttr.attribute_values_id.composite.currency) {
        return {
          ...previousAttr,
          [currentAttr.attribute_values_id.attribute_id.name]:`${currentAttr.attribute_values_id.composite.currency} ${currentAttr.attribute_values_id.values}`,
        };
      }
      return {
        ...previousAttr,
        [currentAttr.attribute_values_id.attribute_id.name]:
          currentAttr.attribute_values_id.values,
      };
    }, {});

    return {
      "Variant Id": r.variant_id,
      ...attrs,
    };
  });

  const cols = data?.[0]?.attributes?.map((at) => {
    const colName = at.attribute_values_id.attribute_id.name;
    return {
      title: colName,
      dataIndex: colName,
      key: colName,
    };
  });

  const otherCols = cols.filter((item) => item.dataIndex !== "Selling Price");
  const sellPriceCol =
    cols.length === otherCols.length
      ? []
      : [
          {
            title: "Price",
            dataIndex: "Selling Price",
            key: "Selling Price",
          },
        ];

  return {
    rows,
    cols: [
      {
        title: "Variant Id",
        dataIndex: "Variant Id",
        key: "Variant Id",
      },
      ...otherCols,
      ...sellPriceCol,
    ],
  };
};

const VariantsTable = ({ data }) => {
  const [{ rows, cols }, setTableData] = useState({});

  useEffect(() => {
    const { rows, cols } = transformTableData(data);
    setTableData({ rows, cols });
  }, [data]);

  return (
    <div>
      <Table
        bordered
        columns={cols}
        size="small"
        scroll={{ x: "max-content" }}
        dataSource={rows}
        pagination={false}
      />
    </div>
  );
};

export default VariantsTable;
