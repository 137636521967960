import React from "react";
import { Input, Row, Col, Typography, Form } from "antd";
import ProductCard from "./ProuductCard";
const { TextArea } = Input;
const { Title, Text } = Typography;

export default function ShortListedCard({ product }) {
  console.log(product,'product')
  return (
    <Row style={{ marginBottom: "2.5rem" }} align="middle">
      <Col
        lg={{ span: 6 }}
        xl={{ span: 6 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <ProductCard product={product?.collections_products_id} />
      </Col>
      <Col
        lg={{ span: 16, offset: 2 }}
        xl={{ span: 16, offset: 2 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Form.Item name={`${product?.collections_products_id?.id}_comment`}>
          <TextArea
            className="query-box"
            defaultValue={product?.comment}
           // rows={5}
            placeholder="Add comments for this product"
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
