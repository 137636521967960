// import { collection_link } from "../routes/RouteApp";

// set localStorage item
export const setItem = (key, value, ) => {
  var collection_link = window.name;

  try {
    console.log("collection_link setItem", collection_link );
    if (collection_link) {
      localStorage.setItem(
        key === collection_link 
          ? collection_link
          : `${collection_link}-${key}`,
        value
      );
    }
  } catch (error) {
    console.log("saving failed:", error);
  }
};
// get localStorage item
export const getItem = (key) => {
  var collection_link = window.name;

  try {
    if (
      collection_link &&
      localStorage.getItem(
        key === collection_link ? collection_link : `${collection_link}-${key}`
      )
    ) {
      const item = localStorage.getItem(
        key === collection_link ? collection_link : `${collection_link}-${key}`
      );
      return item;
    } 
    else if (localStorage.getItem("product-details-sessionId-public")) {
      const item = localStorage.getItem("product-details-sessionId-public" &&
      "product-details-sessionId-public");
      return item
    }
    else {
      return null;
    }
  } catch (error) {
    console.log("getting failed:", error);
  }
};

// remove localStorage item
export const removeItem = (key) => {
  var collection_link = window.name;

  try {
    if (collection_link) {
      localStorage.removeItem(
        key === collection_link ? collection_link : `${collection_link}-${key}`
      );
    }
  } catch (error) {
    console.log("removing failed:", error);
  }
};

// clear localStorage
export const clear = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.log("clearing failed:", error);
  }
};
