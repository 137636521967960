import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Space} from "antd"
import Logo from "../../../assets/images/Logo.svg"
import { MdCloudDownload, MdFullscreen } from "react-icons/md";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Modal from "./Modal";
import { CheckCircleFilled } from "@ant-design/icons";
import LoadingGif from "assets/images/loadingGIF.gif";

const { Text, Title}  = Typography;

export default function FileDownloader() {
    const [filetype, setFiletype] = useState(null);
    const [spinning, setspinning] = useState(false);
    const [finalText, setFinalText] = useState(false);
    const { id } = useParams();
    
    const downloadPPT = () => {
        setspinning(true);
        setFiletype("PPT");
        axios.get(
          `${process.env.REACT_APP_API_SERVICE}pptservice/?collection_link=${id}`
        )
          .then((res) => {
            console.log(res.data["ppt_url"]);
            let url = res.data["ppt_url"];
            window.open(url, "_self");
            // fileDownload(res.data, `${collectionDetails?.collection_name}.pptx`);
            setspinning(false);
            setFiletype("PPT");
            setFinalText(true);
          })
          .catch((err) => {
            setspinning(false);
            setFiletype(null);
            setFinalText(true);
          });
      };

    useEffect(() => {
        downloadPPT()
    }, [])

    useEffect(() => {
   if (finalText) {
     
   }
    }, [finalText])
    

  return (<>
     {/* <Modal visible={spinning} fileType={filetype} /> */}
     <Row justify='center' align="middle" style={{ height: "100vh", background: "#f1f3f4"}}>
        <Col lg={12} xl={12} xxl={12} md={12} sm={24} xs={24} style={{background:'white', minHeight:'60vh', borderRadius:8}} >
            <Row justify="center" align='middle' style={{ padding: "2rem", textAlign: "center", height: "100%" }}>
          {finalText ? (
            <Row justify='center' align='middle' style={{height:'60vh',}}>
                <Space direction="vertical" size="large">
              <CheckCircleFilled style={{ fontSize: 80, color: "#0B7764" }} />

              <Title level={3}> Download Started</Title>
            </Space>
                </Row>
          ) : (
            <>
              <img src={LoadingGif} alt="loading" width="60%" />
              <Title style={{ fontSize: 20 }}>
                Getting PPT {""} ready for download
              </Title>
              <Text type="secondary">
                Do not close this window until the download is complete.
              </Text>
            </>
           )} 
        </Row>
                
                                    
        </Col>
    </Row>
  </>
    
  )
}
