import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button, Typography, Layout, message } from "antd";
import successPng from "./EmailSent.svg";
import { Colors } from "../../../theme";
import { useHistory, useParams } from "react-router-dom";
import "./index.css";
import {getItem,removeItem} from "utils/localStorage"
export default function EmailSentSuccess({ searchParams, setIsAccessGranted }) {
  const params = useParams();
  const history = useHistory();
  const [email, setEmail] = useState(null);
  const [ExportnerName, setExportnerName] = useState(null);

  
  useEffect(() => {
    const collectionData = getItem("collection_details");
    if (collectionData) {
      const collectionDataParsed = JSON.parse(collectionData);

      setExportnerName(
        collectionDataParsed?.exporter_name
          ? collectionDataParsed?.exporter_name
          : null
      );
    }
  }, []);

  function IsCatalogueActive() {
    axios
    .get(
      `${process.env.REACT_APP_API_SERVICE}link_security/get_verification_data?collection_link=${params.id}`
    )
    .then((res) => {
      if (res?.data?.status === "inactive" || res?.data?.expiry) {
        removeItem("shortlisted_products");
        setIsAccessGranted(false);
        //message.error("The catalogue link has expired!!");
        //history.push(`/${params.expname}/${params.id}`);


        history.push({
          pathname: params?.expname
            ? `/${params.expname}/${params.id}`
            : `/${params.id}`,
          search: searchParams,
        });
      }
    });
  }

  useEffect(() => {
    const timer = setInterval(IsCatalogueActive, 30000);
    return () => clearInterval(timer);
  }, []);
 

  return (
    <div>
      <Row className="mainContainer">
        <Col span={24} className="alignCenter">
          <Row align="middle" className="imagesvg">
            <img src={successPng} alt="sourcewiz" style={{ width: "100%" }} />
          </Row>

          <Row align="middle">
            <span
              style={{
                fontSize: "32px",
                textAlign: "center",
                color: Colors.black,
                fontFamily: "Gilroy",
                padding: "1rem",

                marginTop: "1em",
              }}
            >
              Query sent to {ExportnerName}
            </span>
          </Row>

          <Row align="middle">
            <span
              style={{
                fontSize: "20px",
                textAlign: "center",
                color: Colors.gray,
                fontStyle: "normal",

                padding: "1rem",
              }}
            >
              Your query has been sent to{" "}
              {getItem("ExporterEmail").replace(/"/g, "")} along
              with you at {getItem("BuyerEmail")}
            </span>
          </Row>

          <Row justify="center" style={{ marginTop: "3em" }}>
            <Button
              className="cta"
              size="large"
              onClick={() =>
                history.push({
                  pathname: params?.expname ? `/${params?.expname}/${params?.id}`: `/${params?.id}`,
                  state: searchParams,
                })
              }
            >
              View Catalogue
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
