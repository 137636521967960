import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Divider } from "antd";
import SVG from "./background.svg";
import ImagePlacholder from "../../../assets/images/image-placeholder.png";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../../store/index";

import API_KEY from "../../../api";
import MediaQuery from "react-responsive";
import { setItem, clear } from "utils/localStorage";

const useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
  const [src, setSrc] = useState(lowQualitySrc);
  useEffect(() => {
    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [lowQualitySrc, highQualitySrc]);

  return [src, { blur: src === lowQualitySrc }];
};

export default function LogoComponent() {
  const params = useParams();
  const { Title, Text } = Typography;
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const history = useHistory();

  setItem("collection_link", params?.id);
  const [globalState, setGlobalState] = useGlobal();
  const deviceType = globalState.deviceType;

  const { collection_link } = globalState;
  const [collectionDetails, setCollectionDetails] = useState(null);
  const { hide_catalogue_name = false } = globalState?.catalogueSettings;
  
  useEffect(() => {
    const searchString = window.location.search;
    // const urlParams = new URLSearchParams(searchString);
    // removeItem("type");
    // console.log(params)
    if (urlParams.get("type") === "PREVIEW") {
      setItem("type", "PREVIEW");
    }
    API_KEY.URL.get(API_KEY.path.collections, {
      params: {
        "filter[collection_link][_eq]": params?.id,
        fields: "*",
      },
    }).then((res) => {
      console.log("Collection_data: ", res);
      setCollectionDetails(res?.data?.data?.length ? res?.data?.data[0] : "");
      setItem(
        "collection_details",
        res?.data?.data?.length ? JSON.stringify(res?.data?.data[0]) : ""
      );
    });
  }, [collection_link, params]);

  const tinyImage = `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.banner_image}?quality=10`;
  const largeImage = collectionDetails?.banner_image
    ? `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.banner_image}?quality=60`
    : SVG;

  const logolarge = `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.logo}?quality=100`;

  const [src, { blur }] = useProgressiveImg(tinyImage, largeImage);

  return (
    <>
      <Row
        align="middle"
        style={{ borderBlockEnd: "1px solid #E6E7E7", marginBottom: "2.8em" }}
      >
        <Col>
          <div
            className={blur ? "box animate" : ""}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              marginBottom: "0.5rem",
              transition: "filter 0.5s ease-out",
            }}
          >
            <img
              className="buyer-logo"
              src={logolarge}
              alt="Buyer Portal"
              style={{
                display: "block",
                borderRadius: "0.5rem",
                filter: blur ? "blur(20px)" : "none",
                transition: blur ? "none" : "filter 0.3s ease-out",
              }}
              onError={(e) => {
                e.target.src = collectionDetails?.logo
                  ? `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.logo}?quality=70`
                  : ImagePlacholder;
              }}
            />
          </div>
        </Col>
        {/* <MediaQuery minWidth={600}> */}
        {deviceType !== "mobile" && (
          <Col style={{ marginLeft: "1.5rem" }}>
            <Row>
              <Title style={{ fontSize: "20px", margin: 0 }}>
                {collectionDetails?.exporter_name ||
                  collectionDetails?.buyer_name}
              </Title>
            </Row>
            {!hide_catalogue_name && (
              <Row>
                <Text style={{ fontSize: "14px", color: "#363E3D" }}>
                  {collectionDetails?.collection_name}
                </Text>
              </Row>
            )}
          </Col>
        )}
        {/* </MediaQuery> */}

        {/* <MediaQuery maxWidth={600}> */}
        {deviceType === "mobile" && (
          <Col style={{ marginLeft: "1em" }}>
            <Row>
              <Title style={{ fontSize: "16px", margin: 0 }}>
                {collectionDetails?.exporter_name ||
                  collectionDetails?.buyer_name}
              </Title>
            </Row>
            {!hide_catalogue_name && (
              <Row>
                <Text style={{ fontSize: "12px", color: "#363E3D" }}>
                  {collectionDetails?.collection_name}
                </Text>
              </Row>
            )}
          </Col>
        )}
        {/* </MediaQuery> */}
      </Row>
    </>
  );
}
