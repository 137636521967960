import React from "react";
import SentQuery from "./SentQuery";

export default function SentQueries() {
  return (
    <div>
      <SentQuery />
    </div>
  );
}
