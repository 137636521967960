import React from "react";
import { Row, Col, Typography, Input, Card, Tooltip } from "antd";
import { Colors } from "../../../theme";
import ProductDetails from "./ProductDetails";
import { IoMdMail } from "react-icons/io";
import { FaShareAltSquare } from "react-icons/fa";
import './styles.css'
import { useParams } from "react-router-dom";

const { Title, Text } = Typography;
const { TextArea } = Input;

export default function SentQueryCard({ activeQuery }) {
  const params = useParams();
  const collection_link = params?.id;

  const share_link = () => {
    navigator.clipboard.writeText(
      `https://catalogue.sourcewiz.co/sent-queries/${collection_link}`
    );
    // const success = message.success("Copied to Clipboard");
    // setTimeout(success, 2000);
  };
  return (
    <div>
      <Card>
        <Row style={{ background: "#FFF" }}>
          <Col
            style={{
              background: "#FFF",
              // marginLeft: "2rem",
              // marginRight: "2rem",
            }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row justify="space-between">
              <IoMdMail color={"#9B9F9E"} size={"2.5rem"} />

              <Col>
                <Tooltip title="Copied to Clipboard!"
                  trigger="click">
                  <FaShareAltSquare
                    onClick={share_link}
                    size={"2rem"}
                    style={{
                      color: Colors.primaryColor,
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Title level={2} style={{ fontFamily: "Gilroy", margin: 0 }}>
                Query ID ENQSWZ{activeQuery?.id}
              </Title>
            </Row>
            <Row>
              <Text
                style={{
                  color: Colors.gray,
                  fontSize: "1rem",
                  marginBottom: "2rem",
                }}
              >
                Shared on{" "}
                {activeQuery?.date_created
                  ? activeQuery?.date_created?.slice(0, 10)
                  : null}
              </Text>
            </Row>
            <Row style={{ marginBottom: "2rem" }}>
              <Title level={5}>Your message</Title>
              <TextArea
                disabled
                value={activeQuery?.comment}
                rows={4}
                placeholder="Hi, I would like to enquire about these products from the catalogue that was shared."
              />
            </Row>
            <Row style={{ paddingBottom: "1rem" }}>
              <Title level={5}>Shortlisted Products</Title>
            </Row>

            {activeQuery?.products.length &&
              activeQuery?.products.map((product, index) => (
                <ProductDetails key={index} product={product} />
              ))}
          </Col>
        </Row>
      </Card>
    </div>
  );
}
