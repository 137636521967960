import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Col,
  Form,
  Typography,
  Input,
  Button,
  message,
  Modal,
  Image,
  Alert,
  Space,
  Affix,
  Breadcrumb,
} from "antd";
import ProductCard from "./ProductCard";
import Lottie from "react-lottie";
import { IoMdMail } from "react-icons/io";
import axios from "axios";
import { useHistory, useParams, Link } from "react-router-dom";
import { useGlobal } from "../../../store/index";
import Axios from "axios";

import API_KEY from "../../../api";
import { getShortedListedProductsFrom } from "utils";
import Loading from "containers/Loading";
import { Colors } from "theme";
import "./index.css";
import EmptyState from "./ShortlistedEmptyState";
import {
  sendAnalyticsEvent,
  updateVisitorInfo,
  patchAnalyticsEvent,
} from "utils/sendAnalyticsEvent";
import MediaQuery from "react-responsive";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ProductActions from "redux/actions/productActions";
import ImagePlacholder from "../../../assets/images/image-placeholder.png";
import StickyBox from "react-sticky-box";
import { WarningFilled } from "@ant-design/icons";

import { setItem, removeItem, getItem } from "utils/localStorage";
import ImageRouter from "react-common-components/ImageRouter";
import chat2 from "../../../assets/images/chat2.svg";

import wishlist from "../../../assets/images/wishlist.svg";
import wishlists2 from "../../../assets/images/wishlists2.svg";
import quote from "../../../assets/images/quote.svg";
import quote2 from "../../../assets/images/quote2.svg";
import deletesvg from "../../../assets/images/delete.svg";
import WishlistedBlip from "../../../assets/images/WishlistedBlip.svg";

import ShortlistOutline from "../../../assets/images/ShortlistOutline.svg";
import ShortlistBlip from "../../../assets/images/ShortlistBlip.svg";
import ShortlistGreen from "../../../assets/images/ShortlistGreen.svg";

import leftArrow from "../../../assets/images/leftArrow.svg";
import download from "../../../assets/images/download.svg";

import * as quoteAnimation from "../../../routes/SliderView/QuoteLottie.json";
import * as successAnimation from "../../../routes/SliderView/SuccessLottie.json";
import { useLocation } from "react-router-dom";

// import { useGlobal } from "../../../store/index";

const { Title, Text } = Typography;
const { TextArea } = Input;

const defaultOptionsQuote = {
  loop: false,
  autoplay: true,
  animationData: quoteAnimation.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function SendQuery({ actions, setIsAccessGranted, checkedProducts }) {
  const [shortlisted, setShortlisted] = useState([]);
  const params = useParams();
  const collectionLink = params?.id;
  const [collectionDetails, setCollectionDetails] = useState(null);
  const [form] = Form.useForm();
  const history = useHistory();

  const [globalState, setGlobalState] = useGlobal();
  const [status, setStatus] = useState("sent");
  const collection_link = params?.id;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [filetype, setFiletype] = useState(null);
  const [buyeremail, setBuyeremail] = useState(null);
  const [validEmail, setValidEmail] = useState(false);
  const [allowedPPTDownload, setAllowedPPTDownload] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const location = useLocation();

  const deviceType = globalState.deviceType;

  const [email, setEmail] = useState(null);
  const [emailChanged, setEmailChanged] = useState(false);
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);

  const [second, setSecond] = useState(false);

  const isPreview = urlParams.get("type") === "PREVIEW" ? true : false;

  useEffect(() => {
    const timer = setInterval(view, 5000);
    return () => clearInterval(timer);
  }, []);

  const view = async () => {
    const collection = localStorage.getItem("collection_link");
    const sessionId = localStorage.getItem(collection);
    // this call identifies the session to check if the session is live
    await axios.post(
      `${process.env.REACT_APP_API_SERVICE}analytics/session/view/?sessionId=${sessionId}&identifier=${collection}`
    );
  };

  const handleConfirm = (values) => {
    sendAnalyticsEvent("buyer_side_gq_for_all_submit", {
      name: "buyer_side_gq_for_all_submit",
      modules: "get quote",
      location: location.pathname,
      email: values?.email_id,
    });
    const data = { email: values?.email_id };
    patchAnalyticsEvent(data);
    setStatus((state) => "sent");
    setsubmitLoading((state) => true);
    onFinishEnd();
  };

  const handleBack = () => {
    setShowModal((state) => !state);
  };

  const validateMessages = {
    required: "Email is required!",
    types: {
      email: "Email is not a valid!",
    },
  };

  const isCatalogueActive = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVICE}link_security/get_verification_data?collection_link=${params.id}`
      )
      .then((res) => {
        if (res?.data?.status === "inactive" || res?.data?.expiry) {
          removeItem("shortlisted_products");
          actions.setProducts([]);

          history.push({
            pathname: params?.expname
              ? `/${params?.expname}/${collection_link}`
              : `/${collection_link}`,
            search: urlParams.toString(),
          });
          setIsAccessGranted(false);
        }
        if (res?.data?.status === "published")
          setAllowedPPTDownload(res?.data?.download_ppt);
      });
  }, []);

  useEffect(() => {
    const timer = setInterval(isCatalogueActive(), 30000);
    return () => clearInterval(timer);
  }, [isCatalogueActive]);

  // Get shortlisted products and collection data from local storage
  useEffect(() => {
    const products = getShortedListedProductsFrom();
    setShortlisted(products);
    const collectionData = getItem("collection_details");
    setCollectionDetails(collectionData ? JSON.parse(collectionData) : null);
  }, []);

  // handle form submit
  const onFinishEnd = () => {
    const formValues = form.getFieldsValue();
    const comment = formValues.comment;
    delete formValues["comment"];
    const keys = Object.keys(formValues);
    const values = Object.values(formValues);
    const BuyerMailID = getItem("BuyerEmail");

    console.log("Confirmed:", isConfirmed, ", status: ", status);

    let products = [];
    for (let index = 0; index < keys.length; index++) {
      const _prod =
        checkedProducts.filter((p) => p.id === parseInt(keys[index])).length > 0
          ? checkedProducts.filter((p) => p.id === parseInt(keys[index]))[0]
          : null;
      const element = {
        comment:
          values[index]?.comment !== undefined
            ? values[index].comment
            : _prod?.comment,
        collections_products_id: parseInt(keys[index]),
      };
      if (keys[index]) {
        products.push(element);
      }
    }

    let requestPayload = {
      products: products,
      collection: collectionDetails?.id,
      comment: comment,
      status: status,
      buyer_email: BuyerMailID,
      email: email,
    };

    API_KEY.URL.post(
      `${process.env.REACT_APP_DIRECTUS}items/enquiries`,
      requestPayload
    )
      .then((response) => {
        if (response.status === 200) {
          const enquiry_id = response?.data?.data?.id;
          // get to  custom/email-service/new_enquiry/:pk
          sendAnalyticsEvent("send_query", { enquiry_id });
          axios
            .get(
              `${process.env.REACT_APP_DIRECTUS}custom/email-service/new_enquiry/${enquiry_id}`
            )
            .then(function (response) {
              console.log("Sent Images:", response);
              setItem("ExporterEmail", response.data?.buyer_email?.[0]);

              console.log("Hey!!", status);
              if (status === "sent") {
                setSecond(true);
                // history.push({
                //   pathname: params?.expname
                //     ? `/sent-query/${params?.expname}/${collection_link}/${enquiry_id}`
                //     : `/sent-query/${collection_link}/${enquiry_id}`,
                //   search: urlParams.toString(),
                // });
              } else {
                message.success("Saved as draft successfully.");
                if (status === "draft") {
                  history.push(
                    `/draft-queries/${collection_link}/${enquiry_id}`
                  );
                }
              }
              setLoading(false);
              setsubmitLoading(false);
              setShowModal(false);
            });
        }
      })
      .catch((error) => {
        setShowModal(false);
        console.log(error);
      });
  };

  const handleSendQuery = () => {
    sendAnalyticsEvent("buyer_side_gq_for_all", {
      name: "buyer_side_gq_for_all",
      modules: "get quote ",
      location: location.pathname,
    });
    setShowModal((state) => !state);
    setValidEmail(getItem("BuyerEmail"));
  };

  const handleModalClose = () => {
    setSecond(false);
    removeItem("shortlisted_products");
    actions.setProducts([]);
    setGlobalState.settings.setShortlistedProducts([]);
    form.resetFields();
  };

  const handleGridView = () => {
    history.push({
      pathname: params?.expname
        ? `/${params?.expname}/${collectionLink}`
        : `/${collectionLink}`,
      search: urlParams.toString(),
    });
    sendAnalyticsEvent("show_grid_view", { collection_link: collectionLink });
  };

  const downloadWishlisted = () => {
    let products_list = [];
    checkedProducts.forEach((product) => {
      products_list.push(product?.id);
    });
    setDownloadLoader(true);

    Axios.get(
      `${process.env.REACT_APP_API_SERVICE}pptservice/?collection_link=${collectionDetails?.collection_link}&short_listed=${products_list}`
    )
      .then((res) => {
        sendAnalyticsEvent("download_wishlisted_ppt", {
          products_list: products_list,
        });
        setDownloadLoader(false);
        console.log(res.data["ppt_url"]);
        let url = res.data["ppt_url"];
        window.open(url, "_self");
        setFiletype("shortlisted products");
      })
      .catch((err) => {
        setFiletype(null);
        setDownloadLoader(false);
      });
  };

  const handleDelete = (item) => {
    setShortlisted(shortlisted.filter((product) => product.id !== item.id));
    setItem(
      "shortlisted_products",
      JSON.stringify(shortlisted.filter((product) => product.id !== item.id))
    );
    setGlobalState.settings.setShortlistedProducts(
      shortlisted.filter((product) => product.id !== item.id)
    );
    actions.removeProduct(item, item?.id);
  };


  const priceTitle = checkedProducts?.attributes?.filter(
    (item) =>
      item?.attribute_values_id?.attribute_id?.name === "Selling Price" &&
      !item?.attribute_values_id?.attribute_id?.is_internal
  );


  const uniqueVariantDataMap = checkedProducts.reduce((acc, product) => {

    const filteredProduct =
      product?.variant_meta?.variant_data_map?.filter(
        (item) => item.variant_id === product.id
      )[0] || {};

    const { variant_id, ...restKeys } = filteredProduct;
    const attributeNameMap = Object.keys(restKeys).reduce((agg, key) => {
      const attribute = product?.variant_meta?.hinge_attributes?.find(
        (attr) => attr.id.toString() === key.toString()
      );

      return {
        ...agg,
        [attribute?.name]:
          attribute?.id !== product?.selling_priceAttr
            ? restKeys[key]
            : (product?.product_price || [])?.join(" ")
      };
    }, {});

    return {
      ...acc,
      [product.id]: { ...attributeNameMap },
    };
  }, {});

  return (
    <>
      <Row>
        <Col sm={24} xs={24} md={24} lg={24} xl={24}>
          <StickyBox
            className="sticky-container2"
            style={{
              background: Colors.white,
              height: "5.5em",
              zIndex: 999,
              borderBlockEnd: "1px solid #E6E7E7",
              borderRadius: "0.2em",
            }}
          >
            <Row className="header-wishlist-page" align={"middle"}>
              <Col
                xs={{ span: 20, offset: 0 }}
                sm={{ span: 20, offset: 0 }}
                md={{ span: 16, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Row align="middle">
                  <Col>
                    <Image
                      preview={false}
                      // height='5em'
                      // width={100}
                      className="buyer-logo"
                      src={
                        collectionDetails?.logo
                          ? `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.logo}?quality=100`
                          : ImagePlacholder
                      }
                      alt="Buyer Portal"
                      style={{
                        display: "block",
                        borderRadius: "5px",
                        objectFit: "contain",
                        height: "65px",
                        width: "65px",
                      }}
                    />
                  </Col>
                  <Col
                    md={{ span: 18, offset: 0 }}
                    style={{ marginLeft: "0.5em" }}
                  >
                    <Row>
                      <Text
                        className="exporterName"
                        level={5}
                        style={{ fontFamily: "Gilroy", margin: 0 }}
                      >
                        {collectionDetails?.exporter_name ||
                          collectionDetails?.buyer_name}
                      </Text>
                    </Row>
                    {/* <Row style={{ paddingBottom: "0.4em" }}>
                    <Text style={{ color: Colors.gray }}>
                      {collectionDetails?.collection_name}
                    </Text>
                  </Row> */}
                  </Col>
                </Row>
              </Col>
              <Col
                xs={{ span: 4, offset: 0 }}
                sm={{ span: 4, offset: 0 }}
                md={{ span: 8, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
              >
                <Row
                  className="pb-2 catlogue-actions"
                  justify="end"
                  align="bottom"
                >
                  <Space align="baseline">
                    <span
                      disabled={!checkedProducts.length}
                      className={`flex`}
                      onClick={() => history.goBack()}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="img wishlistIcon"
                        src={
                          checkedProducts.length > 0
                            ? ShortlistBlip
                            : ShortlistOutline
                        }
                        alt="wishlist"
                        width={deviceType === "mobile" ? 24 : 18}
                        height={deviceType === "mobile" ? 24 : 18}
                      />
                      {/* <MediaQuery minWidth={600}> */}
                      {deviceType !== "mobile" && (
                        <span>
                          {checkedProducts.length
                            ? ` My Shortlist (${checkedProducts.length})`
                            : ` My Shortlist`}
                        </span>
                      )}
                      {/* </MediaQuery> */}
                      {/* <MediaQuery maxWidth={500}> */}
                      {deviceType === "mobile" && (
                        <span style={{ fontSize: "16px" }}>
                          {checkedProducts.length
                            ? `  (${checkedProducts.length})`
                            : ` `}
                        </span>
                      )}
                      {/* </MediaQuery> */}
                    </span>
                  </Space>
                </Row>
              </Col>
            </Row>
          </StickyBox>

          {isPreview && (
            <Alert
              showIcon={false}
              message={
                <span>
                  <WarningFilled style={{ color: "red" }} /> This is a preview
                  of the catalogue. Do not share this link with the buyer.
                </span>
              }
              style={{
                color: Colors.white,
                fontSize: "1.5rem",
                textAlign: "center",
                background: "#FDDBDB",
                //top: 64,
                zIndex: 0,
              }}
              banner
            />
          )}

          <Row className="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link onClick={() => handleGridView()}>Catalogue</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={{}}>My Shortlist</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>

          <Row className="wishlist-download">
            <Col xs={18} sm={16} md={17} lg={18} xl={20}>
              <Text ellipsis className="t2" onClick={() => history.goBack()}>
                <img
                  src={leftArrow}
                  alt="Go back"
                  width={20}
                  style={{
                    display: "inline",
                    padding: "0.1rem 0.3rem 0.25rem 0",
                    cursor: "pointer",
                  }}
                />
                <span className="cursor">
                  {checkedProducts.length === 0
                    ? `My shortlist`
                    : `My shortlist ( ${checkedProducts.length}  ${checkedProducts.length === 1 ? "product" : "products"
                    } )`}
                </span>
              </Text>
            </Col>

            <Col xs={6} sm={8} md={7} lg={6} xl={4}>
              {allowedPPTDownload &&
                checkedProducts.length > 0 &&
                deviceType !== "mobile" ? (
                <Button
                  className="t5"
                  type="outline"
                  size="large"
                  loading={downloadLoader}
                  disabled={checkedProducts?.length > 0 ? false : true}
                  style={{
                    width: "98%",
                    height: "3rem",
                    color: Colors?.gray1,
                    border: "1px solid #9B9F9E",
                    wordBreak: "break-word",
                  }}
                  onClick={() => downloadWishlisted()}
                >
                  {window.innerWidth > 400 ? (
                    <span>
                      <img
                        src={download}
                        alt="download"
                        size={20}
                        style={{
                          display: "inline",
                          padding: "0 0.4rem 0.25rem 0",
                        }}
                      />
                      Download Shortlisted
                    </span>
                  ) : (
                    <img
                      src={download}
                      alt="download"
                      size={20}
                      style={{
                        display: "inline",
                        padding: "0 0.4rem 0.25rem 0",
                      }}
                    />
                  )}
                </Button>
              ) : null}
            </Col>
          </Row>

          <Row gutter={24} className="checked-products">
            <Col xs={24} sm={24} md={24} lg={24} xl={14}>
              {checkedProducts?.length > 0 && (
                <Form
                  form={form}
                  validateMessages={validateMessages}
                  initialValues={{
                    comment:
                      "Hi, \n I would like to enquire about these products from the catalogue that was shared.",
                  }}
                  name="nest-messages"
                >
                  {checkedProducts?.map((product, index) => {
                    product.comment = sessionStorage.getItem(product?.id);
                    return (
                      <Row>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                          className="gutter-row"
                        >
                          <Row gutter={8} style={{ marginBottom: "2rem" }}>
                            <Col
                              lg={{ span: 7 }}
                              xl={{ span: 9 }}
                              md={{ span: deviceType === "mobile" ? 24 : 8 }}
                              sm={{ span: deviceType === "mobile" ? 24 : 11 }}
                              xs={{ span: deviceType === "mobile" ? 24 : 12 }}
                              className={
                                deviceType === "mobile" ? "mobileQuery" : ""
                              }
                            >
                              <ProductCard
                                product={product}
                                handleDelete={handleDelete}
                              />
                            </Col>
                            <Col
                              lg={{ span: 17 }}
                              xl={{ span: 15 }}
                              md={{ span: deviceType === "mobile" ? 24 : 16 }}
                              sm={{ span: deviceType === "mobile" ? 24 : 13 }}
                              xs={{ span: deviceType === "mobile" ? 24 : 12 }}
                            >
                              <Row style={{ padding: "1rem 0 0 1rem" }}>
                                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                  <Title
                                    className="product-id"
                                    style={{
                                      fontWeight: "bolder",
                                      fontSize: "1rem",
                                    }}
                                    level={5}
                                    ellipsis
                                  >
                                    {product?.products_id
                                      ? `ID: ${product?.products_id}`
                                      : ""}
                                  </Title>
                                  <Row className="shortlistedAtttributeContainer">
                                    <Row className="shortlistedAtttribute">
                                      {Object.entries(
                                        uniqueVariantDataMap[product.id]
                                      ).map(
                                        ([attributeKey, attributeValue]) => (
                                          <Text className="variantPill">
                                            {attributeKey} :{" "}
                                            {attributeValue
                                              ? attributeValue
                                              : ""}
                                          </Text>
                                        )
                                      )}
                                    </Row>
                                  </Row>
                                </Col>
                                <Col
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  align="end"
                                  onClick={() => handleDelete(product)}
                                >
                                  <img
                                    src={deletesvg}
                                    alt="delete"
                                    size={20}
                                    style={{
                                      display: "inline",
                                      padding: "0 0rem 0.25rem 0",
                                      color: Colors.gray,
                                      cursor: "pointer",
                                    }}
                                  />
                                </Col>
                              </Row>

                              <Form.Item
                                name={[`${product?.id}`, "comment"]}
                                style={{ paddingLeft: "1rem" }}
                              >
                                <TextArea
                                  className="query-box"
                                  defaultValue={
                                    product &&
                                      product.comment &&
                                      product.comment !== "undefined"
                                      ? product.comment
                                      : ""
                                  }
                                  placeholder="Any special request or customization"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}

                  <Modal
                    keyboard={true}
                    closable={false}
                    centered
                    width={"400px"}
                    visible={showModal}
                    destroyOnClose={true}
                    footer={null}
                    bodyStyle={{
                      borderRadius: "8px",
                      height: "236px",

                      boxShadow: "inherit",
                    }}
                  >
                    <Form
                      name="email-form"
                      className="email-form"
                      initialValues={{
                        email_id: getItem("BuyerEmail"),
                      }}
                      onFinish={handleConfirm}
                    >
                      <Title
                        level={5}
                        style={{
                          family: "DM Sans",
                          style: "normal",
                          weight: "bold",
                          size: "18px",
                        }}
                      >
                        We need your email id to send this query
                      </Title>

                      <Text
                        level={5}
                        style={{
                          family: "DM Sans",
                          style: "normal",
                          weight: "normal",
                          size: "16px",
                        }}
                      >
                        Enter email ID
                      </Text>
                      <Form.Item
                        name="email_id"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Email!",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          placeholder="jhon@doe.com"
                          size="large"
                          style={{
                            width: "100%",
                            marginTop: "0.8rem",
                            marginBottom: "1.2rem",
                          }}
                          onChange={(e) => {
                            setEmailChanged(true);
                            setBuyeremail(e.target.value);
                            setEmail(e.target.value);
                            setItem("BuyerEmail", e.target.value);
                          }}
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          size="large"
                          className="emailbtn"
                          style={{
                            background: Colors.white,
                            color: Colors.black,
                          }}
                          onClick={handleBack}
                        >
                          Go Back
                        </Button>
                        <Button
                          loading={submitLoading}
                          className="emailbtn"
                          size="large"
                          type="primary"
                          htmlType="submit"
                          style={{
                            background: Colors.black,
                            color: Colors.white,
                            border: Colors.black,
                            float: "right",
                          }}
                        >
                          Confirm
                        </Button>
                      </Form.Item>
                    </Form>
                  </Modal>
                </Form>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
              {checkedProducts.length > 0 && (
                <Affix offsetTop={80}>
                  <Row
                    className={`quote-card ${deviceType === "mobile" ? "quoteMobile" : ""
                      }`}
                    style={{ maxWidth: "30rem", margin: "auto" }}
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row className="t5">
                        Request quote for your shortlisted products
                      </Row>
                      <Row
                        className="t6 quote-text"
                        style={{ color: Colors?.gray1, paddingTop: "0.5rem" }}
                      >
                        Enquiry will be sent for details of your shortlisted
                        products
                      </Row>
                      <Row
                        className={`modal-btns ${deviceType === "mobile" ? "mobileBtn" : ""
                          }`}
                        style={{ marginTop: "1.5rem" }}
                      >
                        <Col
                          xs={24}
                          sm={24}
                          md={deviceType !== "mobile" ? 12 : 24}
                          lg={12}
                          xl={12}
                        >
                          <Button
                            className="t5"
                            type="dark"
                            size="large"
                            style={{
                              width: "100%",
                              height: "2.5rem",
                              color: Colors?.white,
                              border: "none",
                              background: Colors?.black,
                            }}
                            onClick={() => {
                              handleSendQuery();
                            }}
                          >
                            <span>
                              <img
                                src={quote}
                                alt="quoted"
                                size={20}
                                style={{
                                  display: "inline",
                                  padding: "0 0.5rem 0.25rem 0",
                                }}
                              />
                              Get quote for all
                            </span>
                          </Button>
                        </Col>
                        {deviceType !== "mobile" && (
                          <Col lg={12} xl={12}>
                            <Row className="get-quote-all" justify="end">
                              <img
                                src={wishlists2}
                                alt="wishlists"
                                width={150}
                                height={150}
                              />
                            </Row>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Affix>
              )}
            </Col>
          </Row>

          {second && (
            <Modal visible={second} footer={null} closable={false}>
              <Row style={{ padding: "1rem" }}>
                <Col span={24}>
                  <Row justify="center">
                    <Lottie
                      options={defaultOptionsQuote}
                      height={"50%"}
                      width={"50%"}
                    />
                  </Row>
                  <Row
                    className="t7"
                    style={{ paddingBottom: "1.5rem" }}
                    align="center"
                  >
                    Quote request sent
                  </Row>

                  <Row
                    className="t6"
                    align="center"
                    style={{ color: Colors?.midGray }}
                  >
                    We have also sent the details to your mail at
                  </Row>
                  <Row
                    className="t6"
                    align="center"
                    style={{ color: Colors?.midGray }}
                  >
                    {getItem("BuyerEmail")}
                  </Row>
                  <Row justify="center" style={{ marginTop: "2rem" }}>
                    <Button
                      className="t5"
                      type="dark"
                      size="large"
                      style={{
                        width: "30%",
                        height: "3rem",
                        color: Colors?.white,
                        border: "none",
                        background: Colors?.black,
                      }}
                      onClick={() => handleModalClose()}
                    >
                      Done
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Modal>
          )}

          {checkedProducts.length === 0 && (
            <Row justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <EmptyState searchParams={urlParams.toString()} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  checkedProducts: state.checkedProducts.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setProducts: ProductActions.setProducts,
      removeProduct: ProductActions.removeProduct,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendQuery);
