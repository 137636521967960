export const isOnlyNumbersRegex = /^[0-9\b]+$/;

export const IMAGE_QUALITY_KEY = "image_quality"
export const CATALOGUE_LINK_SETTINGS_KEY = "catalogue_link_settings"
export const DEFAULT_IMAGE_QUALITY = {
    "MAGNIFIED": {
        "h": "3600",
        "w": "5400",
        "q": "100"
    },
    "COROUSEL": {
        "h": "3600",
        "w": "5400",
        "q": "100"
    }
}

export const DEFAULT_WATERMARK_SETTINGS = {
    "isCatalogueWatermark": true,
    "isOtpModalWatermark": true,
    "isEmailModalWatermark": true,
    "isOtpEmail":true,
    "isQuoteEmail":true
}

export const PRODUCT_CARD_SETTINGS_KEY = "product_card";
export const CATALOGUE_SETTINGS_KEY = "catalogue_settings";