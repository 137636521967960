import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import { Typography } from "antd";
import Logo from "./Logo.svg"
import { Link } from "react-router-dom";
import { useGlobal } from "../../../store/index";
import {getItem} from "utils/localStorage"

const { Title } = Typography;
const Nav = styled.nav`
  width: 100%;
  height: 65px;
  border-bottom: 2px solid #f1f1f1;
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  .logo {
    padding: 15px 0px;
  }
`


const Navbar = ({ isPreview , isAccessGranted, setIsAccessGranted}) => {
  const [globalState, setGlobalState] = useGlobal();
  const local_collection_link =getItem("collection_link");
  const { collection_link } = globalState;
  return (
    <Nav style={{position:'fixed',height:64,zIndex:100}}>
      <div className="logo">
        <Title

          style={{
            // color: Colors.primaryColor,
            fontWeight: "normal",
            fontFamily: "Gilroy",
            fontSize: 25,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',


          }}
        >
          {/* <a href="/"></a> */}
          <Link
            to={`/buyer-catalogue/${collection_link || local_collection_link
              }`} style={{ color: "#000", paddingTop: "5px" }}>
            <img src={Logo} alt="sourcewiz" height={25} width={25} style={{ marginRight: '0.5rem', marginBottom: "5px" }} />Sourcewiz</Link>
        </Title>
      </div>
      {<Burger isPreview={isPreview} isAccessGranted={isAccessGranted} setIsAccessGranted={setIsAccessGranted}/> }
    </Nav>
  )
}

export default Navbar