import React from "react";

import { Typography, Row, Col, Card, Space } from "antd";

const { Title, Text } = Typography;

const getFallbackUrl = (product) =>
  `${process.env.REACT_APP_DIRECTUS}assets/${product?.products_id?.product_images?.[0]?.directus_files_id}?transforms=[["resize",{"width":283,"height":214,"fit":"contain","background":{"r":242,"g":242,"b":242}}]]`;

const getPrice = (attributes) => {
  let price = [];
  attributes.forEach((element) => {
    element = element?.attribute_values_id;
    if (element) {
      if (element?.attribute_id?.dtype === "price") {
        price.push(element?.values);
        price.push(element?.composite?.currency);
        price.push(element?.composite?.unit);
        //    return  price = price + element?.value + element?.composite?.currency + element?.composite?.unit
      }
    }
    return price;
  });
  return price;
};
export default function ProductCard({
  product,
  index,
  handleCardClick,
  handleShortlist,
  isShortlisted,
}) {
  var price = getPrice(product.attributes);

  return (
    <Col
      key={index}
      // xs={24} sm={24} md={12} lg={8} xl={8}
    >
      <Card
        className="product-card-send-query"
        cover={
          <img
            // ref={imageRef}
            src={getFallbackUrl(product)}
            // onLoad={handleOnLoad}
            // onError={handleOnError}
            onClick={() => {
              handleCardClick(product);
            }}
            height={160}
            alt="product-img"
          />
        }
        bodyStyle={{ padding: 10 }}
      >
        <Row style={{ minHeight: 56 }}>
          <Space direction="vertical">
            <Title
              className="product-id"
              style={{ fontWeight: "bolder", fontSize: 16, margin: 0 }}
              level={5}
            >
              {product?.products_id ? `ID: ${product?.products_id}` : ""}
            </Title>

            {price?.[0] && price?.length > 2 ? (
              <Row>
                <Space direction="horizontal" size="small">
                  <Title
                    level={5}
                    className="product-price"
                    style={{ fontFamily: "Gilroy", margin: 0 }}
                  >
                    " " {price?.[0] + " " + price?.[1] + " "}
                  </Title>
                  <Text type="secondary">{" " + price.slice(2).join(" ")}</Text>
                </Space>
              </Row>
            ) : null}
          </Space>
        </Row>
      </Card>
    </Col>
  );
}

// <div style={!loading ? {} : { display: 'none' }} onClick={handleCardClick} disabled={loading}>
//           <img
//               ref={imageRef}
//               src={imgSrc}
//               onLoad={handleOnLoad}
//               onError={handleOnError}
//               width={'100%'}
//               height={'100%'}
//           />
//       </div>
//       <div style={loading ? {} : { display: 'none' }}>
//           <Shimmer style={{ height: 222, borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }} />
//       </div>
