import React from 'react';
import { Row, Col, Button, Typography } from 'antd';
import emptyWishlist from '../../../assets/images/emptyWishlist.svg';
import { Colors } from '../../../theme';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobal } from '../../../store/index';
const { Title, Text } = Typography;

export default function EmptyState({ searchParams }) {
  const [globalState, setGlobalState] = useGlobal();
  const deviceType = globalState.deviceType;
  const params = useParams();
  const history = useHistory();

  return (
    <Row justify='center' style={{ marginTop: '2rem' }}>
      <Col>
        <Row justify='center'>
          <img src={emptyWishlist} alt='success' size={250} />
        </Row>
        <Row justify='center'>
          <Col>
            <Row justify='center' style={{ marginTop: '3rem' }}>
              <Title
                level={2}
                style={{
                  fontSize: `${deviceType === "mobile" ? '20px' :'24px'}`,
                  textAlign: 'center',
                }}
              >
                You have no products shortlisted
              </Title>
            </Row>
            <Row justify='center'>
              <Text
                style={{
                  fontSize: `${deviceType === "mobile" ? '12px' :'16px'}`,
                  textAlign: 'center',
                }}
              >
                Explore catalogue to shortlist products and send quotes
              </Text>
            </Row>
            <Row justify='center' style={{ marginTop: '2rem' }}>
              <Col>
                <Button
                  className='cta'
                  size='large'
                  onClick={() =>
                    history.push({
                      pathname: params?.expname
                        ? `/${params?.expname}/${params?.id}`
                        : `/${params?.id}`,
                      state: searchParams,
                    })
                  }
                  style={{
                    color: Colors?.white,
                    backgroundColor: Colors?.black,
                  }}
                >
                  Explore all products
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
