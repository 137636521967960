import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import algoliasearch from "algoliasearch/lite";
import ContactSlide from "./ContactSlide";
import ProductSlide from "./ProductSlide";
import EmptyState from "./EmptyState";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import ProductActions from "redux/actions/productActions";
import { message } from "antd";
import { useGlobal } from "../../store/index";
import QUOTATION_KEY from "api/quotation";
import { IMAGE_QUALITY_KEY } from "constants/index";
function SliderView({ checkedProducts, actions }) {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const params = useParams();
  const collectionLink = params?.id;
  let collectionLocalStorageKey = collectionLink + "-collection_details";
  const [globalState, setGlobalState] = useGlobal();
  const [nbHits, setNbHits] = useState(0);

  let collectionDetails = JSON.parse(
    localStorage.getItem(collectionLocalStorageKey)
  );

  const defaultFilter = `collections_id.collection_link:${
    window?.name || collectionLink
  }`;

  //elastic
  const [hits, setHits] = useState([]);
  const [page, setPage] = useState(1);
  const [facets, setFacets] = useState({});
  const [indexOnPage, setIndexOnPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [productInd, setProductInd] = useState(globalState.productIndex);
  const [imageQuality, setImageQuality] = useState({})
  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        // alert( "This page is reloaded" );
      }
    }
  }, [performance.navigation.type]);

  // getPage
  const getPageByIndex = () => {
    let _index = globalState.productIndex;
    setPage(
      globalState.pageSize > 0
        ? Math.floor(_index / globalState.pageSize) + 1
        : 1
    );
    setIndexOnPage(_index % globalState.pageSize);

    return globalState.pageSize > 0
      ? Math.floor(_index / globalState.pageSize) + 1
      : 1;
  };

  useEffect(() => {
    setLoading(true);
    let _page = getPageByIndex();
    axios
      .post(
        `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
        {
          page: _page,
          ...globalState.filters,
          records_per_page: globalState.pageSize,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setHits(res.data?.data?.hits?.length ? res.data.data.hits : []);
          setFacets(
            res.data?.data?.facets && Object.keys(res.data.data.facets).length
              ? formatFacets(res.data.data.facets)
              : {}
          );
          setAllProducts(res.data.data.hits);
          setNbHits(res.data.data.nbHits);
          setPages(res.data.data.nbPages);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error while fetching algoliaHits", err);
        setLoading(false);
        message.error("Something went wrong, Please try again");
      });
  }, [defaultFilter]);

  useEffect(()=>{
    // collect the image quality parameter of according the manufacturer
    QUOTATION_KEY.URL.get(`${QUOTATION_KEY.path.manufacturerFeatures}/${params?.id}/${IMAGE_QUALITY_KEY}`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.data?.value) {
          setImageQuality(res?.data?.data?.value)
        }
      })
      .catch((err) => {
        console.error("error while fetching manufacturer features", err);
        message.error("Something went wrong");
      });
  },[params?.id])

  const addLabelValueBucket = (buckets) => {
    return buckets.map((bucket) => {
      return {
        ...bucket,
        value: bucket.key,
        label: `${bucket.key} (${bucket.doc_count})`, 
      };
    });
  };

  const formatFacets = (_facets, _filters) => {
    let _facetsFiltered = {};
    Object.keys(_facets).forEach((_facet) => {
      if (_facets[_facet].buckets.length) {
        _facetsFiltered[_facet] = _facets[_facet];
        _facetsFiltered[_facet].buckets = addLabelValueBucket(
          _facetsFiltered[_facet].buckets
        );
      }
    });
    if (_filters) {
      Object.keys(_filters).forEach((_key) => {
        if (facets[_key] && _facetsFiltered[_key].buckets.length > 0) {
          _filters[_key].forEach((_bucket) => {
            let index = _facets[_key].buckets.findIndex(
              (_b) => _b.key === _bucket
            );
            if (index >= 0) {
              _facetsFiltered[_key].buckets[index].checked = true;
            }
          });
        }
      });
    }
    return _facetsFiltered;
  };

  const handleRight = () => {
    // setImgLoaded(false);
    if (indexOnPage < allProducts?.length - 1) {
      // setProductIndex((_index) => _index + 1);
      setProductInd((_index) => _index + 1);
      // setIndexRel((_index) => _index + 1);
      setIndexOnPage((_index) => _index + 1);
    } else {
      updateAllProducts("right", productInd, indexOnPage);
    }
  };

  const handleLeft = () => {
    // setImgLoaded(false);
    if (indexOnPage !== 0) {
      // setProductIndex((_index) => _index + 1);
      setProductInd((_index) => _index - 1);
      // setIndexRel((_index) => _index + 1);
      setIndexOnPage((_index) => _index - 1);
    } else {
      updateAllProducts("left", productInd, indexOnPage);
    }
  };

  const updateAllProducts = (direction, _prodIndex, _index) => {
    let _page = page;
    if (pages > 1) {
      setLoading(true);
      if (direction === "right") {
        _page = page === pages ? 1 : page + 1;
        _prodIndex = _prodIndex === nbHits - 1 ? 0 : _prodIndex + 1;
        _index = 0;
      } else {
        _page = page === 1 ? pages : page - 1;
        _prodIndex = _prodIndex === 0 ? nbHits - 1 : _prodIndex - 1;
        _index =
          page === 1
            ? (nbHits % (globalState.pageSize * (_page - 1))) - 1
            : globalState.pageSize - 1;
      }

      axios
        .post(
          `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
          {
            page: _page,
            ...globalState.filters,
            records_per_page: globalState.pageSize,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setHits(res.data?.data?.hits?.length ? res.data.data.hits : []);
            setFacets(
              res.data?.data?.facets && Object.keys(res.data.data.facets).length
                ? formatFacets(res.data.data.facets)
                : {}
            );
            setAllProducts(res.data.data.hits);
            setPage(_page);
            setIndexOnPage(_index);
            setProductInd(_prodIndex);
          }

          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch((err) => {
          console.log("error while fetching algoliaHits", err);
          setLoading(false);
          message.error("Something went wrong, Please try again");
        });
    } else {
      if (direction === "right") {
        // _page = page === pages ? 1 : page+1;
        _prodIndex = 0;
        _index = 0;
      } else {
        // _page = page === 1 ? pages : page - 1;
        _prodIndex = nbHits - 1;
        _index = nbHits - 1;
      }
      setIndexOnPage(_index);
      setProductInd(_prodIndex);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const updateIndex = (_ind, _relInd) => {
    setIndexOnPage(_relInd);
    setProductInd(_ind);
    // setPage(1);
    if (page !== 1) {
      setLoading(true);
      setPage(1);
      axios
        .post(
          `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
          {
            page: 1,
            ...globalState.filters,
            records_per_page: globalState.pageSize,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setHits(res.data?.data?.hits?.length ? res.data.data.hits : []);
            setFacets(
              res.data?.data?.facets && Object.keys(res.data.data.facets).length
                ? formatFacets(res.data.data.facets)
                : {}
            );
            setAllProducts(res.data.data.hits);
            // setPage(_page);
            // setIndexOnPage(_index);
            // setProductInd(_prodIndex);  
          }

          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch((err) => {
          console.log("error while fetching algoliaHits", err);
          setLoading(false);
          message.error("Something went wrong, Please try again");
        });
    }
  };

  const renderSlider = () =>
    allProducts?.length > 0 ? (
      <ProductSlide
        {...{
          collectionLink,
          allProducts,
          actions,
          checkedProducts,
          productInd,
          indexOnPage,
          nbHits,
          updateAllProducts,
          handleRightClick: handleRight,
          handleLeftClick: handleLeft,
          updateIndex,
          imageQuality
          
        }}
      />
    ) : (
      <ContactSlide
        allProducts={allProducts}
        collectionLink={collectionLink}
        collectionDetails={collectionDetails}
      />
    );

  return (
    <div className="z-50 bg-white fixed inset-x-0 inset-y-0 w-screen h-screen overflow-y-scroll overflow-x-hidden">
      {loading ? <EmptyState /> : error ? <div>{error}</div> : renderSlider()}
    </div>
  );
}
const mapStateToProps = (state) => ({
  checkedProducts: state.checkedProducts.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addProduct: ProductActions.addProduct,
      removeProduct: ProductActions.removeProduct,
      setProducts: ProductActions.setProducts,
      getProducts: ProductActions.getProducts,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(SliderView);
