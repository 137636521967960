import React from "react";
import { Row, Col, Button, Typography } from "antd";
import successPng from "../../../assets/images/sentempty.svg";
import { Colors } from "../../../theme";
import { useHistory, useParams } from "react-router-dom";
const { Title, Text } = Typography;

export default function EmptyState() {
  const params = useParams();
  const history = useHistory();
  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col>
      <Row justify="center">
        <img src={successPng} alt="success" width="403px" height="269px" />
        </Row>
        <Row justify="center" style={{ marginTop: "40px" }}>
          <Col >
            <Row justify="center" style={{ marginTop: "48px" }}>
              <Title level={2} style={{ fontFamily: "Avenir", fontSize: "24px",textAlign:'center'}}>
              Start shortlisting products first to send a query
              </Title>
              </Row>
              <Row justify="center">
              <Text type="secondary" style={{ fontFamily: "Avenir" , fontSize: "14px",textAlign:'center'}}>
              Click shortlist button on the products to start shortlisting
              </Text>
            </Row>
            <Row justify="center" style={{ marginTop: "20px"}}>
           <Col span={12}>
           <Button
              size="large"
              className="cta"
              // style={{
              //   color: Colors.white,
              //   backgroundColor: Colors.black,
              // }}
              onClick={() =>
                history.push(`/buyer-catalogue/${params?.id}`)
              }
            >
              Browse Catalogue
            </Button>
            </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
