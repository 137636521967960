import React, { useEffect, useState } from "react";
import { Button, Row, Col, Typography, notification, Space } from "antd";
import ProductCard from "./ProductCard";
import { useParams, useHistory } from "react-router-dom";
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";

// import { useGlobal } from "../../../store/index";
import pptxgen from "pptxgenjs";
import API_KEY from "../../../api/index";
import { useGlobal } from "../../../store/index";
import EmptyState from "./ShortlistedEmptyState";
import sourcewizLogo64 from "../../../assets/images/logo64.png";
import background from "../../../assets/images/ppt_background.png";
import { Colors } from "../../../theme";
import fileDownload from "js-file-download";
import Axios from "axios";
import { getShortedListedProductsFrom } from "utils";
import Loading from 'components/LoadingModal'
import StickyBox from "react-sticky-box";
import Tour from 'reactour';
import SendQuery from '../../../assets/images/send_query.png';
import {getItem,removeItem,setItem} from "utils/localStorage"

import { AiOutlineMail } from 'react-icons/ai'
import { FaCloudDownloadAlt } from 'react-icons/fa'

const { Text, Title } = Typography;
const pptx = new pptxgen();
pptx.layout = "LAYOUT_WIDE";

export default function Shortlisted() {
  const [shortlisted, setShortlisted] = useState([]);
  const [isShortlisted, setIsShortlisted] = useState(false);
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const type = getItem("type");
  const isPreview = type === "PREVIEW" ? true : false;

  // const [globalState, setGlobalState] = useGlobal();
  const [globalState, setGlobalState] = useGlobal();
  const [collectionDetails, setCollectionDetails] = useState(null);
  const [DownloadButtonLoading, setDownloadButtonLoading] = useState(false);

  const [TourOpen, setTourOpen] = useState(false);
  const [ShowingMore, setShowingMore] = useState(false);

  //Start the tour when the page renders
  useEffect(() => {

    if (getItem("tour") === "true") {
   
        shortlisted.length > 0 && setTourOpen(false)
       
    
      
    } else {
      if (shortlisted.length > 0) {
        setTourOpen(true)
       }
      
    
    }
    
   
  }, [shortlisted])

  const pptDownload = () => {
    var slides = [];
    var k = -1;
    var titleslide = pptx.addSlide();
    var title_footer = [];
    titleslide.addImage({
      path: collectionDetails?.banner_image
        ? `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.banner_image}.jpg?quality=100`
        : background,
      x: 0,
      y: 0,
      w: "100%",
      h: "46.5%",
    });
    titleslide.addImage({
      path: collectionDetails?.logo
        ? `${process.env.REACT_APP_DIRECTUS}/${collectionDetails?.logo}.jpg?transforms=[["resize",{"width":200,"height":200,"fit":"contain","background":{"r":242,"g":242,"b":242}}]]`
        : ImagePlaceholder,
      x: 0.6,
      y: 3.7,
      w: "8.35%",
      h: "14.7%",
    });
    titleslide.addText(collectionDetails?.exporter_name, {
      x: 1.79,
      y: 3.87,
      w: "40%",
      h: "10%",
      fontSize: 36,
    });
    titleslide.addText(collectionDetails?.collection_name, {
      x: 0.48,
      y: 4.76,
      w: "100%",
      h: "10%",
      fontSize: 35,
      color: "#A6ACAF",
    });

    title_footer.push({
      text: "We have partnered with Sourcewiz to enhance your viewing experience and send our queries easily. ",
    });
    title_footer.push({
      text: "Click here to visit our online catalogue",
      options: {
        fontSize: 15,
        hyperlink: {
          url: `https://catalogue.sourcewiz.co/${collectionDetails?.collection_link}`,
        },
        breakline: true,
      },
    });
    titleslide.addText(title_footer, {
      x: 0,
      y: 7.0,
      align: "center",
      valign: "top",
      w: "100%",
      h: "6%",
      fontSize: 14,
      color: "#A6ACAF",
    });

    titleslide.addImage({
      path: `${process.env.REACT_APP_DIRECTUS}assets/b007aae7-fa5e-431b-8d74-ebac68dba2a3.jpg?transforms=[["resize",{"width":100,"height":100,"fit":"contain","background":"white"}]]`,
      x: 0.65,
      y: 7.0,
      w: 0.3,
      h: 0.3,
    });

    var products_list = [];
    shortlisted.forEach((product) => {
      product.items.forEach((item) => {
        products_list.push(item["id"]);
      });
    });

    Axios.get(
      `${process.env.REACT_APP_API_SERVICE}pptservice/?collection_link=${collectionDetails?.collection_link}&short_listed=${products_list}`,
      // {
      //   responseType: "blob",
      // }
    )
      .then((res) => {
        console.log(res.data['ppt_url'])
        let url = res.data['ppt_url']
        window.open(url, '_self')
        // fileDownload(
        //   res.data,
        //   `${collectionDetails?.collection_name}__shortlisted.pptx`
        // );
        setDownloadButtonLoading((state) => false);
      })
      .catch((err) => {
        setDownloadButtonLoading((state) => false);
      });

    // const filename = collectionDetails?.exporter_name
    //   ? `${collectionDetails?.exporter_name}_${collectionDetails?.collection_name}`
    //   : collectionDetails?.collection_name;
    // pptx
    //   .writeFile({ fileName: `${filename}.pptx` })
    //   .then((result) => {
    //     setDownloadButtonLoading((state) => false);
    //   })
    //   .catch((error) => {
    //     setDownloadButtonLoading((state) => false);
    //   });
  };

  const { shortlistedProducts } = globalState;

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    API_KEY.URL.get(`${process.env.REACT_APP_DIRECTUS}items/collections`, {
      params: {
        "filter[collection_link][_eq]": params?.id,
        fields: "*",
      },
    }).then((res) => {
      setCollectionDetails(res?.data?.data?.length ? res?.data?.data[0] : "");
      setItem(
        "collection_details",
        res?.data?.data?.length ? JSON.stringify(res?.data?.data[0]) : ""
      );
    });
  }, [params]);

  useEffect(() => {
    const products = getShortedListedProductsFrom();
    mapProducts(products);
  }, []);

  const mapProducts = (products) => {
    var dictionary = [];
    products &&
      products.forEach((element) => {
        let name = element.secondary_category?.name;
        let id = element.secondary_category?.id;
        let product_obj = element;

        dictionary.push({
          name: name,
          id: id,
          product_obj,
        });
      });

    const result = Object.values(
      dictionary.reduce((acc, { name, id, product_obj }) => {
        acc[name] = acc[name] || {
          name,
          items: [],
          id,
        };
        acc[name].items.push(product_obj);
        return acc;
      }, {})
    );
    setIsShortlisted(isShortlistedFunc(result));
    setShortlisted(result);
  };

  const isShortlistedFunc = (data) => {
    return data?.some((d) => {
      return d?.items.length > 0;
    });
  };

  const handleDelete = (item) => {
    // work in progress
    // delete from shortlisted
    // delete from item
    // console.log(item);
    // console.log(shortlisted);

    // const newState = shortlisted.forEach((element) => {
    //   element.items = element.items.filter((pro) => pro.id !== item.id);
    //   return element;
    // });
    // console.log(newState);
    const newState = [];

    shortlisted.forEach((element) => {
      element.items = element.items.filter((pro) => pro.id !== item.id);

      newState.push(element);
    });

    let storageShortlist = [];
    newState.forEach((element) => {
      element.items.forEach((item) => storageShortlist.push(item));
    });

    setItem(
      "shortlisted_products",
      JSON.stringify(storageShortlist)
    );
    if (!storageShortlist.length) {
      removeItem("shortlisted_products");
    }

    setGlobalState.settings.setShortlistedProducts(storageShortlist);
    // setItem(
    //   "shortlisted_products",
    //   JSON.stringify(shortlistedProductRef.current)
    // );

    setIsShortlisted(isShortlistedFunc(newState));

    setShortlisted(newState.length ? newState : []);
  };
  const openNotification = () => {
    notification.open({
      message:
        " You cannot perform this action because you are browsing in preview mode",
      // description:
      //  ,
    });
  };

  return (
    <>
      <Loading visible={DownloadButtonLoading} fileType="Catalogue" />
      {isShortlisted && (
        <StickyBox offsetTop={64} offsetBottom={20} style={{ background: Colors.white, zIndex: 10, paddingBottom: 10, width: '100%' }}>

          <Row align="middle">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row>
                <Title level={3} style={{ fontFamily: "Gilroy", margin: 0 }}>
                  Shortlisted Products
                </Title>
              </Row>
              {collectionDetails?.exporter_name && (
                <Row style={{ marginTop: 12 }}>
                  <Text type="secondary">
                    {` Send a query to ${collectionDetails?.exporter_name} about these products or
              download them in PPT format.`}
                  </Text>
                </Row>
              )}
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 4, offset: 6 }}
              lg={{ span: 4, offset: 6 }}
              xl={{ span: 4, offset: 6 }}
            >
              <Row
                className="shorlist-page-actions"
                justify="space-between"
                style={{ marginTop: 28 }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Space>
                    <Button
                      size="large"
                      type="primary"
                      style={{
                        background: Colors.primaryColorTint,
                        color: Colors.primaryColor,
                        width: '12em',
                        height: 48,
                      }}
                      onClick={() => {
                        setDownloadButtonLoading((state) => true);
                        pptDownload();
                      }}
                    // loading={DownloadButtonLoading}
                    >
                      <FaCloudDownloadAlt
                        size={20}
                        style={{ transform: 'translateY(0.2em)', margin: '0 0.4em' }}
                        className="icons"
                      />
                      {
                        // DownloadButtonLoading
                        //   ? `Downloading...`
                        //   :

                        `Download Shortlist`
                      }
                    </Button>

                    {/* <span data-tour="step-1"> */}
                      <Button
                        onClick={() =>
                          isPreview
                            ? openNotification()
                            : history.push({
                              pathname: `/send-query/${params?.id}/${collectionDetails?.exporter_name}`,
                              state: urlParams.toString(),
                            })
                        }
                        size="large"
                        className="cta"
                        style={{ width: '10em' }}
                      >
                        <AiOutlineMail
                          size={20}
                          style={{ transform: 'translateY(0.3em)', margin: '0 0.4em' }}
                          className="icons"
                        />
                        Send query
                      </Button>
                    {/* </span> */}
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </StickyBox>
      )}

      {isShortlisted > 0 &&
        shortlisted.map((product, index) => (
          <Row key={index} style={{ marginTop: "2rem" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <Title
                  level={4}
                >{`${product?.name} (${product?.items?.length})`}</Title>
              </Row>
              <Row>
                {product?.items.map((element, index) => (
                  <ProductCard
                    handleDelete={handleDelete}
                    product={element}
                    key={index}
                  />
                ))}
              </Row>
            </Col>
          </Row>
        ))}

      {!isShortlisted && (
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <EmptyState searchParams={urlParams.toString()} />
          </Col>
        </Row>
      )}

      <Tour
        onRequestClose={() => { setTourOpen(false) }}
        steps={tourConfig}
        isOpen={TourOpen}
        rounded={5}
        closeButtonAriaLabel="Close"
        closeWithMask={true}
        disableDotsNavigation={false}
        lastStepNextButton={
          <Button
            className="tourButton"
            size="medium"
            // onClick={() =>
            //   isPreview
            //     ? openNotification()
            //     : history.push({
            //         pathname: `/send-query/${params?.id}/${collectionDetails?.exporter_name}`,
            //         state: urlParams.toString(),
            //       })
            // }
            onClick={() => {
              setTourOpen(false);
              setItem("tour", true);
            }}
          >
            Okay, got it
          </Button>
        }
        maskSpace={18}
        showCloseButton={false}
        showNavigation={false}
        showNumber={false}
        className="tourCard tourCardQuery"
        maskClassName="maskQuery"
      />

    </>
  );
}

//Each Object in the Array is a Step in the Tour
const tourConfig = [
  {
    selector: '[data-tour="step-1"]',
    content: () => (
      <div>
        <img
          src={SendQuery}
          width="100%"
          style={{ borderRadius: "0.5em" }}
          alt="Send Query"
        />
        <Text  style={{fontWeight:'bold',fontSize:16,fontFamily:'Gilroy',margin:"5px 0px"}}><b>Share your commments by sending a query from here</b></Text><br />
        <Text>Once you have selected the products you can enter your comments for each product</Text>
      </div>
    ),
    style: {
      backgroundColor: Colors.white,
      color: Colors.black,
      padding: '1em',
      width: '20em',
    },
    position: 'bottom',
  }
];
