import { Shimmer } from 'components/Shimmer';
import React, { useState, useEffect } from 'react';
import ImageRouter from './ImageRouter';

export default function ImageWithMagnification(props) {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [magnifiedSrc, setMagnifiedSrc] = useState(props.fallbackUrl);
  const magnifierHeight = 400;
  const magnifieWidth = 400;
  const zoomLevel = 3;
  useEffect(() => {
    setMagnifiedSrc(props.fallbackUrl);
  }, [props.magnifiedSrc]);
  return (
    <div
      onMouseOver={(e) => {
        // update image size and turn-on magnifier
        const elem = e.currentTarget;
        const { width, height } = elem.getBoundingClientRect();
        setSize([width, height]);
        setShowMagnifier(true);
      }}
      onMouseMove={(e) => {
        // update cursor position
        const elem = e.currentTarget;
        const { top, left } = elem.getBoundingClientRect();

        // calculate cursor position on the image
        const x = e.pageX - left - window.pageXOffset;
        const y = e.pageY - top - window.pageYOffset;
        setXY([x, y]);
      }}
      onMouseLeave={() => {
        // close magnifier
        setShowMagnifier(false);
      }}
    >
      {!props.imgLoaded ? <Shimmer style={{ height: `${props.environment === 'buyer' ? "70vh" : '53vh'}` }} /> : null}
      <ImageRouter
        src={props.src}
        fallback={props.fallbackUrl}
        alt={'something'}
        preview={false}
        onLoad={() => props.setImgLoaded(true)}
        style={{ display: props.imgLoaded ? "block" : "none" }}
        timeout={2500}
      />
      <ImageRouter
        src={props.magnifiedSrc}
        fallback={props.magnifiedSrc}
        alt={'something'}
        preview={false}
        imageLoaded={() => {
          console.log('magnified image is fixed', props.magnifiedSrc);
          setMagnifiedSrc(props.magnifiedSrc);
        }}
        style={{
          opacity: 0,
          height: 1,
          width: 1,
        }}
        timeout={200000}
      />
      <div
        style={{
          cursor: 'zoom-in',
          position: 'absolute',
          display: showMagnifier && props.imgLoaded ? '' : 'none',
          // prevent maginier blocks the mousemove event of img
          // pointerEvents: "none",
          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifieWidth / 2}px`,
          opacity: '1', // reduce opacity so you can verify position
          border: '1px solid lightgray',
          backgroundImage: `url('${magnifiedSrc}')`,
          backgroundRepeat: 'no-repeat',
          overflow: 'hidden',

          //calculate zoomed image size
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,

          //calculete position of zoomed image.
          backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
          backgroundColor: 'white', // fix for image when zoomed in
        }}
      />
    </div>
  );
}
