import React from "react";
import { Row, Col, Button, Typography } from "antd";
import successPng from "../../../assets/images/shortlistempty.svg";
import { Colors } from "../../../theme";
import { useHistory, useParams } from "react-router-dom";
const { Title, Text } = Typography;

export default function EmptyState({ searchParams }) {
  const params = useParams();
  const history = useHistory();

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col>
        <Row justify="center">
          <img src={successPng} alt="success" width="386px" height="250px" />
        </Row>
        <Row justify="center" style={{ marginTop: "40px" }}>
          <Col>
            <Row justify="center" style={{ marginTop: "48px" }}>
              <Title
                level={2}
                style={{
                  fontFamily: "Avenir",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                You haven’t shortlisted any products yet!
              </Title>
            </Row>
            <Row justify="center">
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Click on the save icon to start shortlisting
              </Text>
            </Row>
            <Row justify="center" style={{ marginTop: "28px" }}>
              <Col>
                <Button
                  className="cta"
                  size="large"
                  onClick={() =>
                    history.push({
                      pathname: params?.expname
                        ? `/${params?.expname}/${params?.id}`
                        : `/${params?.id}`,
                      state: searchParams,
                    })
                  }
                >
                  Start Shortlisting
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
