import React from "react";

import {
    Row,
    Col,
    Modal,
    Typography,
    Button,
} from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import shortlistWYL from "../../../assets/images/shortlist_what_you_like.svg";
import browseWYL from "../../../assets/images/browse_what_you_like.svg";
import sendyourqueries from "../../../assets/images/send_your_queries.svg";




const { Title, Text } = Typography;

export default function EducationModal({
    showModal,
    handleModal,
}) {
    return (
        <Modal
            visible={showModal}
            destroyOnClose={true}
            footer={null}
            onCancel={handleModal}
            width={"80%"}
            centered
            closable={false}
            bodyStyle={{ borderRadius: 80 }}
        >
            <Row justify="center">
                <Col

                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 22, offset: 2 }}
                    xl={{ span: 22, offset: 2 }}
                    lg={{ span: 22, offset: 2 }}

                >
                    <Row justify="center">
                        <Title level={3} style={{ fontFamily: "Gilroy", marginBottom: "0.5rem" }}>
                            Say hello to our all new digital catalogue!
                        </Title>
                    </Row>
                    <Row justify="center">
                        <Text type="secondary">
                            We have partnered up with Sourcewiz to enhance your browsing experience and help you send your queries easily
                        </Text>
                    </Row>

                    <Row style={{ marginTop: "2.5rem" }}>
                        <Col
                            xs={{ span: 20, offset: 2 }}
                            sm={{ span: 20, offset: 2}}
                            md={{ span:8,offset:0 }}
                            xl={{ span:8,offset:0}}
                            lg={{ span:8,offset:0 }}
                        >
                            <Row justify="center">
                                <img
                                    src={browseWYL}
                                    alt="browse-what-you-like"
                                    height={"75%"}
                                    width={"75%"}
                                />
                            </Row>
                            <Row justify="center" style={{ marginTop: "1rem" }}>
                                Browse Products
                            </Row>
                        </Col>
                        <Col 
                         xs={{ span: 20, offset: 2 }}
                         sm={{ span: 20, offset: 2}}
                         md={{ span:8,offset:0 }}
                         xl={{ span:8,offset:0}}
                         lg={{ span:8,offset:0 }}
                        >
                            <Row justify="center">
                                <img
                                    src={shortlistWYL}
                                    alt="shortlist-what-you-like"
                                    height={"75%"}
                                    width={"75%"}
                                />
                            </Row>
                            <Row justify="center" style={{ marginTop: "1rem" }}>
                                Shortlist what you like
                            </Row>
                        </Col>
                        <Col 
                         xs={{ span: 20, offset: 2 }}
                         sm={{ span: 20, offset: 2}}
                         md={{ span:8,offset:0 }}
                         xl={{ span:8,offset:0}}
                         lg={{ span:8,offset:0 }}
                        >
                            <Row justify="center">
                                <img
                                    src={sendyourqueries}
                                    alt="send-your-queries"
                                    height={"75%"}
                                    width={"75%"}
                                />
                            </Row>
                            <Row justify="center" style={{ marginTop: "1rem" }}>
                                Send your queries
                            </Row>
                        </Col>
                    </Row>
                    <Row justify="center" style={{ marginTop: "2.5rem" }}>
                        <Button
                            size="large"
                            type="primary"
                            onClick={handleModal}
                        >
                            Start Browsing
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Modal >
    );
}
