import React, { useEffect, useState } from "react";

import { Row, Col, Typography } from "antd";
import { useGlobal } from "../../../store/index";
import QueryCard from "./QueryCard";
import SentQueryCard from "./SentQueryCard";
import EmptyState from "./SentEmptyState";
import {getItem} from "utils/localStorage"
const { Title } = Typography;

export default function SentQuery() {
  const [globalState, setGlobalState] = useGlobal();
  const [collectionDetails, setCollectionDetails] = useState(null);
  const [queryDetails, setQueryDetails] = useState(null);
  const [activeQuery, setActiveQuery] = useState(null);

  const { queriesData } = globalState;

  // get collection details from local storage
  useEffect(() => {
    const collectionData = getItem("collection_details");
    setCollectionDetails(collectionData ? JSON.parse(collectionData) : null);
  }, []);
  // api call to fetch Queries details
  useEffect(() => {
    if (collectionDetails) {
      setGlobalState.buyerActions.getQueries(collectionDetails?.id);
    }
  }, [setGlobalState, collectionDetails]);

  // get data from store
  useEffect(() => {
    if (queriesData) {
      const sentQueries = queriesData?.data?.filter((q) => q.status === "sent");

      setQueryDetails(sentQueries?.reverse());
    }
  }, [queriesData]);
  // set first query as active
  useEffect(() => {
    if (queryDetails) {
      setActiveQuery(queryDetails[0]);
    }
  }, [queryDetails]);
  //handle click on query card
  const handleActiveQuery = (id) => {
    setActiveQuery(queryDetails.filter((item) => item.id === id)[0]);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={3} style={{ fontFamily: "Gilroy" }}>
            Sent queries
          </Title>
        </Col>
      </Row>
      {queryDetails && queryDetails.length > 0 ? (
        <Row>
          <Col
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            style={{ maxHeight: "80vh", overflowY: "scroll" }}
          >
            {queryDetails &&
              queryDetails.map((query, index) => (
                <QueryCard
                  handleActiveQuery={handleActiveQuery}
                  key={index}
                  query={query}
                  isActive={activeQuery?.id === query?.id}
                />
              ))}
          </Col>
          <Col span={1}></Col>
          <Col
            xl={{ span: 15 }}
            lg={{ span: 15 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{ maxHeight: "80vh", overflowY: "scroll" }}
          >
            <SentQueryCard activeQuery={activeQuery} />
          </Col>
        </Row>
      ) : (
        <Row style={{ marginTop: "2rem" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <EmptyState />
          </Col>
        </Row>
      )}
    </>
  );
}
