import API_KEY from "../../api";

export const manufacturer = {
  getUserCategories: async (store, payload) => {
    store.setState({ loading: true });
    const response = await API_KEY.URL.get(API_KEY.path.manufacturer, {
      params: {
        "filter[user_id][_eq]": '$CURRENT_USER',
        // access_token:
        //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImQyZmMxNTAxLWYzOWEtNGIyNy1iNDJhLWM5YTY1OGIzZjIxOSIsImlhdCI6MTYyNjY2ODA1MywiZXhwIjoxNjI2OTI3MjUzfQ.HcSxLbwFE_mWkE2Ip5iAwBHk-RgrMNwjM273TYvIOOE",

        fields:
          "id,secondary_categories.secondary_category_id,secondary_categories.secondary_category_id.name,secondary_categories.secondary_category_id.id",
      },
    });

    if (response && response.data?.data?.length > 0) {
      const data = response.data?.data[0];

      const unique = (arr, encoder = JSON.stringify, decoder = JSON.parse) =>
        [...new Set(arr.map((item) => encoder(item)))].map((item) =>
          decoder(item)
        );

      const cats = data?.secondary_categories.map((item) => {
        return item.secondary_category_id;
      });

      store.setState({ userCategories: unique(cats), loading: false });
    }
  },
};
