import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    Space,
    Typography,
    Button,
    Skeleton
} from "antd";
import Loader from "react-loader-spinner";
import InfiniteScroll from 'react-infinite-scroll-component';
import MediaQuery from "react-responsive";

import HorizontalFilters from "../AlgoliaFilters/horizonalFilter";
import ProductCard from "./ProductCard";

import closeSvg from "../../../assets/images/close.svg";
import Logo from "../Layout/Logo.svg";
import { Colors } from "../../../theme";
import EmptyState from "./emptyState";
import { useGlobal } from "../../../store/index";
const { Title, Text } = Typography;

export default function CatalogueDetails({
    productCount,
    OnTextSearch,
    showFilter,
    Facets,
    selectFilter,
    Filters,
    showAllFilters,
    hits,
    loader,
    collection_link,
    // For card
    handleCardClick,
    onClickCard,
    checkedProducts,
    handleNext,
    handlePrev,
    imageQuality,
    mobileCols,
    refineNext,
    hasMore,
    updateQuickFilter,
    id,
    isLoadingMore,
    resetFilters,
    isCatalogueWatermark,
    extraAttributeToRender = '',
}) {
    const [flagClearAll, setFlagClearAll] = useState(false);
    const [globalState] = useGlobal();
    const deviceType = globalState.deviceType;
    // const [isLoadingMore, setIsLoadingMore] = useState(false);

    // useEffect(() => {
    //     setIsLoadingMore(false);
    // },[hits])

    useEffect(() => {
        const _keys = Filters !== null ? Object.keys(Filters) : [];
        if (_keys.length > 1 || (_keys.length && _keys[0] !== "search_keyword")) {
            setFlagClearAll(true);
        } else {
            setFlagClearAll(false);
        }
    }, [Filters]);
    // const getMoreRecords = () => {
    //     setIsLoadingMore(true);
    //     refineNext();
    // }

    const InfiniteHits = () => {
        return (
            <div
                className="z-[40] dataContainer"
            >
                {
                    loader ? (
                        <Row style={{ width: '100%', justifyContent: "center" }} >
                            <Skeleton />
                        </Row>
                    ) : (
                        <>
                            <InfiniteScroll
                                dataLength={hits.length} //This is important field to render the next data
                                next={refineNext}
                                hasMore={hasMore}
                            >
                                <Row className="infiniteScroll" style={{ margin: 0, marginRight: "-10px" }} >
                                    {hits.length > 0 ?
                                        (<>
                                            {hits.map((item, index, array) => {
                                                return (
                                                    <ProductCard
                                                        key={index}
                                                        product={item}
                                                        index={index}
                                                        allHits={array}
                                                        nextProduct={array[index + 1]}
                                                        prevProduct={array[index - 1]}
                                                        handleCardClick={handleCardClick}
                                                        handleShortlist={onClickCard}
                                                        isShortlisted={checkedProducts.some((p) => p.id === item.id)}
                                                        handleNext={handleNext}
                                                        handlePrev={handlePrev}
                                                        imageQuality={imageQuality}
                                                        mobileCols={mobileCols}
                                                        id={id}
                                                        extraAttributeToRender={extraAttributeToRender}
                                                    />
                                                );
                                            })}
                                        </>) : (
                                            <>
                                                <EmptyState resetFilter={resetFilters} />
                                            </>
                                        )
                                    }
                                </Row>
                            </InfiniteScroll>


                            {isLoadingMore && (
                                <Row style={{ width: '100%', justifyContent: "center" }} >
                                    {/* <Loader type="ThreeDots" color={Colors.primaryColor}/> */}
                                    <Skeleton />
                                </Row>
                            )}

                            <p
                                style={{
                                    textAlign: "center",
                                    margin: "4rem",
                                    fontSize: "16px",
                                }}
                            >
                                {isCatalogueWatermark &&
                                    <a href="https://www.sourcewiz.co/" target="_blank" rel="no noreferrer">
                                        <Title style={{ fontSize: "14px", textAlign: "center" }}>
                                            Powered by{" "}
                                            <img
                                                alt={Logo}
                                                src={Logo}
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    marginBottom: "6px",
                                                    display: "inline-block",
                                                    marginRight: "0.25rem",
                                                }}
                                            />
                                            <Text
                                                level={2}
                                                style={{ fontFamily: "Gilroy", fontSize: "18px" }}
                                            >
                                                Sourcewiz
                                            </Text>
                                        </Title>
                                    </a>
                                }
                            </p>
                        </>
                    )
                }
            </div>
        );
    };

    const close = (filter, index) => {
        let filterValue = [...Filters[filter]];
        filterValue.splice(index, 1);
        selectFilter(filterValue, filter);
    }

    return (
        <Row align="middle" id="catalogueDetails" justify="space-between">
            <Col md={24} xs={24} sm={24}>

                {/* <Text type="secondary" style={{ marginTop: -10 }}> */}
                {/* <Space style={{width:"100%"}} direction="vertical"> */}
                {showFilter && (
                    <HorizontalFilters
                        Facets={Facets}
                        OnTextSearch={OnTextSearch}
                        OnFilterChange={selectFilter}
                        Filters={Filters}
                        showAllFilters={showAllFilters}
                    />
                )}
                {/* <MediaQuery minWidth={600}> */}
                {deviceType !== "mobile" && (
                    <Row style={{ paddingLeft: "10px" }}>
                        {flagClearAll && (
                            <Col className="filterChips" >
                                <Text style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); selectFilter() }}>
                                    Clear All
                                </Text>
                            </Col>
                        )}
                        {Filters !== null && Object.keys(Filters).map((filter) => (
                            <>
                                {filter !== 'search_keyword' && (
                                    <>
                                        {Filters[filter].map((_filChip, index) => (
                                            <Col className="filterChips" key={`${_filChip}${index}`}>
                                                <Text >
                                                    {_filChip}
                                                </Text>
                                                <span><img className="closeIcon"
                                                    onClick={e => { e.preventDefault(); close(filter, index) }}
                                                    src={closeSvg}
                                                    alt="more-options"
                                                    height={"100%"}
                                                    width={"100%"}
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                /></span>
                                            </Col>
                                        ))}
                                    </>
                                )}
                            </>
                        ))}
                    </Row>
                )}
                {/* </MediaQuery> */}
                {/* <MediaQuery  maxWidth={600}> */}
                {deviceType === "mobile" && showFilter && (
                    <div className="quickFilters">
                        {
                            globalState.quickFilters.length > 0 && (
                                <>
                                    {
                                        globalState.quickFilters.map((filter, index) => (
                                            <Button
                                                className={`filterBtn ${filter.selected && filter.selected !== undefined ? "selectedFilter" : ""}`}
                                                onClick={() => updateQuickFilter(index)}
                                            >
                                                {filter.key}
                                            </Button>
                                        ))
                                    }
                                </>
                            )
                        }

                    </div>
                )}
                {/* </MediaQuery> */}
                <Row className="dataProducts">
                    {InfiniteHits()}
                </Row>
                {/* </Space> */}

            </Col>
        </Row>
    )
}