import { useRef } from "react";
import {
    Row,
    Col,
    Input,
    Checkbox,
    Popover,
    Button,
    Affix
} from "antd";
import { useEffect, useState } from "react";
import chevronDownSvg from "../../../assets/images/chevron-down.svg";
import filtersSvg from "../../../assets/images/filter.svg";
import filtersBlip from "../../../assets/images/filterBlip.svg";
import { useGlobal } from "../../../store/index";
import { useLocation } from "react-router-dom";

import "./styles.css"
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

const { Search } = Input;
const CheckboxGroup = Checkbox.Group;

export default function HorizontalFilters({
    Facets,
    Filters,
    OnTextSearch,
    OnFilterChange,
    showAllFilters
}) {
    const [globalState] = useGlobal();
    const deviceType = globalState.deviceType;
    const [searchText, setSearchText] = useState(globalState?.filters?.search_keyword ? globalState.filters.search_keyword : "")
    const location = useLocation();
    const filterResults = filter => {
        sendAnalyticsEvent("buyer_side_search_prods", {
            name: 'buyer_side_search_prods',
            search: filter,
            location: location.pathname,
        });
        OnTextSearch(filter.toUpperCase());
    }

    useEffect(() => {
        setSearchText(globalState?.filters?.search_keyword ? globalState.filters.search_keyword : "")
    },[globalState.filters,globalState.filters.search_keyword]);

    const resolveFacetHeader = facet => {
        switch(facet) {
          case "sub_category": return "Sub Category";
          case "secondary_category": return "Category";
          default: return facet;
        }
    }

    const updateFacets = (e, facet) => {
        sendAnalyticsEvent("buyer_side_filter_select_mp", {
            name:'buyer_side_filter_select_mp',
            type: facet,
            location: location.pathname,
        });
        console.log(e);
        OnFilterChange(e, facet);
    }

    return (
        <Affix offsetTop={deviceType === 'mobile' ? 40 : 55} className="stickyHorizontal">
        <div id="horizontalFilter" key={searchText} >
            {deviceType === "mobile" && (
                <Row >
                    <Col xs={22} md={22} >
                        <Search 
                            defaultValue={searchText} 
                            className={`${deviceType === 'mobile' ? "mobile" : ""} searchText`} 
                            placeholder="Search for products" 
                            allowClear  
                            onSearch={filterResults}
                        />
                    </Col>
                    <Col xs={2} style={{display: "flex", justifyContent: "center"}}>
                        <span style={{border: "1px solid #CDCFCE"}}>
                            <img className="filterSvg"
                                onClick={(e)=>{e.preventDefault();showAllFilters()}}
                                src={Object.keys(Filters).length > 0 ? filtersBlip : filtersSvg}
                                alt="more-options"
                                // height={"100%"}
                                width={"100%"}
                            />
                        </span>
                    </Col>
                </Row>
            )}                    
            {
                deviceType !== "mobile" && (
                    <Row>
                        <Col xs={6} md={6}>
                            <Search defaultValue={searchText} className="searchText" placeholder="Search for products" allowClear  onSearch={filterResults}/>
                        </Col>
                        <Col>
                            <Row >
                                {Object.keys(Facets).slice(0,3).map((facet,index)=>(
                                    <Popover className="popoverBucket"
                                        content={
                                            <CheckboxGroup 
                                                className="buckets" 
                                                options={Facets[facet].buckets} 
                                                onChange={e=>updateFacets(e, facet)}
                                                value={Filters[facet]}
                                            />
                                        }
                                        trigger="hover"
                                        placement="bottomLeft"
                                    >
                                    <Button>
                                        {resolveFacetHeader(facet)}
                                        <span><img
                                            src={chevronDownSvg}
                                            alt="more-options"
                                            height={"100%"}
                                            width={"100%"}
                                            style={{
                                                paddingLeft: "5px",
                                                cursor: "pointer"
                                            }}
                                        /></span>
                                    </Button>
                                </Popover>
                                ))}
                                {Object.keys(Facets).length > 0 && (
                                    <Button className="popoverBucket" onClick={(e)=>{e.preventDefault();showAllFilters()
                                        sendAnalyticsEvent("buyer_side_apply_filter_sp", {
                                            name: 'buyer_side_apply_filter_sp',
                                            modules: 'filters',
                                            location: location.pathname,
                                        });
                                    }}>
                                        All filters
                                        <span><img
                                            src={chevronDownSvg}
                                            alt="more-options"
                                            height={"100%"}
                                            width={"100%"}
                                            style={{
                                                paddingLeft: "5px",
                                                cursor: "pointer"
                                            }}
                                        /></span>
                                    </Button>
                                )}
                            </Row>
                        </Col>
                    </Row>
                )
            }
        </div>
        </Affix>
    );
}