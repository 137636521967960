import axios from "axios";
import { getItem } from "utils/localStorage"


const getPreviewStatus = () => {
  return getItem("type")
}
const baseURL = process.env.REACT_APP_API_SERVICE;
// const baseURL = 'https://quotation.internal.sourcerer.tech/'

const QUOTATION_KEY = {
  URL: axios.create({
    baseURL: baseURL,
    // params: {
    //   access_token: user?.access_token,
    // },
  }),
  path: {
    qrProductInfo: `${baseURL}qr/public/product_info`,
    qrCatalogues: `${baseURL}qr/public/catalogues`,
    mobileOtpVerify: `${baseURL}auth/verify/otp/no_auth`,
    emailOtpVerify: `${baseURL}auth/email/otp/verify`,
    mobileOtpGenerate: `${baseURL}auth/send/otp/no_auth`,
    emailOtpGenerate: `${baseURL}auth/email/otp/generate`,
    verifyLinkSecurity: `${baseURL}link_security/verify?collection_link=`,
    countryCodes: `${baseURL}auth/country_codes`,
    manufacturerFeatures: `${baseURL}manufacturer`,
    quoteEnquiry: `${baseURL}items/enquiries/quote_request`
  },
};


QUOTATION_KEY.URL.interceptors.request.use(function (request) {
  var type = getPreviewStatus()
  var ignore = ["custom", "enquiries"]
  if (type === "PREVIEW") {
    if (ignore.some(request.url.includes.bind(request.url))) {
      return axios.Cancel("You are in preview mode", type);
    }
    else {
      return request
    }
  }
  else {
    return request
  }

})
export default QUOTATION_KEY;


export const linkSecurityVerify = (collectionLink, data) => {
  return QUOTATION_KEY.URL.post(`${QUOTATION_KEY.path.verifyLinkSecurity + collectionLink}`, data);
};

export const verifyMobileOtp = (data) => {
  return QUOTATION_KEY.URL.post(QUOTATION_KEY.path.mobileOtpVerify, data);
};

export const verifyEmailOtp = (data) => {
  return QUOTATION_KEY.URL.post(QUOTATION_KEY.path.emailOtpVerify, data);
};

export const generateMobileOtp = (data) => {
  return QUOTATION_KEY.URL.post(QUOTATION_KEY.path.mobileOtpGenerate, data);
};

export const generateEmailOtp = (data) => {
  return QUOTATION_KEY.URL.post(QUOTATION_KEY.path.emailOtpGenerate, data);
};

export const getCountryCodes = () => {
  return QUOTATION_KEY.URL.get(QUOTATION_KEY.path.countryCodes);
};

export const getManufacturerSettings = (data) => {
  return QUOTATION_KEY.URL.get(`${QUOTATION_KEY.path.manufacturerFeatures}/${data.collection_link}/${data.key}`)
}