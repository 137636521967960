import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Typography,
  Input,
  Button,
  message,
  Modal,
  Image,
  Alert,
} from "antd";
import API_KEY from "../../../api";
import QUOTATION_KEY from "api/quotation";
import "./publicqr.less";
import TopBar from "./TopBar";
import ProductCarousal from "./productCarousal";
import CataloguesView from "./CataloguesView";
import srcwiz from "../../../assets/images/srcwiz.png";
import failureContainer from "../../../assets/images/failureContainerMain.svg";
import { Space, Spin } from "antd";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { setItem, clear } from "utils/localStorage";
import { useGlobal } from "../../../store";
import { useLocation, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import {
  sendAnalyticsEvent,
  sendAnalyticsEventForPublicQR,
} from "../../../utils/sendAnalyticsEvent";
import failedproducts from "../../../assets/images/failedproducts.svg";
import { Skeleton } from "antd";

const { Text } = Typography;

function PublicQr() {
  //   const user = localStorage.getItem("user");
  //   const token = JSON.parse(user).access_token;
  const [manufacturer, setManufacturerData] = useState({});
  const [productData, setProductData] = useState({});
  const [catalogueData, setCatalogueData] = useState({});
  const [accessRestricted, setAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [visitor, setVisitor] = useState(null);
  const [visitorInfo, setVisitorInfo] = useState(null);
  const [globalState, setGlobalState] = useGlobal();
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [screenLoad, setScreenLoad] = useState(false);
  const [errorLog, setErrorLog] = useState(false);
  const skelData = [
    {
      count: 1,
    },
    {
      count: 1,
    },
    {
      count: 1,
    },
    {
      count: 1,
    },
  ];

  let url = window.location.pathname;
  var splitURL = url.toString().split("/");
  var hash = splitURL[splitURL.length - 1];

  const params = hash;
  const product_hash = params;

  const reloadProduct = () => {
    setLoading(true);
    QUOTATION_KEY.URL.get(`${QUOTATION_KEY.path.qrProductInfo}/${hash}`)
      .then((res) => {
        if (res.status === 200) {
          setFetchingUserData(false);
          setErrorLog(false);
          const manufacturer_data = res?.data?.data?.manufacturer_data;
          setManufacturerData(manufacturer_data);
          setProductData(res?.data?.data?.product_info);
          if (res?.data?.data?.is_public === true) {
            setAccess(false);
            setLoading(false);
            sendAnalyticsEventForPublicQR("view_public_qr", {
              product_hash: product_hash,
              visitor_data: visitorInfo,
              org_id: manufacturer_data.id,
            });
            // setAccess(true);
          } else {
            setFetchingUserData(false);
            setErrorLog(false);
            setAccess(true);
            setLoading(false);
            sendAnalyticsEventForPublicQR("view_private_qr", {
              product_hash: product_hash,
              visitor_data: visitorInfo,
              org_id: manufacturer_data.id,
            });
          }
        } else {
          setAccess(true);
          setErrorLog(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrorLog(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    // setScreenLoad(true);
    if (product_hash) {
      const fpPromise = FingerprintJS.load({
        token: process.env.REACT_APP_FINGERPRINT_TOKEN,
        endpoint: process.env.REACT_APP_SESSION_ENDPOINT,
      });
      fpPromise
        .then((fp) => fp.get({ extendedResult: true }))
        .then((result) => {
          // This is the visitor identifier:
          setVisitorInfo(result);
          // setGlobalState.settings.setVisitorData(result);
          setItem("visitor_public", result?.visitorId);
          setVisitor(result);
          setFetchingUserData(false);
          //   const getSessionId = async () => {
          axios
            .post(
              `${process.env.REACT_APP_API_SERVICE}analytics/session/?visitor=${result?.visitorId}&identifier=${product_hash}&city=${result?.ipLocation?.city?.name}&country=${result?.ipLocation?.country?.name}`,
              result
            )
            .then((res) => {
              setSessionId(res?.data?.sessionId);
              setItem("sessionId-public", res?.data?.sessionId);

              // setLoading(true);
              setScreenLoad(false);
            })
            .catch((err) => {
              setScreenLoad(false);
              console.log("err", err);
            });
          //   };
          //   if(!(type === "PREVIEW") ){
          // getSessionId();
          //   }
        })
        .catch((err) => {
          setFetchingUserData(false);
          console.log("err", err);
        });
    }
    reloadProduct();
    QUOTATION_KEY.URL.get(`${QUOTATION_KEY.path.qrCatalogues}/${hash}`).then(
      (res) => {
        if (res.status === 200) {
          setCatalogueData(res?.data?.data);
          setLoading(false);
        }
      }
    );
  }, [product_hash]);

  const catalogueClick = (data) => {
    sendAnalyticsEventForPublicQR("open_catalogue_public_page", {
      product_hash: product_hash,
      visitor_data: visitorInfo,
      org_id: manufacturer?.id,
      catalogue_id: data?.id,
    });
    window.open(
      `https://catalogue.sourcewiz.co/${
        data?.manufacturer_slug
          ? data?.manufacturer_slug + "/" + data?.collection_link
          : "/" + data?.collection_link
      } `
    );
  };

  return (
    <>
      {screenLoad && (
        <>
          {skelData.map((i) => {
            return <Skeleton active />;
          })}
        </>
      )}
      {accessRestricted ? (
        <>
          {!loading ? <TopBar manufacturer={manufacturer} /> : ""}
          {loading ? (
            <>
              {skelData.map((i) => {
                return <Skeleton active />;
              })}
            </>
          ) : (
            <div>
              <div className="failedContainer1">
                <img src={failureContainer} className="failedContainer1_img" />
              </div>
              <Row
                span={24}
                style={{ width: "100%", textAlign: "center" }}
                justify="center"
              >
                <Col span={24}>
                  <Text className="failedContainer1_txtHead">
                    Uhh Ohh! Unable to access
                  </Text>
                </Col>
              </Row>
              <Row
                span={24}
                style={{ width: "100%", textAlign: "center" }}
                justify="center"
              >
                <Col span={24}>
                  <Text className="failedContainer1_txtSub">
                    This product is restricted to view. Kindly contact the owner
                    to know more.
                  </Text>
                </Col>
              </Row>

              <div className="sourcewizFooter">
                <Text className="sourcewizFooter_txt">Powered by</Text>
                <div style={{ height: "160px" }} />
                <img src={srcwiz} className="sourcewizFooter_img" />
              </div>
            </div>
          )}
        </>
      ) : (
        // <Row span={24} justify='center'>
        //     <Col span={24}>
        //         {/* <Col span={24}> */}
        //         <img
        //     src={failureContainer}
        //     className='failedContainer_img'
        //     />
        //         {/* </Col> */}

        //     </Col>
        //     <Row span={24}>
        //     {/* <Col span={24}> */}
        //     <Text
        //     className="failedContainer_txtHead"
        //     >Uhh Ohh! Unable to access</Text>
        //     {/* </Col> */}
        //     </Row>
        //     <Row span={24}>
        //     {/* <Col span={24}> */}
        //     <Text
        //     className="failedContainer_txtSub"
        //     >
        //     This product is restricated to view. Please contact the owner for permission
        //     </Text>
        //     {/* </Col> */}
        //     </Row>
        // </Row>
        <>
          {!loading ? <TopBar manufacturer={manufacturer} /> : ""}

          {loading ? (
            <>
              {skelData.map((i) => {
                return <Skeleton active />;
              })}
            </>
          ) : (
            <div className="qrPageContainer">
              {errorLog && (
                <>
                  <Row
                    span={24}
                    justify="center"
                    className="qrPageContainer_errHandle"
                  >
                    <img
                      src={failedproducts}
                      className="qrPageContainer_errHandle_img"
                    />
                    <Row style={{ marginTop: "30px" }}>
                      <Text className="qrPageContainer_errHandle_txt1">
                        Uhh ohh! Something went wrong
                      </Text>
                    </Row>
                    <Row style={{ marginBottom: "30px" }}>
                      <Text className="qrPageContainer_errHandle_txt2">
                        Couldn’t load product details. Refresh to continue
                      </Text>
                    </Row>
                    <Button
                      style={{
                        backgroundColor: "#1D2624",
                        color: "#fff",
                        height: "40px",
                        borderRadius: "4px",
                      }}
                      onClick={() => reloadProduct()}
                      className="qrPageContainer_errHandle_refresh"
                    >
                      Refresh
                    </Button>
                  </Row>
                </>
              )}
              <ProductCarousal productData={productData && productData} />
              <Row
                className="qrPageContainer_itemRow"
                style={{ width: "100%" }}
                span={12}
              >
                <Text className="qrPageContainer_txtSmall">
                  {productData?.product_id && "Product ID"}
                </Text>
                <Text className="qrPageContainer_txtLarge">
                  {productData?.product_id}
                </Text>
                <Text className="qrPageContainer_txtSmall1">
                  {productData?.secondary_category?.name && "Category"}
                </Text>
                <Text className="qrPageContainer_txtLarge">
                  {productData?.secondary_category?.name}
                </Text>
              </Row>
              {productData?.attributes &&
                productData?.attributes?.length > 0 &&
                productData?.attributes.map((item) => {
                  return (
                    <Row
                      className="qrPageContainer_itemRow"
                      style={{ width: "100%" }}
                      span={12}
                    >
                      <Text className="qrPageContainer_txtSmall">
                        {item?.name}
                      </Text>
                      <Text className="qrPageContainer_txtLarge">
                        {item?.value}
                      </Text>
                    </Row>
                  );
                })}
            </div>
          )}
          <CataloguesView
            catalogueClick={catalogueClick}
            setCatalogueData={setCatalogueData}
            catalogueData={catalogueData}
          />
          <div className="sourcewizFooter">
            <Text className="sourcewizFooter_txt">Powered by</Text>

            <img src={srcwiz} className="sourcewizFooter_img" />
          </div>
        </>
      )}
    </>
  );
}

export default PublicQr;
