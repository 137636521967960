import React, { useState } from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";

import Home from "./Home/index";
import Catalogue from "../RouteApp";
import SentQueries from "./SentQueries";
import DraftQueries from "./DraftQueries";
import ShortlistedProducts from "./Shortlisted";
import SendQuery from "./SendQuery/SendQuery";
import EmailSentSuccess from "./SentQuery/EmailSentEnd";
import SliderView from "routes/SliderView";
import FileDownloader from "./FileDownloader";
import PublicQr from "./PublicQR";
export default function BuyerRoutes({ isAccessGranted, setIsAccessGranted }) {
  let { path } = useRouteMatch();
  const params = useParams();

  return (
    <>
      <Switch>
        <Route exact path={`/slider-view/:expname/:id`}>
          {isAccessGranted ? (
            <SliderView
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
          ) : (
            <Catalogue
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
          )}
        </Route>
        <Route exact path={`/product-details/:id`}>
        <PublicQr />
        </Route>
        <Route exact path={`/slider-view/:id`}>
          {isAccessGranted ? (
            <SliderView
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
          ) : (
            <Catalogue
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
          )}          
        </Route>
        <Route exact path={`/buyer-catalogue/:expname/:id`}>
          {/* {isAccessGranted ? ( 
            <Catalogue  setIsAccessGranted = {setIsAccessGranted} isAccessGranted={isAccessGranted}/>
          ) : (
            <Home
              isAccessGranted={isAccessGranted}
              setIsAccessGranted={setIsAccessGranted}
            />
          )} */}
          <Catalogue
            setIsAccessGranted={setIsAccessGranted}
            isAccessGranted={isAccessGranted}
          />
        </Route>

        <Route exact path={`/sent-queries/:id`}>
          {isAccessGranted ? (
            <SentQueries setIsAccessGranted={setIsAccessGranted} />
          ) : (
            <Catalogue
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
            // <Home
            //   isAccessGranted={isAccessGranted}
            //   setIsAccessGranted={setIsAccessGranted}
            // />
          )}
        </Route>
        <Route path="/draft-queries/:id">
          <DraftQueries />
        </Route>
        <Route path="/short-listed/:expname/:id">
          {isAccessGranted ? (
            <SendQuery setIsAccessGranted={setIsAccessGranted} />
          ) : (
            <Catalogue
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
            // <Home
            //   isAccessGranted={isAccessGranted}
            //   setIsAccessGranted={setIsAccessGranted}
            // />
          )}
        </Route>
        <Route path="/send-query/:expname/:id/:name">
          {isAccessGranted ? (
            <SendQuery setIsAccessGranted={setIsAccessGranted} />
          ) : (
            <Catalogue
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
            // <Home
            //   isAccessGranted={isAccessGranted}
            //   setIsAccessGranted={setIsAccessGranted}
            // />
          )}
        </Route>
        <Route path="/sent-query/:expname/:id/:enquiryId">
          {isAccessGranted ? (
            <EmailSentSuccess setIsAccessGranted={setIsAccessGranted} />
          ) : (
            <Catalogue
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
            // <Home
            //   isAccessGranted={isAccessGranted}
            //   setIsAccessGranted={setIsAccessGranted}
            // />
          )}
        </Route>
        <Route exact path={`/:expname/:id`}>
          {/* <Home
            isAccessGranted={isAccessGranted}
            setIsAccessGranted={setIsAccessGranted}
          /> */}
          <Catalogue
            setIsAccessGranted={setIsAccessGranted}
            isAccessGranted={isAccessGranted}
          />
        </Route>

        <Route exact path={`/:id`}>
          <Catalogue
            setIsAccessGranted={setIsAccessGranted}
            isAccessGranted={isAccessGranted}
          />
        </Route>

        <Route exact path={`/buyer-catalogue/:id`}>
          <Catalogue
            setIsAccessGranted={setIsAccessGranted}
            isAccessGranted={isAccessGranted}
          />
        </Route>

        <Route path="/short-listed/:id">
          {isAccessGranted ? (
            <SendQuery setIsAccessGranted={setIsAccessGranted} />
          ) : (
            <Catalogue
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
          )}
        </Route>

        <Route path="/send-query/:id/:name">
          {isAccessGranted ? (
            <SendQuery setIsAccessGranted={setIsAccessGranted} />
          ) : (
            <Catalogue
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
          )}
        </Route>

        <Route path="/sent-query/:id/:enquiryId">
          {isAccessGranted ? (
            <EmailSentSuccess setIsAccessGranted={setIsAccessGranted} />
          ) : (
            <Catalogue
              setIsAccessGranted={setIsAccessGranted}
              isAccessGranted={isAccessGranted}
            />
          )}
        </Route>
        <Route path="/downloader/ppt/:id">

            <FileDownloader/>
        </Route>
      </Switch>
    </>
  );
}
