import axios from "axios";
import API_KEY from "../../api";

export const buyerActions = {
  getCollection: async (store, url, filters, collection_link) => {

    store.setState({ loading: true });
    const { data } = await axios.get(url, {
      params: {
        filter: filters
          ? filters
          : {
            collections_id: {
              collection_link: {
                _eq: collection_link,
              },
            },
          },
      },
    });
    store.setState({ buyerCollectionData: data, loading: false });
  },
  getQueries: async (store, id) => {
    store.setState({ loading: true });
    const { data } = await API_KEY.URL.get(
      `items/enquiries`,
      {
        params: {
          filter: {
            collection: {
              id: {
                _eq: id,
              },
            },
          },
          fields: "*,products.collections_products_id.*,products.comment,products.collections_products_id.products_id.product_images.*,products.collections_products_id.products_id.product_id"
        },
      }
    );
    console.log(data, "getQueries");
    store.setState({ queriesData: data, loading: false });
  },
};
