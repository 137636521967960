import API_KEY from "../../api";
import {getItem,setItem,clear} from "utils/localStorage"

export const authActions = {
  signinAction: async (store, payload) => {
    store.setState({ loading: true });

    try {
      const authData = await API_KEY.URL.post(API_KEY.path.login, payload);
      console.log(authData);
      store.setState({ isloggedIn: authData?.data?.data, loading: false });
      setItem("user", JSON.stringify(authData?.data?.data));
      setItem("email", JSON.stringify(payload?.email));
      window.location.reload();
    } catch (error) {
      store.setState({
        error: error?.response?.data?.errors[0].message
          ? error?.response?.data?.errors[0].message
          : "Something went wrong. Try again",
        loading: false,
      });
    }
  },
  signoutAction: async (store, payload) => {
    store.setState({ loading: true });
    const userData = getItem("user");
    const user = JSON.parse(userData);
    const authData = await API_KEY.URL.post(API_KEY.path.logout, {
      refresh_token: user?.refresh_token,
    });
    if (authData?.status === 200) {
      clear();
      store.setState({ isloggedIn: false, loading: false });
    } else {
      store.setState({
        error: "Someting went wrong, Try again!",
        loading: false,
      });
    }
  },
  setOnboarding: (store, payload) => {
    store.setState({ isOnboarding: payload });
  },
  signup: async (store, payload) => {
    store.setState({ loading: true });

    try {
      const authData = await API_KEY.URL.post(API_KEY.path.users, payload, {
        params: {
          access_token: null,
        },
      });

      console.log(authData);
      store.setState({
        signupNew: authData.data?.data ? true : false,
        loading: false,
      });
      setItem("email", JSON.stringify(payload?.email));
    } catch (error) {
      
      store.setState({
        error: error?.response?.data?.errors[0].message
          ? error?.response?.data?.errors[0].message
          : "Try again with another email",
        loading: false,
      });
    }
  },
};

// javascript sdk for directus

// import API_KEY from "../../api";
// import directus from "../../api/directus";
// export const authActions = {
//   signinAction: async (store, payload) => {
//     store.setState({ loading: true });

//     try {
//       const authData = await directus.auth.login({
//         refresh: {
//           auto: true,
//         },
//         ...payload,
//       });
//       console.log(authData);
//       store.setState({ isloggedIn: authData, loading: false });
//       setItem("user", JSON.stringify(authData));
//     } catch (error) {
//       store.setState({
//         error: "Invalid user credentials or Try again!",
//         loading: false,
//       });
//     }
//   },
//   signoutAction: async (store, payload) => {
//     store.setState({ loading: true });
//     const userData = getItem("user");
//     const user = JSON.parse(userData);

//     const authData = await API_KEY.URL.post(API_KEY.path.logout, {
//       refresh_token: user?.refresh_token,
//     });
//     if (authData?.status === 200) {
//       clear();
//       store.setState({ isloggedIn: false, loading: false });
//     } else {
//       store.setState({
//         error: "Someting went wrong, Try again!",
//         loading: false,
//       });
//     }
//   },
// };
