export const settings = {
  showFiltersAction: (store, payload) => {
    store.setState({ showFilters: payload });
  },
  setShortlistedProducts: (store, payload) => {
    store.setState({ shortlistedProducts: payload });
  },
  setCollection_link: (store, payload) => {
    store.setState({ collection_link: payload });
  },
  setVisitorData: (store, payload) => {
    store.setState({ visitorData: payload });
  },
  setDeviceType: (store, payload) => {
    store.setState({ deviceType: payload });
  },
  setCatalogueSettings: (store, payload) => {
    store.setState({ catalogueSettings: payload });
  },
};
