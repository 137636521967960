import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Typography,
  Space,
  Col,
  Row,
  message,
} from "antd";
import { Colors } from "../../../theme";
import Expired_link from "./Expired_link.svg";
import "./index.css";
import {getItem} from "utils/localStorage"
 
const { Title, Text } = Typography;
const { TextArea } = Input;

export default function ExpireLink({
  isOptionalEmail,
  showModal,
  setShowModal,
  collectionDetails,
  collectionStatus,
}) {
  const [showEmailLink, setShowEmailLink] = useState(false);

  const [form] = Form.useForm();

  const handleModal = () => {
    setShowEmailLink((state) => true);
    setShowModal((state) => !state);
    console.log(showEmailLink);
  };

  const copyLink = () => {
    message.success("Request sent");
  };

  const validateMessages = {
    required: "Email is required!",
    types: {
      email: "Email is not a valid!",
    },
  };

  return (
    <>
      <div>
        {true && (
          <Modal
            keyboard={true}
            closable={false}
            //centered
            width={"35em"}
            visible={showModal}
            destroyOnClose={true}
            footer={null}
           
           // onCancel={() => setShowModal(false)}
            //closable={()=>{setShowModal((state) => !state)}}
            style={{
              borderRadius: "8px",
              height: "31em",
              background: "#FFFFFF",
              boxShadow: "inherit",
              top : "36%",
            }}
          >
            <Row>
              <Col span={24} className="alignCenter">
                <Row align="middle" >
                  <img src={Expired_link} alt="Expired-link" style={{width:"95%", height:"95%"}}/>
                </Row>

                <Row align="middle">
                  <span
                    style={{
                      fontSize: "28px",
                      textAlign: "center",
                      color: Colors.black,
                      fontFamily: "Gilroy",
                      marginTop: "1em",
                    }}
                  >
                    {collectionStatus === "inactive"
                      ? "The catalogue link has expired"
                      : "This catalogue no longer exists"}
                  </span>
                </Row>

                <Row
                  className="alignCenter"
                  style={{
                    margin: "2em 0",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: Colors.gray,
                      fontStyle: "normal",

                      padding: "1rem",
                    }}
                  >
                    {collectionStatus === "inactive"
                      ? `You can contact ${collectionDetails?.exporter_name} to request access`
                      : `You can contact ${collectionDetails?.exporter_name} and request access to their catalogues `}
                  </span>
                </Row>

                {/* <Row align="middle">
                  <Button
                    className="cta"
                    size="large"
                    onClick={handleModal}
                    // onClick={() =>
                    //   // history.push({
                    //   //   pathname: `/buyer-catalogue/${params?.id}`,
                    //   //   state: searchParams,
                    //   // })
                    // }
                  >
                    Request access
                  </Button>
                </Row> */}
              </Col>
            </Row>
            
          </Modal>
        )}
      </div>

      {/* <ExpireLinkEmail
        showEmailLink={showEmailLink}
        setShowEmailLink={setShowEmailLink}
        copyLink={copyLink}
      /> */}
    </>
  );
}

function ExpireLinkEmail({ showEmailLink, setShowEmailLink, copyLink }) {
  const [form] = Form.useForm();

  const handleConfirm = () => {
    setShowEmailLink((state) => !state);

    copyLink();
  };

  const handleCancel = () => {
    setShowEmailLink((state) => !state);
  };

  // console.log("Optional Email: ", isOptionalEmail);
  // console.log("ShowModal ", showModal);

  const validateMessages = {
    required: "Email is required!",
    types: {
      email: "Email is not a valid!",
    },
  };

  return (
    <div>
      <Modal
        keyboard={true}
        closable={false}
        centered
        width={"43em"}
        visible={showEmailLink}
        destroyOnClose={true}
        footer={null}
        bodyStyle={{
          borderRadius: "8px",
          height: "40em",
          background: "#FFFFFF",
          boxShadow: "inherit",
        }}
      >
        <Form
          name="email-form"
          className="email-form"
          initialValues={{
            email_id: getItem("BuyerEmail"),
          }}
          // onFinish={handleConfirm}
        >
          <Title
            level={5}
            style={{
              family: "DM Sans",
              style: "normal",
              weight: "bold",
              size: "19px",
              paddingBottom: "2rem",
            }}
          >
            We need your email id to send this query
          </Title>

          <Text
            level={5}
            style={{
              family: "DM Sans",
              style: "normal",
              weight: "normal",
              size: "16px",
            }}
          >
            Enter email ID
          </Text>

          <Form.Item
            name="email_id"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input
              type="email"
              placeholder="jhon@doe.com"
              size="large"
              style={{
                width: "100%",
                marginTop: "0.8rem",
                marginBottom: "1.2rem",
              }}
              // onChange={(e) => {
              //   setEmailChanged(true);
              //   setBuyeremail(e.target.value);
              //   setEmail(e.target.value);
              // }}
            />
          </Form.Item>

          <Text
            level={5}
            style={{
              family: "DM Sans",
              style: "normal",
              weight: "normal",
              size: "16px",
            }}
          >
            Your message
          </Text>

          <Form.Item name="comment">
            <TextArea
              rows={6}
              type="comment"
              placeholder="(Optional)"
              size="large"
              style={{
                width: "100%",
                marginTop: "0.8rem",
                marginBottom: "1.2rem",
              }}
              // onChange={(e) => {
              //   setEmailChanged(true);
              //   setBuyeremail(e.target.value);
              //   setEmail(e.target.value);
              // }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              className="emailbtn"
              style={{
                background: Colors.white,
                color: Colors.black,
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="emailbtn"
              size="large"
              type="primary"
              htmlType="submit"
              style={{
                background: Colors.black,
                color: Colors.white,
                border: Colors.black,
                float: "right",
              }}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
