import axios from "axios";

import { getItem } from "utils/localStorage";

const getPreviewStatus = () => {
  return getItem("type");
};

const ANALYTICS_KEY = {
  URL: axios.create({
    baseURL: process.env.REACT_APP_API_SERVICE,
  }),
};

// Dont send analytics when type==PREVIEW
ANALYTICS_KEY.URL.interceptors.request.use(function (request) {
  var type = getPreviewStatus();
  if (type === "PREVIEW") {
    return axios.Cancel("You are in preview mode", type);
  } else {
    return request;
  }
});

export const sendAnalyticsEvent = (event, data) => {
  const sessionId =
    getItem(getItem("collection_link")) || localStorage.getItem(window?.name);

  ANALYTICS_KEY.URL.post(
    `analytics/getdetails?session=${sessionId}&name=${event}`,
    data
  )
    .then((res) => {
      //console.log("view api", res?.data);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const sendAnalyticsEventForPublicQR = (event, data) => {
  const session = 1
  ANALYTICS_KEY.URL.post(
    `analytics/getdetails?session=${session}&name=${event}`,
    data
  )
    .then((res) => {
      //console.log("view api", res?.data);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const updateVisitorInfo = (data) => {
  const visitorId = getItem("visitorId");
  console.log("call", data);
  ANALYTICS_KEY.URL.patch(
    `analytics/visitor/${visitorId}`,
    data
  )
    .then((res) => {
      console.log("email updated", res?.data);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const patchAnalyticsEvent = (data) => {
  const sessionId =
    getItem(getItem("collection_link")) || localStorage.getItem(window?.name);

  ANALYTICS_KEY.URL.patch(
    `analytics/session/${sessionId}`,
    data
  )
    .then((res) => {
      //console.log("view api", res?.data);
    })
    .catch((err) => {
      console.log("err", err);
    });
};
