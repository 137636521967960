import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Typography,
  Input,
  Button,
  message,
  Modal,
  Image,
  Alert,
  Carousel,
} from "antd";
import API_KEY from "../../../api";
import QUOTATION_KEY from "api/quotation";
import "./publicqr.less";
import TopBar from "./TopBar";
import carouseNext from "../../../assets/images/carouseNext.png";
import carousePrev from "../../../assets/images/carousePrev.png";
import ImageRouter from "react-common-components/ImageRouter";

const { Text } = Typography;

const ProductCarousal = ({ productData }) => {
  const contentStyle = {
    display: 'flex',
    minHeight: "380px",
    margin: "auto",
    paddingTop: "5px",
    // paddingBottom: "20px",
    maxHeight: "380px",
    color: "#fff",
    width: "100%",
    // lineHeight: '160px',
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    
    background: "#F5F5F5",
    border: "1px solid #F5F5F5",
    borderRadius: "8px",
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", height: "100px" }}
        onClick={onClick}
      >
        <img src={carouseNext} />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", height: "100px" }}
        onClick={onClick}
      >
        <img src={carousePrev} />
      </div>
    );
  };
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <Row justify="center">
        <Col span={24}>
          <Carousel arrows {...settings}>
            {productData?.product_images &&
              productData?.product_images.length > 0 &&
              productData?.product_images.map((item) => {
                return (
                  <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Col 
                    style={contentStyle}
                    >
                      {/* <img
                        style={contentStyle}
                        // src={carouseNext}
                        className="carousalImg"
                        src={`${process.env.REACT_APP_DIRECTUS}assets/${item?.directus_files_id?.id}`}
                      /> */}
                      <ImageRouter
                        // width="70%"
                        timeout={1500}
                        preview={false}
                        className="carousalImg"
                        src={`${process.env.REACT_APP_DIRECTUS}assets/${item?.directus_files_id?.id}`}
                        alt="Product images"
                      />
                      {/* {item?.directus_files_id?.id} */}
                    </Col>
                  </Row>
                );
              })}
            {/* <div>
                  <h3 style={contentStyle}>1</h3>
                </div>
                <div>
                  <h3 style={contentStyle}>2</h3>
                </div>
                <div>
                  <h3 style={contentStyle}>3</h3>
                </div>
                <div>
                  <h3 style={contentStyle}>4</h3>
                </div> */}
          </Carousel>
        </Col>
      </Row>
    </>
  );
};
export default ProductCarousal;
