import axios from "axios";
import { getItem } from "utils/localStorage";

const getPreviewStatus = () => {
  return getItem("type");
};
const API_KEY = {
  URL: axios.create({
    baseURL: process.env.REACT_APP_DIRECTUS,
    // params: {
    //   access_token: user?.access_token,
    // },
  }),
  path: {
    collections: "items/collections",
    collections_products: "items/collections_products",
    users: "users",
    files: "files",
    assets: "assets",
    manufacturer: "items/manufacturer",
    primaryCategory: "items/primary_category",
    secondaryCategory: "items/secondary_category",
    certifications: "items/certifications",
    products: "items/products",
    colours: "items/colours",
    queryCategories: `items/secondary_category/?fields=name,id,primary_category.name,primary_category.id`,
    queryProducts: `items/products?fields=name,description,fabric,size,price,product_images.image_url,secondary_category.category_name,secondary_category.primary_category.category_name`,
    requirements: "items/requirements",
    login: "auth/login",
    logout: "auth/logout",
    manufacturers:
      "items/manufacturer_secondary_category/?fields=secondary_category_id.name,secondary_category_id.status,secondary_category_id.id",
    manufacturer_secondary_category:
      "items/manufacturer_secondary_category?fields=secondary_category_id.name,secondary_category_id.id",
    collection_products: "items/v2/collections_products",
    catalogue_created: "custom/email-service/catalogue_created",
    send_enquiry_copy: "custom/email-service/send_enquiry_copy",
    link_security_data: "link_security/get_verification_data",
    link_verification: "link_security/verify",
  },
};

API_KEY.URL.interceptors.request.use(function (request) {
  var type = getPreviewStatus();
  var ignore = ["custom", "enquiries"];
  if (type === "PREVIEW") {
    if (ignore.some(request.url.includes.bind(request.url))) {
      return axios.Cancel("You are in preview mode", type);
    } else {
      return request;
    }
  } else {
    return request;
  }
});
export default API_KEY;
