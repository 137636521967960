const Colors = {
  primaryColor: "#278977",
  secondaryColor: "#B9DFD6",
  success: "#05CA01",
  danger: "#FA5762",
  white: "#fff",
  black: "#000",
  offWhite: "#FEE8FF",
  gray: "#797F7D",
  primaryColorTint: "#F0F8F6",
  purple: "#F3EFFD",
  lemon: "#F6FBE9",
  lightgray: "#FAFAFA",
  midGray: "#686E6D",
  gray1: "#4F5655",
  gray2: "#363E3D",
  lightgreen: "#E6F4BE",
  bgGray: "#F5F5F5",
  green: "#0B7764",
};

export { Colors };
