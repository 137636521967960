import React, { useEffect } from "react";
import "./buyerPortal.less"
import LayoutComponent from "./Layout/index";
import { useGlobal } from "../../store/index";
export default function Index() {
  const [globalState, setGlobalState] = useGlobal();
  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        // return "tablet"; use if required
        return "mobile";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
  };

  useEffect(() => {
    const device = deviceType();
    localStorage.setItem("deviceType", device);
    setGlobalState.settings.setDeviceType(device);

    localStorage.setItem("mobileCols",2);

    
  },[]);
  
  return (
    <div className="buyer-portal">
      <LayoutComponent />
    </div>
  );
}
