import React from "react";
import {
  Modal,
  Button,
  Typography,
  Row,
  Col,
} from "antd";
import failureContainer from "assets/images/failureContainerMain.svg";
import "./otpverification.less";
const { Text } = Typography;

function NoAccessModal({ 
    setIsNoAccessModal, 
    isNoAccessModal,
    setShowEmailModal,
}) {
  return (
    <>
      <Modal
        className="modal-email"
        maskClosable={false}
        keyboard={false}
        closable={false}
        visible={isNoAccessModal}
        destroyOnClose={true}
        footer={null}
        centered
      >
        <div>
          <div className="noAccessContained">
            <img
              src={failureContainer}
            />
          </div>
          <Row
            span={24}
            className="noAccessContained_txtSubDiv1"
            justify="center"
          >
            <Col span={24}>
              <Text className="noAccessContained_txtHead">
                Uhh Ohh! Unable to access
              </Text>
            </Col>
          </Row>
          <Row
            span={24}
            className="noAccessContained_txtSubDiv"
            justify="center"
          >
            <Col span={24}>
              <Text className="noAccessContained_txtSub">
                This catalogue is restricted to view. Please contact the owner
                for permission
              </Text>
            </Col>
          </Row>
          <Row span={24} justify="center" className="noAccessContained_btnDiv">
            <Button
              onClick={() => {
                  setIsNoAccessModal(false)
                  setShowEmailModal(true)
                }}
              className="noAccessContained_btnDiv_btn"
            >
              Go back
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default NoAccessModal;
