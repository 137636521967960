import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Typography, message, Row } from "antd";
import back from "assets/images/goback.svg";
import "./otpverification.less";
import { Colors } from "../../../theme";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Logo from "../Layout/Logo.svg";
import {
  patchAnalyticsEvent,
  sendAnalyticsEvent,
} from "utils/sendAnalyticsEvent";
import {
  generateEmailOtp,
  generateMobileOtp,
  verifyEmailOtp,
  verifyMobileOtp,
} from "api/quotation";
import { isOnlyNumbersRegex } from "constants/index";

const { Title, Text } = Typography;

function OtpVerficationModal({
  setOtpModal,
  isOtpModal,
  otpEmail,
  otpMobile,
  setShowEmailModal,
  countryCode,
  newCatalogData,
  setEmailOtpUniqueHash,
  emailOtpUniqueHash,
  setIsAccessGranted,
  setIsUserAllowed,
  collection_link,
  isOtpModalWatermark,
  handleCatalogue
}) {
  const delayResend = 180;
  const [delay, setDelay] = useState(delayResend);
  const [mobileDelay, setMobileDelay] = useState(delayResend);
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);
  const mobileMinutes = Math.floor(mobileDelay / 60);
  const mobileSeconds = Math.floor(mobileDelay % 60);
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const location = useLocation();
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  useEffect(() => {
    const timer = setInterval(() => {
      setDelay((prev) => prev - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setMobileDelay((prev) => prev - 1);
    }, 1000);

    if (mobileDelay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    setDelay(delayResend);
    setMobileDelay(delayResend);
  }, [isOtpModal]);

  const resendMobileOtp = () => {
    if (otpMobile !== "" && countryCode !== "") {
      generateMobileOtp({
        mobile: otpMobile,
        country: countryCode.toString(),
      })
        .then((res) => {
          if (res && res?.status === 200) {
            setMobileDelay(delayResend);
            message.success("Otp sent to your mobile!");
          } else {
            message.error("Mobile is not valid");
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Mobile is not valid");
        });
    }
  };

  const resendEmailOtp = () => {
    if (otpEmail !== "") {
      generateEmailOtp({
        email: otpEmail,
        collection_link: collection_link
      })
        .then((res) => {
          if (res && res?.status === 200) {
            setDelay(delayResend);
            setEmailOtpUniqueHash(res?.data?.data?.unique_hash);
            message.success("Otp sent to your Email!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const mobileOtpFailureEvent = () => {
    sendAnalyticsEvent("otp_failure", {
      name: "otp_failure",
      modules: "OTP flow ",
      location: location.pathname,
      email: otpEmail,
      meta: {
        process: "mobile otp failure",
        email: otpEmail,
        mobile: otpMobile,
        phone_otp: phoneOtp,
        email_otp: emailOtp,
      },
    });
  };

  const emailOtpFailureEvent = () => {
    sendAnalyticsEvent("otp_failure", {
      name: "otp_failure",
      modules: "OTP flow ",
      location: location.pathname,
      email: otpEmail,
      meta: {
        process: "email otp failure",
        email: otpEmail,
        mobile: otpMobile,
        phone_otp: phoneOtp,
        email_otp: emailOtp,
      },
    });
  };

  const sendLinkOpenEvent = (isClose) => {
    // trigger link open event
    const eventName = "link_open";
    sendAnalyticsEvent(eventName, { collection_link });
    handleCatalogue(isClose)
  };

  const validateOtp = () => {
    let verifyStatus = {
      name: "buyer_side_view_catalog",
      modules: "view catalogue ",
      location: location.pathname,
      email: otpEmail !== '' ? otpEmail : null,
      mobile: otpMobile !== '' && "+" + countryCode + " " + otpMobile,
      mobile_verified: false,
      email_verified: false,
    };

    const mobileOtpPromise = new Promise((resolve, reject) => {
      if (otpMobile !== "" && countryCode !== "" && phoneOtp !== "" && phoneOtp.length === 4) {
        verifyMobileOtp({
          otp: phoneOtp,
          country: countryCode,
          mobile: otpMobile,
        })
          .then((res) => {
            if (res && res?.status === 200) {
              if (res?.data?.message === "OTP not match") {
                setMobileError(true);
                mobileOtpFailureEvent();
                setMobileError("*The OTP you have entered is not valid");
                resolve(false);
              } else {
                setMobileError("");
                verifyStatus.mobile_verified = true;
                resolve(true);
              }
            } else {
              setMobileError("");
              resolve(false);
            }
          })
          .catch((err) => {
            resolve(false);
            setMobileError("*The OTP you have entered is not valid");
            mobileOtpFailureEvent();
            console.log(err);
          });
      } else if (
        newCatalogData.mobile_otp &&
        otpMobile !== "" &&
        countryCode !== "" &&
        phoneOtp.length !== 4
      ) {
        resolve(false);
        setMobileError("Enter Mobile otp!");
      } else {
        resolve(false);
      }
    });

    const emailOtpPromise = new Promise((resolve, reject) => {
      if (otpEmail !== "" && emailOtp !== "" && emailOtp.length === 6) {
        verifyEmailOtp({
          unique_hash: emailOtpUniqueHash,
          otp: emailOtp,
        })
          .then((res) => {
            if (res && res?.status === 200) {
              verifyStatus.email_verified = true;
              resolve(true);
              setEmailError("");
            } else {
              setEmailError("");
              resolve(false);
              emailOtpFailureEvent();
            }
          })
          .catch((err) => {
            resolve(false);
            setEmailError("*The OTP you have entered is not valid");
            emailOtpFailureEvent();
            console.log(err);
          });
      } else if (
        newCatalogData?.email_otp &&
        otpEmail !== "" &&
        emailOtp.length !== 6
      ) {
        resolve(false);
        setEmailError("Enter Email otp!");
      } else {
        resolve(false);
      }
    });

    let promiseArray = [];
    if (
      newCatalogData.mobile_otp && otpMobile !== "" &&
      countryCode !== "" && phoneOtp !== ""
    ) {
      promiseArray.push(mobileOtpPromise);
    }

    if (newCatalogData?.email_otp && otpEmail !== "" && emailOtp !== "") {
      promiseArray.push(emailOtpPromise);
    }

    Promise.all(promiseArray).then((values) => {
      function validatePromises(values) {
        return values.every((element) => element === true);
      }
      if (validatePromises(values) && values.length > 0) {
        sendLinkOpenEvent(true);
        setIsUserAllowed(true)
        setOtpModal(false);
        setShowEmailModal(false);
        setIsAccessGranted(true);
        sendAnalyticsEvent("buyer_side_view_catalog", verifyStatus);
        patchAnalyticsEvent({
          email: otpEmail !== '' ? otpEmail : null,
          meta: JSON.stringify(verifyStatus),
        });
      }
    });
  };

  return (
    <>
      <Modal
        className="modal-email"
        maskClosable={false}
        keyboard={false}
        closable={false}
        visible={isOtpModal}
        destroyOnClose={true}
        footer={null}
        centered
        //closable={false}
        onCancel={() => {
          setEmailOtp("");
          setPhoneOtp("");
          setOtpModal(false);
          setShowEmailModal(true);
        }}
        bodyStyle={{ top: "19em", borderRadius: "8px" }}
      >
        <Row span={24} justify="start">
          <img
            src={back}
            className="otpScreenbackImg"
            onClick={() => {
              setEmailOtp("");
              setPhoneOtp("");
              setOtpModal(false);
              setShowEmailModal(true);
            }}
          />
          <Row className="otpVerficationHeadDiv">
            <Text className="otpVerficationHeadDiv_txt">Otp verfication</Text>
          </Row>
        </Row>

        <div className="otpDiv">
          {newCatalogData?.email_otp && (
            <div className="otpDiv_emailDiv">
              <Row>
                <Text
                  level={5}
                  style={{
                    family: "DM Sans",
                    style: "normal",
                    weight: "normal",
                    size: "16px",
                    marginBottom: "0.8rem",
                  }}
                >
                  Enter OTP sent on {otpEmail}
                </Text>
              </Row>
              <Input
                onPressEnter={() => validateOtp()}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    isOnlyNumbersRegex.test(e.target.value)
                  ) {
                    setEmailOtp(e.target.value);
                  }
                }}
                status={emailError !== "" ? "error" : ""}
                placeholder="Enter 6 digit OTP"
                style={{ height: "40px" }}
                type="text"
                maxLength={6}
                value={emailOtp}
              />
              {emailError !== "" && (
                <span style={{ color: "red", padding: "5px 0" }}>
                  {emailError}
                </span>
              )}
              <Row span={24} justify="space-between" className="otpTimer">
                <Row>
                  {(minutes > 0 || seconds > 0) && (
                    <Text>
                      OTP valid for 0{minutes}:
                      {seconds < 10 ? "0" + seconds : seconds} mins
                    </Text>
                  )}
                </Row>
                <Row>
                  <Text
                    onClick={() => resendEmailOtp()}
                    className="otpTimer_resend"
                  >
                    Resend OTP
                  </Text>
                </Row>
              </Row>
            </div>
          )}

          {newCatalogData?.mobile_otp && (
            <div>
              <Row>
                <Text
                  level={5}
                  style={{
                    family: "DM Sans",
                    style: "normal",
                    weight: "normal",
                    size: "16px",
                    marginBottom: "0.8rem",
                  }}
                >
                  Enter OTP sent on {otpMobile}
                </Text>
              </Row>
              <Input
                onPressEnter={() => validateOtp()}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    isOnlyNumbersRegex.test(e.target.value)
                  ) {
                    setPhoneOtp(e.target.value);
                  }
                }}
                type="text"
                value={phoneOtp}
                maxLength={4}
                status={mobileError !== "" ? "error" : ""}
                style={{ height: "40px" }}
                placeholder="Enter 4 digit OTP"
              />
              {mobileError !== "" && (
                <span style={{ color: "red", padding: "5px 0" }}>
                  {mobileError}
                </span>
              )}
              <Row span={24} justify="space-between" className="otpTimer">
                <Row>
                  {(mobileMinutes > 0 || mobileSeconds > 0) && (
                    <Text>
                      OTP valid for 0{mobileMinutes}:
                      {mobileSeconds < 10 ? "0" + mobileSeconds : mobileSeconds}{" "}
                      mins
                    </Text>
                  )}
                </Row>
                <Row>
                  <Text
                    onClick={() => resendMobileOtp()}
                    className="otpTimer_resend"
                  >
                    Resend OTP
                  </Text>
                </Row>
              </Row>
            </div>
          )}

          <Button
            // className="emailbtn"
            size="large"
            type="primary"
            htmlType="submit"
            onClick={() => validateOtp()}
            className={`${(newCatalogData.email_otp && emailOtp.length < 6
              ? true
              : false || (newCatalogData.mobile_otp && phoneOtp.length < 4)
                ? true
                : false) && "otpVerify"
              }`}
            disabled={
              newCatalogData.email_otp && emailOtp.length < 6
                ? true
                : false || (newCatalogData.mobile_otp && phoneOtp.length < 4)
                  ? true
                  : false
            }
            style={{
              background: Colors.black,
              color: Colors.white,
              border: Colors.black,
              width: "100%",
              marginTop: "38px",
            }}
          >
            Verify
          </Button>
        </div>

        {isOtpModalWatermark && <p
          style={{
            textAlign: "center",
            margin: "2rem 0  1rem 0",
            fontSize: "16px",
          }}
        >
          <a
            href="https://www.sourcewiz.co/"
            target="_blank"
            rel="no noreferrer"
          >
            <Title style={{ fontSize: "14px", textAlign: "center" }}>
              Powered by{" "}
              <img
                alt={Logo}
                src={Logo}
                style={{
                  width: "20px",
                  height: "20px",
                  marginBottom: "6px",
                  display: "inline-block",
                  marginRight: "0.25rem",
                }}
              />
              <Text
                level={2}
                style={{ fontFamily: "Gilroy", fontSize: "18px" }}
              >
                Sourcewiz
              </Text>
            </Title>
          </a>
        </p>}
      </Modal>
    </>
  );
}

export default OtpVerficationModal;
