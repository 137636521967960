import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Input,
  Form,
  Typography,
  message,
} from 'antd';
import { Colors } from 'theme';
import API_KEY from '../../api';
import { useGlobal } from '../../store/index';
import { getItem, setItem } from 'utils/localStorage';
import Axios from 'axios';
import { sendAnalyticsEvent } from 'utils/sendAnalyticsEvent';

import Lottie from 'react-lottie';
import * as quoteAnimation from './QuoteLottie.json';
import * as successAnimation from './SuccessLottie.json';
import { useLocation } from "react-router-dom";

const { Text } = Typography;

export const QuoteModal = ({
  quoteModal,
  setQuoteModal,
  setQuoted,
  product,
  id,
  setModalStatus,
}) => {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [form] = Form.useForm();
  const [email, setEmail] = useState(getItem('BuyerEmail'));
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if(quoteModal){
      console.log(getItem('BuyerEmail'));
      setEmail(getItem('BuyerEmail'));
    }
  },[quoteModal]);

  const defaultOptionsQuote = {
    loop: false,
    autoplay: true,
    animationData: quoteAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleClose = () => {
    setQuoteModal(false);
    setFirst(true);
    setSecond(false);
  };
  const handleQuoteRequest = () => {
    sendAnalyticsEvent("buyer_side_get_quote_submit", {
      name:'buyer_side_get_quote_submit',
      modules: 'get quote ',
      location: location.pathname,
  });
    setLoading(true);
    const payload = form.getFieldValue();
    

    let products = [];
    const element = {
      comment: payload.requirements,
      collections_products_id: product?.id,
      ...{
        ...(!product.is_primary ? { variant_id: product.variant_id } : {}),
      },
    };
    products.push(element);

    let requestPayload = {
      products: products,
      buyer_email: payload?.email,
      collection: id,
      ...{
        ...(!product.is_primary ? { variant_id: product.variant_id } : {}),
      },
    };

    API_KEY.URL.post(
      `${process.env.REACT_APP_DIRECTUS}items/enquiries`,
      requestPayload
    )
      .then((response) => {
        if (response.status === 200) {
          const enquiry_id = response?.data?.data?.id;
          sendAnalyticsEvent('send_query', { enquiry_id });
          
          Axios.get(
            `${process.env.REACT_APP_DIRECTUS}custom/email-service/new_enquiry/${enquiry_id}`
          )
            .then(function (response) {
              setSecond(true);
              setFirst(false);
              setQuoted(true);
              setLoading(false);

              // if (!email) {
                setEmail(payload?.email);
                setItem('BuyerEmail', payload?.email);
              // }
              form.resetFields();
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        message.error('Quote request unsuccessful, Please try gain.');
        console.error(error);
      });
  };
  setModalStatus(false);
  return (
    <Modal
      visible={quoteModal}
      onCancel={() => handleClose()}
      width={560}
      footer={false}
    >
      {first && (
        <Row style={{ padding: '1rem' }}>
          <Col span={24}>
            <Row className='t7' style={{ paddingBottom: '1.5rem' }}>
              Get quote for this product
            </Row>
            <Form
              form={form}
              layout='vertical'
              initialValues={{
                email: getItem('BuyerEmail'),
                requirements: '',
              }}
              onFinish={handleQuoteRequest}
            >
              {/* {!email ? ( */}
                <Form.Item
                  name='email'
                  label='Email id'
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input style={{ padding: '0.5rem' }} />
                </Form.Item>
              {/* ) : null} */}
              <Form.Item name='requirements' label='Add your requirements'>
                <Input.TextArea rows={4} />
              </Form.Item>

              <Row style={{ paddingTop: '1rem' }}>
              <Col span={12}>
                <Button
                  className='t5'
                  type='outline'
                  size='large'
                  onClick={() => {setQuoteModal(false)
                    sendAnalyticsEvent("buyer_side_shortlist_pdp_skip", {
                      modules: 'view quote ',
                      location: location.pathname,
                      // property: 'create quotation',
                  });
                  }}
                  style={{
                    color: Colors?.gray1,
                    width: '95%',
                    height: '3rem',
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12} align='end'>
                <Form.Item>
                  <Button
                    loading={loading}
                    className='t5'
                    type='dark'
                    htmlType="submit"
                    size='large'
                    // onClick={() => {
                    //   handleQuoteRequest();
                    // }}
                    style={{
                      width: '95%',
                      height: '3rem',
                      color: Colors?.white,
                      border: 'none',
                      background: Colors?.black,
                    }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            </Form>

            
          </Col>
        </Row>
      )}

      {second && (
        <Row style={{ padding: '1rem' }}>
          <Col span={24}>
            <Row justify='center'>
              <Lottie
                options={defaultOptionsQuote}
                height={'50%'}
                width={'50%'}
              />
            </Row>
            <Row
              className='t7'
              style={{ paddingBottom: '1.5rem' }}
              align='center'
            >
              Quote request sent
            </Row>

            <Row
              className='t6'
              align='center'
              style={{ color: Colors?.midGray }}
            >
              We have also sent the details to your mail at
            </Row>
            <Row
              className='t6'
              align='center'
              style={{ color: Colors?.midGray }}
            >
              {email !== "" ? email : "abc" }
            </Row>
            <Row justify='center' style={{ marginTop: '2rem' }}>
              <Button
                className='t5'
                type='dark'
                size='large'
                style={{
                  width: '30%',
                  height: '3rem',
                  color: Colors?.white,
                  border: 'none',
                  background: Colors?.black,
                }}
                onClick={() => handleClose()}
              >
                Done
              </Button>
            </Row>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export const WishlistModal = ({
  wishlistModal,
  setWishlistModal,
  product,
  setWishlisted,
}) => {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [globalState, setGlobalState] = useGlobal();
  const { wishlistComments } = globalState;
  const [form] = Form.useForm();
  const location = useLocation();

  const defaultOptionsSuccess = {
    loop: true,
    autoplay: true,
    animationData: successAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleClose = () => {
    setWishlistModal(false);
    setFirst(true);
    setSecond(false);
  };

  const handleUserDetails = () => {
    sendAnalyticsEvent("buyer_side_gq_for_all_submit", {
      name: 'buyer_side_gq_for_all_submit',
      modules: 'shortlist confirm ',
      location: location.pathname,
      // property: 'create quotation',
  });
    console.log(product);
    const payload = form.getFieldValue();
    setGlobalState.products.setWishlistComments(payload?.id);
    sessionStorage.setItem(product?.id, payload?.comment);
    form.resetFields();
  };

  return (
    <Modal
      visible={wishlistModal}
      onCancel={() => handleClose()}
      width={560}
      footer={false}
    >
      {first && (
        <Row style={{ padding: '1rem' }}>
          <Col span={24}>
            <Row className='t7'>Add anything about your favorite product</Row>
            <Row
              className='t4'
              style={{ paddingBottom: '1.5rem', color: Colors?.midGray }}
            >
              This will help us respond to you faster
            </Row>

            <Text className='t6'>Add any comments</Text>
            <Form
              form={form}
              layout='vertical'
              initialValues={{
                id: product?.id,
              }}
            >
              <Form.Item name='comment'>
                <Input.TextArea
                  className='query-box'
                  placeholder='Any special request or customization'
                />
              </Form.Item>
            </Form>

            <Row style={{ paddingTop: '2rem' }}>
              <Col span={12}>
                <Button
                  className='t5'
                  type='outline'
                  size='large'
                  onClick={() => {
                    setWishlisted(true);
                    setWishlistModal(false);
                    sendAnalyticsEvent("buyer_side_shortlist_pdp_skip", {
                      name: 'buyer_side_shortlist_pdp_skip',
                      modules: 'skip',
                      location: location.pathname,
                  });
                  }}
                  style={{
                    color: Colors?.gray1,
                    width: '95%',
                    height: '3rem',
                  }}
                >
                  Skip for now
                </Button>
              </Col>
              <Col span={12} align='end'>
                <Button
                  className='t5'
                  type='dark'
                  size='large'
                  onClick={() => {
                    sendAnalyticsEvent("buyer_side_shortlist_pdp_submit", {
                      name: 'buyer_side_shortlist_pdp_submit',
                      modules: 'skip',
                      location: location.pathname,
                  });
                    setWishlisted(true);
                    setSecond(true);
                    setFirst(false);
                    handleUserDetails();
                  }}
                  style={{
                    width: '95%',
                    height: '3rem',
                    color: Colors?.white,
                    border: 'none',
                    background: Colors?.black,
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {second && (
        <Row style={{ padding: '1rem' }}>
          <Col span={24}>
            <Row justify='center'>
              <Lottie
                options={defaultOptionsSuccess}
                height={'50%'}
                width={'50%'}
              />
            </Row>
            <Row
              className='t7'
              style={{ paddingBottom: '1.5rem' }}
              align='center'
            >
              Requirement added
            </Row>

            <Row
              className='t6'
              align='center'
              style={{ color: Colors?.midGray }}
            >
              Product added to shortlisted product with the requirement
            </Row>
            <Row justify='center' style={{ marginTop: '2rem' }}>
              <Button
                className='t5'
                type='dark'
                size='large'
                style={{
                  width: '30%',
                  height: '3rem',
                  color: Colors?.white,
                  border: 'none',
                  background: Colors?.black,
                }}
                onClick={() => handleClose()}
              >
                Done
              </Button>
            </Row>
          </Col>
        </Row>
      )}
    </Modal>
  );
};