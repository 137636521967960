import React from "react";
import { Row, Typography, Card } from "antd";
const { Title, Text } = Typography;

export default function QueryCard({ query, handleActiveQuery, isActive }) {
  return (
    <Row style={{ marginBottom: "16px", }}>
      <Card
        hoverable
        style={{ borderRadius: "4px", width: "24.18rem", minHeight: "8.5rem",  background: isActive?"#F0F8F6": "white" }}
        onClick={() => handleActiveQuery(query?.id)}
      >
        <Row>
          <Text type="secondary">{query?.date_created?.slice(0, 10)}</Text>
        </Row>
        <Row>
          <Title level={4}>ENQSWZ{query?.id}</Title>
        </Row>
        <Row>
          <Text type="secondary">{query?.comment}</Text>
        </Row>
      </Card>
    </Row>
  );
}
