import React, { useEffect, useState } from "react";
import { Layout, Alert, Typography } from "antd";
import BuyerRoutes from "../buyerRoutes";
import Navbar from "./Navbar";
import { Colors } from "../../../theme";
import { WarningFilled } from "@ant-design/icons";
import { setItem, getItem, removeItem } from "utils/localStorage";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

const { Title, Text } = Typography;

// const MenuComponent = ({ mode, hideMobileMenu }) => {
//   const [globalState, setGlobalState] = useGlobal();
//   const local_collection_link =getItem("collection_link");
//   const { collection_link } = globalState;
//   return (
//     <Menu hidden={hideMobileMenu} theme="light" mode={mode}>
//       <Menu.Item key="0" style={{ borderBottom: 0 }}>
//         <Title
//           level={2}
//           style={{
//             fontFamily: "Gilroy",
//             color: Colors.primaryColor,
//             paddingTop: "1rem",
//             marginRight: "25rem",
//             borderBottomColor: "none",
//           }}
//         >
//           SourceWiz
//         </Title>
//       </Menu.Item>
//       <Link
//         to={`bptl/buyer-catalogue/${
//           collection_link || local_collection_link
//         }`}
//       >
//         <Menu.Item key="1" icon={<ShopOutlined />}>
//           Catalogue
//         </Menu.Item>
//       </Link>
//       <Link
//         to={`bptl/sent-queries/${
//           collection_link || local_collection_link
//         }`}
//       >
//         <Menu.Item key="2" icon={<SendOutlined />}>
//           Sent queries
//         </Menu.Item>
//       </Link>
//       <Link
//         to={`bptl/draft-queries/${
//           collection_link || local_collection_link
//         }`}
//       >
//         <Menu.Item key="3" icon={<FormOutlined />}>
//           Draft queries
//         </Menu.Item>
//       </Link>
//       <Link
//         to={`bptl/short-listed/${
//           collection_link || local_collection_link
//         }`}
//       >
//         <Menu.Item key="4" icon={<SaveOutlined />}>
//           Shortlisted Products ({globalState.shortlistedProducts.length})
//         </Menu.Item>
//       </Link>
//     </Menu>
//   );
// };
export default function LayoutComponent() {
  const location = useLocation();
  // const [hideMobileMenu, setHideMobileMenu] = useState(true);

  window.pendo.initialize();
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("collection_link") !== null &&
      window.performance.navigation.type === 1 &&
      location.pathname.includes("/product-details") === false
    ) {
      let _url = "/";
      if (
        localStorage.getItem("manufacturer_slug") !== null &&
        localStorage.getItem("manufacturer_slug") !== "" &&
        localStorage.getItem("manufacturer_slug") !== "null"
      ) {
        _url = _url + localStorage.getItem("manufacturer_slug") + "/";
      }
      if (
        localStorage.getItem("collection_link") !== "" &&
        localStorage.getItem("collection_link") !== null
      ) {
        _url = _url + localStorage.getItem("collection_link");
      }
      window.location.href = _url;
    }
    // };
  }, []);

  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  removeItem("type");
  if (urlParams.get("type") === "PREVIEW") {
    setItem("type", "PREVIEW");
  }
  const type = getItem("type");
  const isPreview = type === "PREVIEW" ? true : false;

  return (
    <Layout>
      <Layout style={{ background: "white" }}>
        <Content style={{ margin: "0 16px 0" }}>
          <div
            className="site-layout-background"
            style={{
              //padding: "20px 40px",
              minHeight: 360,
              background: "white",
            }}
          >
            <BuyerRoutes
              isAccessGranted={isAccessGranted}
              setIsAccessGranted={setIsAccessGranted}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
