import API_KEY from "../../api";

export const collections = {
  createCollection: async (store, payload) => {
    store.setState({ loading: true });
    const response = await API_KEY.URL.post(API_KEY.path.collections, payload);
    store.setState({ createCollection: response?.data?.data, loading: false });
  },
  getCollection: async (store, payload) => {
    store.setState({ loading: true });
    const response = await API_KEY.URL.get(API_KEY.path.collections, payload);
    store.setState({ collections: response?.data?.data, loading: false });
  },

  setEditCollectionProducts: (store, payload) => {
    store.setState({ editCollectionProducts: payload });
  },
};
