import React from 'react';
import { Row, Col, Skeleton } from 'antd';
export default function EmptyState() {
  return (
    <Row
      style={{ height: '100vh', margin: '2em', padding: '2em' }}
      justify='center'
      align='middle'
    >
      <Col xl={12} xxl={12} lg={24} md={24} sm={24} xs={24} align='center'>
        <Skeleton.Image active style={{ width: '20em', height: '20em' }} />
      </Col>
      <Col
        xl={12}
        xxl={12}
        lg={24}
        md={24}
        sm={24}
        xs={24}
        style={{ padding: '2rem 0' }}
      >
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </Col>
    </Row>
  );
}
