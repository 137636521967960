import { authActions } from "./authActions";
import { settings } from "./settings";
import { requirements } from "./requirements";
import { filters } from "./filters";
import { products } from "./products";
import { collections } from "./collections";
import { manufacturer } from "./manufacturer";
import { buyerActions } from "./buyerActions";
import { pageSize } from "./pageSize";
import { scrollPosition } from "./scrollPosition";
import { quickFilters } from "./quickFilters";
import { collectionDetails } from "./collectionDetails";

const actions = {
  authActions,
  settings,
  requirements,
  filters,
  products,
  collections,
  manufacturer,
  buyerActions,
  pageSize,
  scrollPosition,
  quickFilters,
  collectionDetails
};

export default actions;
