export const ActionTypes = Object.freeze({
  ADD_PRODUCT: "productsActions/ADD_PRODUCT",
  REMOVE_PRODUCT: "productsActions/REMOVE_PRODUCT",
  SET_PRODUCTS: "productsActions/SET_PRODUCTS",
});

export function addProduct(product, collectionId, variantMetaData,price, sellingPriceAttr) {
  return {
    type: ActionTypes.ADD_PRODUCT,
    payload: {
      product,
      collectionId,
      variantMetaData,
      price,
      sellingPriceAttr
    },
  };
}

export function removeProduct(product, collectionId) {
  return {
    type: ActionTypes.REMOVE_PRODUCT,
    payload: {
      product,
      collectionId,
    },
  };
}

export function setProducts(products = [], collectionId) {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: {
      products,
      collectionId,
    },
  };
}

export default {
  ActionTypes,
  addProduct,
  removeProduct,
  setProducts,
};
