import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  Form,
  Input,
  Typography,
  message,
  Select,
  Row,
  Col,
} from "antd";
import { Colors } from "../../../theme";
import LogoComponent from "../Home/LogoComponent";
import Logo from "../Layout/Logo.svg";
import { setItem } from "utils/localStorage";
import "./index.css";
import {
  sendAnalyticsEvent,
  patchAnalyticsEvent,
} from "utils/sendAnalyticsEvent";
import { useLocation } from "react-router-dom";
import { generateEmailOtp, generateMobileOtp, getCountryCodes, linkSecurityVerify } from "api/quotation";
const { Title, Text } = Typography;

export default function EmailInputModal({
  isOptionalEmail,
  showModal,
  setShowModal,
  handleCatalogue,
  isPasswordReq,
  isLinkExpired,
  imageQuality,
  isActiveCatalogue,
  collectionDetails,
  collection_link,
  allowedPPTDownload,
  showFilters,
  fetchingUserData,
  setOtpModal,
  isOtpModal,
  otpEmail,
  setOtpEmail,
  otpMobile,
  setOtpMobile,
  setIsNoAccessModal,
  countryCode,
  handleChange,
  newCatalogData,
  setEmailOtpUniqueHash,
  setIsUserAllowed,
  sessionId,
  isUserAllowed,
  isEmailModalWatermark
}) {
  const { Option } = Select;
  const [form] = Form.useForm();
  // const { trackEvent } = useTracking();
  const location = useLocation();

  const [loader, setLoader] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [passCodeError, setPassCodeError] = useState(false);
  useEffect(() => {
    getCountryCodes()
      .then((res) => {
        if (res && res?.status === 200) {
          setCountryCodes(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (newCatalogData?.email_otp === false &&
      newCatalogData?.mobile_otp === false && sessionId) {
      sendAnalyticsEvent("buyer_side_view_catalog", {
        name: "buyer_side_view_catalog",
        modules: "view catalogue ",
        location: location.pathname,
        email: otpEmail !== '' ? otpEmail : null,
        meta: {
          mobile_verified: false,
          email_verified: false,
          email: otpEmail !== '' ? otpEmail : null,
          mobile: otpMobile !== '' && '+' + countryCode + ' ' + otpMobile,
        },
      });
      patchAnalyticsEvent({
        email: otpEmail !== '' ? otpEmail : null, meta: JSON.stringify({
          mobile_verified: false,
          email_verified: false,
          email: otpEmail !== '' ? otpEmail : null,
          mobile: otpMobile !== '' && '+' + countryCode + ' ' + otpMobile,
        })
      });
    }
  }, [sessionId, isUserAllowed])

  const sendLinkOpenEvent = (isClose) => {
    // trigger link open event
    if (
      newCatalogData?.email_otp === false &&
      newCatalogData?.mobile_otp === false
    ) {
      const eventName = "link_open";

      sendAnalyticsEvent(eventName, { collection_link });
      handleCatalogue(isClose);
    }
  };

  const handleEmailAuthPromise = () => {
    return new Promise(
      (emailAuthResolve, emailAuthReject) => {

        generateEmailOtp({
          email: otpEmail,
          collection_link: collection_link
        })
          .then((res) => {
            if (res && res?.status === 200) {
              setEmailOtpUniqueHash(res?.data?.data?.unique_hash);
              emailAuthResolve(true);
            } else {
              emailAuthResolve(false);
            }
          })
          .catch((err) => {
            emailAuthResolve(false);
            console.log(err);
          });
      }
    );
  }

  const handleMobileAuthPromise = () => {
    return new Promise((mobileAuthResolve, mobileAuthReject) => {
      generateMobileOtp({
        mobile: otpMobile,
        country: countryCode.toString(),
      })
        .then((res) => {
          if (res && res?.status === 200) {
            mobileAuthResolve(true);
          } else {
            message.error("Mobile is not valid");
            mobileAuthResolve(false);
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Mobile is not valid");
          mobileAuthResolve(false);
        });
    })
  }

  const blockedEmailCheck = () => {
    sendAnalyticsEvent("emailBlocked", {
      name: "emailBlocked",
      modules: "view catalogue ",
      location: location.pathname,
      email: otpEmail,
      meta: {
        email: otpEmail,
        mobile: otpMobile ? otpMobile : '',
      },
    });
    setIsNoAccessModal(true);
  }

  const allowedEmailCheck = () => {
    sendAnalyticsEvent("emailnotAllowed", {
      name: "emailnotAllowed",
      modules: "view catalogue ",
      location: location.pathname,
      email: otpEmail,
      meta: {
        email: otpEmail,
        mobile: otpMobile ? otpMobile : '',
      },
    });
    setIsNoAccessModal(true);
  }

  const allowUserIf2FANotRequired = () => {
    if (
      newCatalogData?.email_otp === false &&
      newCatalogData?.mobile_otp === false
    ) {
      setIsUserAllowed(true);
    }
  }

  const onFinish = (value) => {
    setLoader(true);

    const isEmailAllowed = newCatalogData?.allowed_emails.length > 0
      ? newCatalogData?.allowed_emails.includes(value?.email_id)
      : true;
    const isEmailBlockedList = newCatalogData?.blocked_emails;
    const isEmailBlocked = isEmailBlockedList?.length > 0 ? isEmailBlockedList.includes(value?.email_id)
      : false;

    if (isEmailBlocked) {
      blockedEmailCheck()
    }
    if (!isEmailAllowed) {
      allowedEmailCheck()
    }

    if (isPasswordReq) {
      linkSecurityVerify(collection_link,
        {
          expiry: isLinkExpired,
          email: value.email_id,
          passcode: value.password,
          download_ppt: allowedPPTDownload,
          show_products_filter: showFilters,
          catalogue_thumbnail_quality: imageQuality,
        }
      )
        .then((result) => {
          if (result?.data?.verified === true) {
            setPassCodeError(false);
            const authPromiseArray = []
            if (newCatalogData?.email_otp && value?.email_id !== "") {
              if (isEmailAllowed && !isEmailBlocked) {
                authPromiseArray.push(handleEmailAuthPromise())
              }
            }
            if (
              isEmailAllowed && !isEmailBlocked && newCatalogData?.mobile_otp &&
              otpMobile !== "" && countryCode !== ""
            ) {
              authPromiseArray.push(handleMobileAuthPromise())
            }
            if (
              !newCatalogData?.email_otp && !newCatalogData?.mobile_otp &&
              isEmailAllowed && !isEmailBlocked
            ) {
              setOtpModal(false);
              setShowModal(false);
            }
            else {
              Promise.all(authPromiseArray).then((values) => {
                function validatePromises(values) {
                  return values.every(element => element === true);
                }
                if (validatePromises(values) && values.length > 0) {
                  setOtpModal(true);
                  setShowModal(true);
                }
                else {
                  setOtpModal(false);
                  setShowModal(true);
                }
              }).catch((err) => {
                setOtpModal(false);
                setShowModal(true);
              })
            }
            if (!isEmailBlocked && isEmailAllowed) {
              allowUserIf2FANotRequired();
              sendLinkOpenEvent(true);
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          sendAnalyticsEvent("passcodeFailure", {
            name: "passcodeFailure",
            modules: "view catalogue ",
            location: location.pathname,
            email: value?.email_id,
            meta: {
              email: value?.email_id,
              mobile: otpMobile ? otpMobile : '',
            },
          });
          setPassCodeError(true);
          setLoader(false);
          console.log(error);
        });
    } else {
      const authPromiseArray = []
      if (newCatalogData?.email_otp && value?.email_id !== "") {
        if (isEmailAllowed && !isEmailBlocked) {
          authPromiseArray.push(handleEmailAuthPromise())
        }
      }
      if (
        isEmailAllowed && !isEmailBlocked && newCatalogData?.mobile_otp &&
        otpMobile !== "" && countryCode !== ""
      ) {
        authPromiseArray.push(handleMobileAuthPromise())
      }
      if (
        !newCatalogData?.email_otp && !newCatalogData?.mobile_otp &&
        isEmailAllowed && !isEmailBlocked
      ) {
        setOtpModal(false);
        setShowModal(false);
      }
      else {
        Promise.all(authPromiseArray).then((values) => {
          function validatePromises(values) {
            return values.every(element => element === true);
          }
          if (validatePromises(values) && values.length > 0) {
            setOtpModal(true);
            setShowModal(true);
          }
          else {
            setOtpModal(false);
            setShowModal(true);
          }
        }).catch((err) => {
          setOtpModal(false);
          setShowModal(true);
        })
      }

      if (!isEmailBlocked && isEmailAllowed) {
        allowUserIf2FANotRequired();
        sendLinkOpenEvent(true);
      }
      setLoader(false);
    }
  };

  const handleSkip = () => {
    if (isOptionalEmail & !isPasswordReq) {
      // handleCatalogue();
    } else {
      setShowModal((state) => !state);
    }
    sendLinkOpenEvent();
  };

  return (
    <div>
      {/* {!isActiveCatalogue || isLinkExpired ? (
        <ExpireLink
          showModal={showModal}
          setShowModal={setShowModal}
          collectionDetails={collectionDetails}
        />
      ) : null} */}

      <Modal
        className="modal-email"
        maskClosable={false}
        keyboard={false}
        closable={isOptionalEmail && !newCatalogData?.mobile && !newCatalogData?.passcode}
        visible={showModal}
        destroyOnClose={true}
        footer={null}
        centered
        //closable={false}
        onCancel={() => onFinish()}
        bodyStyle={{ top: "19em", borderRadius: "8px" }}
      >
        <LogoComponent />

        <Form name="email-form" form={form} className="email-form" onFinish={onFinish}>
          <Text
            level={5}
            style={{
              family: "DM Sans",
              style: "normal",
              weight: "normal",
              size: "16px",
              marginBottom: "0.8rem",
            }}
          >
            {isOptionalEmail ? `Email ID (Optional)` : `Email ID`}
          </Text>

          <Form.Item
            name="email_id"
            rules={[
              {
                required: !isOptionalEmail,
                message: "*Please enter the email ID",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="jhon@doe.com"
              size="large"
              style={{
                width: "100%",
                marginTop: "0.8rem",
                //marginBottom: "0.8rem",
              }}
              onChange={(e) => {
                setItem("BuyerEmail", e.target.value);
                setOtpEmail(e.target.value);
              }}
            />
          </Form.Item>

          {newCatalogData?.mobile && (
            <>
              <Row style={{ marginBottom: "0px" }}>
                <Text
                  level={5}
                  style={{
                    family: "DM Sans",
                    style: "normal",
                    weight: "normal",
                    size: "16px",
                    marginBottom: "0.8rem",
                  }}
                >
                  Phone number
                </Text>
              </Row>

              <Row Span={24} justify="start">
                <Col span={6}>
                  <Form.Item
                    name="phone_code"
                    rules={[
                      {
                        required: newCatalogData?.mobile,
                        message: "*Country code",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleChange}
                      placeholder="IND +(91)"
                    // defaultValue='91'
                    >
                      {countryCodes &&
                        countryCodes?.length > 0 &&
                        countryCodes.map((item) => {
                          return (
                            <Option
                              key={item.id}
                              value={item?.iso3 + " " + item?.phonecode}
                            >
                              {item?.iso3} +({item?.phonecode})
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={17}>
                  <Form.Item
                    name="phone_no"
                    rules={[
                      {
                        required: newCatalogData?.mobile,
                        message: "*Please enter the phone number",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        setItem("BuyerPhone", e.target.value);
                        setOtpMobile(e.target.value);
                      }}
                      type="number"
                      placeholder="Enter phone number"
                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {isPasswordReq ? (
            <>
              <Text
                level={5}
                style={{
                  family: "DM Sans",
                  style: "normal",
                  weight: "normal",
                  size: "16px",
                  marginBottom: "0.8rem",
                }}
              >
                Passcode
              </Text>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "*Please enter the passcode" },
                ]}
                style={{ marginBottom: '0px' }}
              >
                <Input
                  placeholder="Required to view"
                  size="large"
                  style={{
                    width: "100%",
                    marginTop: "0.8rem",
                    border: passCodeError ? "solid 1px red" : '',
                  }}
                  onChange={(e) => {
                    setItem("BuyerPassword", e.target.value);
                  }}
                />
              </Form.Item>
              {passCodeError && <span style={{ color: 'red', marginTop: '0px' }}>Invalid passcode</span>}
            </>
          ) : null}

          <Form.Item>
            {/* {!isPasswordReq & isOptionalEmail ? (
                <Button
                  size="large"
                  className="emailbtn"
                  style={{
                    background: Colors.white,
                    color: Colors.black,
                    marginTop: "0.8rem",
                  }}
                  onClick={handleSkip}
                >
                  Skip for now
                </Button>
              ) : null} */}
            <Button
              className="emailbtn"
              size="large"
              type="primary"
              htmlType="submit"
              loading={fetchingUserData || loader}
              style={{
                background: Colors.black,
                color: Colors.white,
                border: Colors.black,
                width: "100%",
                marginTop: "2rem",
              }}
            >
              View Catalogue
            </Button>
          </Form.Item>
        </Form>

        {isEmailModalWatermark && (<p
          style={{
            textAlign: "center",
            margin: "2rem 0  1rem 0",
            fontSize: "16px",
          }}
        >
          <a
            href="https://www.sourcewiz.co/"
            target="_blank"
            rel="no noreferrer"
          >
            <Title style={{ fontSize: "14px", textAlign: "center" }}>
              Powered by{" "}
              <img
                alt={Logo}
                src={Logo}
                style={{
                  width: "20px",
                  height: "20px",
                  marginBottom: "6px",
                  display: "inline-block",
                  marginRight: "0.25rem",
                }}
              />
              <Text
                level={2}
                style={{ fontFamily: "Gilroy", fontSize: "18px" }}
              >
                Sourcewiz
              </Text>
            </Title>
          </a>
        </p>)}
      </Modal>
    </div>
  );
}
