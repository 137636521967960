import React, { useState, useRef } from "react";

import { Shimmer } from "./Shimmer/index";

const ImageBox = ({
  imgUrl,
  fallbackUrl,
  alt = "",
  imgIndex = 0,
  onClick,
  shimmerStyles = {},
}) => {
  const [loading, setLoading] = useState(true);
  const [imgSrc, setImageSrc] = useState(imgUrl);
  const imageRef = useRef();

  const handleOnError = function handleOnError() {
    setImageSrc(fallbackUrl);
  };

  const handleOnLoad = function handleOnLoad() {
    setLoading(false);
  };

  return (
    <>
      <div
        style={!loading ? {} : { display: "none" }}
        onClick={onClick}
        disabled={!onClick}
      >
        <img
          ref={imageRef}
          src={imgSrc}
          alt={alt}
          onLoad={handleOnLoad}
          onError={handleOnError}
          width={"100%"}
          height={"100%"}
          key={imgIndex}
        />
      </div>
      <div style={loading ? {} : { display: "none" }}>
        <Shimmer style={shimmerStyles} />
      </div>
    </>
  );
};

export default ImageBox;
