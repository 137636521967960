import React from "react";
import DraftQuery from "./DraftQuery";

export default function DraftQueries() {
  return (
    <div //style={{ background: "rgba(245, 245, 245, 1)" }}
    >
      <DraftQuery />
    </div>
  );
}
