import React, { useEffect, useRef, useState, useMemo } from "react";

import { Typography, Row, Col, Card, Space, Image, Button } from "antd";

const getImgUrl = (product) =>
  `${process.env.REACT_APP_DIRECTUS}assets/${product?.product_images[0]?.directus_files_id}?transforms=[["resize",{"width":283,"height":214,"background":{"r":242,"g":242,"b":242}, "fit":"contain"}]]`;

const getFallbackUrl = (product) =>
  `${process.env.REACT_APP_DIRECTUS}assets/${product?.product_images[0]?.directus_files_id}?transforms=[["resize",{"width":283,"height":214,"background":{"r":242,"g":242,"b":242}, "fit":"contain"}]]`;

export default function ProductCard({
  product,
  index,
  handleCardClick,
  handleDelete,
}) {
  const [loading, setLoading] = useState(true);

  const handleOnLoad = () => {
    setLoading(false);
  };

  return (
    <Row>
      <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24}>
        <img
          src={getImgUrl(product)}
          onLoad={handleOnLoad}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = getFallbackUrl(product);
          }}
          onClick={() => {
            handleCardClick(product);
          }}
          className="send-query-card-image"
          alt="product-img"
          style={{ objectFit: "contain" }}
        />
      </Col>
    </Row>
  );
}
