import React from 'react';
import SourceApp from '../src/containers/SourceApp';
import { hotjar } from 'react-hotjar';
import configureStore from 'redux/configureStore';
import { Provider } from 'react-redux';
import FullStory from 'react-fullstory';
import MaintainanceScreen from 'components/MaintenaceScreen';
hotjar.initialize(2721152, 6);
const { store } = configureStore();
export default function App() {
  const isMaintainence =
    process?.env?.REACT_APP_MAINTAINANCE &&
    process?.env?.REACT_APP_MAINTAINANCE === 'true';
  return isMaintainence ? (
    <MaintainanceScreen />
  ) : (
    <div>
      <Provider store={store}>
        {process.env.REACT_APP_ENV === 'prod' ? (
          <FullStory org={process.env.REACT_APP_FULL_STORY} />
        ) : null}
        <SourceApp />
      </Provider>
    </div>
  );
}
