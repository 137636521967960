import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Divider } from "antd";
import SVG from "./background.svg";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../../store/index";
import axios from "axios";
import ImagePlacholder from "../../../assets/images/image-placeholder.png";
import EmailInputModal from "../Catalogue/EmailModal";

import moment from "moment";
import API_KEY from "../../../api";
import ImageBox from "components/ImageBox";
import ExpireLink from "../Catalogue/ExpireLink";
import { setItem, removeItem, getItem } from "utils/localStorage";

const useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
  const [src, setSrc] = useState(lowQualitySrc);
  useEffect(() => {
    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [lowQualitySrc, highQualitySrc]);

  return [src, { blur: src === lowQualitySrc }];
};

const { Title, Text } = Typography;
export default function Home({ isAccessGranted, setIsAccessGranted }) {
  const [globalState, setGlobalState] = useGlobal();
  const [collectionDetails, setCollectionDetails] = useState(null);

  const [isOptionalEmail, setIsOptionalEmail] = useState(true);
  const [isPasswordReq, setIsPasswordReq] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isActiveCatalogue, setIsActiveCatalogue] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showExpireModal, setShowExpireModal] = useState(false);
  //const [isAccessGranted, setIsAccessGranted] = useState(false);

  const params = useParams();
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const history = useHistory();

  setItem("collection_link", params?.id);

  const { collection_link, visitorData, catalogueSettings = {} } = globalState;
  const { hide_catalogue_name = false } = catalogueSettings;
  useEffect(() => {
    if (params?.id) {
      setGlobalState.settings.setCollection_link(params?.id);
    }
  }, [setGlobalState, params?.id]);

  // get collection data using collection link and set it to state and local storage
  useEffect(() => {
    const searchString = window.location.search;
    // const urlParams = new URLSearchParams(searchString);
    // removeItem("type");
    // console.log(params)
    if (urlParams.get("type") === "PREVIEW") {
      setItem("type", "PREVIEW");
    }
    API_KEY.URL.get(API_KEY.path.collections, {
      params: {
        "filter[collection_link][_eq]": params?.id,
        fields: "*",
      },
    }).then((res) => {
      console.log("Collection_data: ", res);
      setCollectionDetails(res?.data?.data?.length ? res?.data?.data[0] : "");
      setItem(
        "collection_details",
        res?.data?.data?.length ? JSON.stringify(res?.data?.data[0]) : ""
      );
    });
  }, [collection_link, params]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVICE}link_security/get_verification_data?collection_link=${params.id}`
      )
      .then((res) => {
        console.log("Email-data: ", res?.data?.email);

        setIsPasswordReq(res?.data?.passcode);
        setIsOptionalEmail(!res?.data?.email);
        setIsLinkExpired(res?.data?.expiry);

        setIsActiveCatalogue(res?.data?.status === "inactive" ? false : true);

        if (res?.data?.status === "inactive" || res?.data?.expiry) {
          setShowExpireModal(true);
        } else {
          setShowExpireModal(false);
        }

        setItem(
          "link_Security_data",
          res?.data ? JSON.stringify(res?.data) : ""
        );
      });
    // API_KEY.URL.get(API_KEY.path.link_security_data, {
    //   params: {
    //     collection_link: collection_link,
    //   },
    // }).then((res) => {
    //   console.log("Security Link:", res);
    //   setIsPasswordReq(res?.passcode);
    //   setIsOptionalEmail(res?.email);
    //   setIsLinkExpired(res?.expiry);
    //   setItem(
    //     "link_Security_data",
    //     res?.data?.data?.length ? JSON.stringify(res?.data?.data[0]) : ""
    //   );
    // });
  }, [collection_link]);

  const handleEmailModal = () => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_API_SERVICE}link_security/get_verification_data?collection_link=${params.id}`
      )
      .then((res) => {
        console.log("Email-data: ", res?.data?.email);

        setIsPasswordReq(res?.data?.passcode);
        setIsOptionalEmail(!res?.data?.email);
        setIsLinkExpired(res?.data?.expiry);

        setIsActiveCatalogue(res?.data?.status === "inactive" ? false : true);

        setItem(
          "link_Security_data",
          res?.data ? JSON.stringify(res?.data) : ""
        );
        setLoader(false);
        setShowModal((state) => !state);
      });

    // setIsOptionalEmail(true);
    //handleViewCatalogue();
  };

  const handleViewCatalogue = () => {
    // alert("View Catalogue");
    setIsAccessGranted((state) => true);
    history.push({
      pathname: params?.expname
        ? `/${params?.expname}/${params?.id}`
        : `/${params?.id}`,
      search: urlParams.toString(),
    });
    API_KEY.URL.post(
      `custom/email-service/trace/catalogue_opened/${collectionDetails?.id}`,
      {
        time: moment().format("MMMM Do YYYY, h:mm:ss a"),
        buyer_email: getItem("BuyerEmail"),
        city: visitorData?.ipLocation?.city?.name,
        country: visitorData?.ipLocation?.country?.name,
      }
    );

    removeItem("shortlisted_products");
  };

  const tinyImage = `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.banner_image}?quality=10`;
  const largeImage = collectionDetails?.banner_image
    ? `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.banner_image}?quality=100`
    : SVG;

  const logolarge = `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.logo}?quality=100`;

  const [src, { blur }] = useProgressiveImg(tinyImage, largeImage);

  return (
    <>
      <EmailInputModal
        isOptionalEmail={isOptionalEmail}
        showModal={showModal}
        setShowModal={setShowModal}
        handleCatalogue={handleViewCatalogue}
        isPasswordReq={isPasswordReq}
        isLinkExpired={isLinkExpired}
        isActiveCatalogue={isActiveCatalogue}
        collectionDetails={collectionDetails}
        collection_link={params?.id}
      />

      {!isActiveCatalogue || isLinkExpired ? (
        <ExpireLink
          showModal={showExpireModal}
          setShowModal={setShowExpireModal}
          collectionDetails={collectionDetails}
        />
      ) : null}
      <Row>
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
        >
          <Row
            span={24}
            className={blur ? "box animate" : ""}
            style={{
              // backgroundColor: dominantImageColor,
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              transition: "filter 0.8s ease-out",
            }}
          >
            <img
              src={largeImage}
              style={{
                width: "100%",
                height: "100%",
                display: "block",
                filter: blur ? "blur(20px)" : "none",
                transition: blur ? "none" : "filter 0.5s ease-out",
              }}
              // onError={(e) => {
              //   e.target.onerror = null;
              //   e.target.src = collectionDetails?.banner_image
              //     ? `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.banner_image}?quality=100`
              //     : SVG;
              // }}
            />
          </Row>

        </Col>
      </Row>
      <Row align="middle" style={{ marginTop: "1.5rem" }}>
        <Col
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
        >
          <Row align="middle">
            <Col
              xl={{ span: 3 }}
              lg={{ span: 3 }}
              md={{ span: 3 }}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <div
                className={blur ? "box animate" : ""}
                style={{
                  // backgroundColor: dominantImageColor,
                  width: "100%",
                  // height: "100%",
                  borderRadius: "8px",
                  transition: "filter 0.5s ease-out",
                }}
              >
                <img
                  className="buyer-logo"
                  src={logolarge}
                  alt="Buyer Portal"
                  style={{
                    display: "block",
                    borderRadius: "0.5rem",
                    filter: blur ? "blur(20px)" : "none",
                    transition: blur ? "none" : "filter 0.3s ease-out",
                  }}
                  onError={(e) => {
                    e.target.src = collectionDetails?.logo
                      ? `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.logo}?quality=100`
                      : ImagePlacholder;
                  }}
                />
              </div>

            </Col>
            <Col>
              <Title
                className="buyer-home-title"
                style={{ fontFamily: "Gilroy", margin: 0 }}
              >
                {collectionDetails?.exporter_name ||
                  collectionDetails?.buyer_name}
              </Title>
              {!hide_catalogue_name && (
              <Text style={{ fontSize: "1rem" }}>
                {collectionDetails?.collection_name}
              </Text>
              )}
            </Col>
          </Row>
        </Col>

        {/* <Col
          xl={{ span: 6, offset: 6 }}
          lg={{ span: 6, offset: 6 }}
          md={{ span: 6, offset: 6 }}
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
        >
          <Row>
            <Col>
              <img
                style={{ borderRadius: 8, marginRight: "0.5rem" }}
                alt="product img"
                src={"https://via.placeholder.com/64x64.png"}
              />
            </Col>
            <Col>
              <img
                style={{ borderRadius: 8, marginRight: "0.5rem" }}
                alt="product img"
                src={"https://via.placeholder.com/64x64.png"}
              />
            </Col>
            <Col>
              <img
                style={{ borderRadius: 8, marginRight: "0.5rem" }}
                alt="product img"
                src={"https://via.placeholder.com/64x64.png"}
              />
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Divider type="horizontal" />
      <Row justify="center">
        <Col
          xl={{ span: 6 }}
          lg={{ span: 6 }}
          md={{ span: 6 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
        >
          <Button
            className="buyer-home-cta"
            // type="primary"
            size="large"
            //onClick={() => handleViewCatalogue()}
            loading={loader}
            onClick={() => handleEmailModal()}
          >
            View catalogue
          </Button>
        </Col>
      </Row>
      <Row
        justify="center"
        style={{ marginTop: "1.5rem" }}
        className="buyer-home-footer"
      >
        <Col>
          {" "}
          <Text type="secondary">
            We have partnered up with Sourcewiz to enhance your browsing
            experience and help you send your queries easily{" "}
          </Text>
        </Col>
      </Row>
    </>
  );
}
