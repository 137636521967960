import {
    Row,
    Col,
    Typography,
    Button
} from "antd";
import { useHistory } from "react-router-dom";
import { useGlobal } from '../../../store/index';

import successPng from "../../../assets/images/success.png";

import searchresult from "../../../assets/images/searchResult.svg";
import { Colors } from "../../../theme";

const { Title, Text } = Typography;

export default function EmptyState({resetFilter}) {
    const history = useHistory();
    const [globalState, setGlobalState] = useGlobal();
    const deviceType = globalState.deviceType;

    return (
        <Row justify="center" style={{ marginTop: "20px", width:"100%" }}>
            <Col>
              <Row justify="center" style={{ marginTop: "48px" }}>
                <img
                  src={searchresult}
                  alt="success"
                  width="386px"
                  height="250px"
                />
              </Row>
              
              <Row justify="center" style={{ marginTop: "48px" }}>
                <Title level={2} style={{fontSize: deviceType !== "mobile"? "18px" : "14px"}}>
                  We haven't found any results for your search
                </Title>
              </Row>
              <Row justify='center'>
                <Text
                  style={{fontSize: deviceType !== "mobile"? "16px" : "12px"}}
                >
                  Try clearing some of the filters you have applied
                </Text>
              </Row>
              <Row justify='center' style={{ marginTop: '2rem' }}>
                <Col>
                  <Button
                    className='cta'
                    size='large'
                    onClick={() =>
                      resetFilter()
                    }
                    style={{
                      color: Colors?.white,
                      backgroundColor: Colors?.black,
                    }}
                  >
                    Reset filter
                  </Button>
                </Col>
            </Row>
            </Col>
          </Row>
    )
}