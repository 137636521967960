import React, { useEffect, useState } from "react";

import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import Catalogue from "./BuyerPortal/Catalogue/index";
import {setItem,clear} from "utils/localStorage"
import { useGlobal } from "../store";


export default function RouteApp({ setIsAccessGranted, isAccessGranted }) {
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const type = urlParams.get("type");
  const [sessionId, setSessionId] = useState(null);
  const [visitor, setVisitor] = useState(null);
  const [visitorInfo, setVisitorInfo] = useState(null);
 const [globalState, setGlobalState] = useGlobal()
 const [fetchingUserData, setFetchingUserData] = useState(false)
 const [isUserAllowed, setIsUserAllowed] = useState(false);

  const params = useParams();
  const collection_link = params?.id;
  window.name=params?.id
  useEffect(() => {
    if (collection_link) {
      setFetchingUserData(true);
      const fpPromise = FingerprintJS.load({
        token: process.env.REACT_APP_FINGERPRINT_TOKEN,
        endpoint: process.env.REACT_APP_SESSION_ENDPOINT,
      });
      // clear();
      // Get the visitor identifier when you need it.
      fpPromise
        .then((fp) => fp.get({ extendedResult: true }))
        .then((result) => {
          // This is the visitor identifier:
          setVisitorInfo(result);
          
          setGlobalState.settings.setVisitorData(result);
          setItem("visitorId", result?.visitorId);
         
          setVisitor(result);
          setFetchingUserData(false);
          const getSessionId = async () => {
            axios
              .post(
                `${process.env.REACT_APP_API_SERVICE}analytics/session/?visitor=${result?.visitorId}&identifier=${collection_link}&city=${result?.ipLocation?.city?.name}&country=${result?.ipLocation?.country?.name}`,
                result
              )
              .then((res) => {
                setSessionId(res?.data?.sessionId);
                setItem(collection_link, res?.data?.sessionId);
              })
              .catch((err) => {
                console.log("err", err);
              });
          };
          if(!(type === "PREVIEW") ){
            getSessionId();
          }
          
        }).catch((err) => {
          setFetchingUserData(false);
          console.log("err", err);
        })
    }
  }, [collection_link, isUserAllowed]);

  useEffect(() => {
    if (sessionId && collection_link && isUserAllowed) {
      const timer = setInterval(view, 5000);
      return () => clearInterval(timer);
    }
  }, [sessionId, collection_link, isUserAllowed]);

  const view = async () => {
    // this call identifies the session to check if the session is live
    await axios.post(
      `${process.env.REACT_APP_API_SERVICE}analytics/session/view/?sessionId=${sessionId}&identifier=${collection_link}`
    );
  };
  return (
    <div className="routeApp">
      {collection_link && (
        <Catalogue
          isAccessGranted={isAccessGranted}
          setIsAccessGranted={setIsAccessGranted}
          visitorInfo={visitorInfo}
          fetchingUserData={fetchingUserData}
          setIsUserAllowed={setIsUserAllowed}
          sessionId={sessionId}
          isUserAllowed={isUserAllowed}
        />
      )}
    </div>
  );
}

